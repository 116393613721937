.map_infobox {
  width: 377px !important;

  ul li {
    margin-left: 7px;
  }

  /* Tabs styles */
  .md_list_t4 {
    padding-left: 7px;
    margin-bottom: 12px !important;
  }

  .md_list_t4_tabs>li {
    padding-right: 7px;
  }

  .md_list_t4_tabs>li>a {
    padding: 5px 8px 5px 8px;
    margin-right: 1px;
    line-height: 8px;
    border-radius: 3px 3px 0px 0px;
  }

  .content_services li {
    width: 98px;
  }

  .content_services li:before {
    padding-right: 3px;
  }
}