/* New Styles */
.template-one-gdpr {
  height: 195px;

  .template-one-gdpr__header-section {
    width: 100%;
  }

  .template-one-gdpr__fieldset {
    display: flex;
    flex-direction: column;

  }

  .template-one-gdpr__header-section {
    order: 2;
  }

  .template-one-gdpr__section-form {
    order: 3;

  }

  .template-one-gdpr__section-icon {
    order: 1;
  }

  .template-one-gdpr__wrapper {
    width: 100%;

    fieldset {
      padding-top: 7px;
    }

    div#final-step {
      line-height: 1;
      margin: 46px auto;
    }
  }
}

// Specific selector to override onpage styleing.
div.fsc .template-one-gdpr {
  height: 293px;
}

.template-one-gdpr {
  .s1of5 {
    vertical-align: top;
    width: initial;

    &.icon {
      margin-top: 7px;
      margin-right: 7px;
    }

    img {
      width: 33px;
    }
  }

  .s3of4,
  a {
    font-size: 1.3rem;
    line-height: 1.6rem;

    &+.md_cb {
      height: 1px;
      background: #eee;
      margin-top: 3px;
      margin-bottom: 3px;
    }

    p {
      margin-top: 10px;
    }
  }
}

// .html && div - for override style with inline selector on the page
.html div.template-one-gdpr-permission,
.html div.template-one-gdpr-valuepackage {
  height: 900px;
  margin: 0px;

  .template-one-gdpr__wrapper {
    width: 100%;

    div#final-step {
      width: unset;

      img {
        margin: 0;
        width: 60%;
      }
    }

  }

  img {
    margin: 0 0 80px 0;
    width: 60%;
  }

  .template-one-gdpr__final-step {
    margin-top: 400px;
  }

}

.html div.template-one-gdpr--no-header{
  height: 800px;

  img{
    margin-bottom: 40px;
  }
}

.client-form--gdpr {
  .client-form__fieldset {
    margin-top: 40px;
  }
}

.flex {
  .gdpr-center-vh {
    height: unset;
    margin-top: 30px;
  }

  .template-one-gdpr__final-step {
    margin-top: unset !important;
  }

  .template-one-gdpr-valuepackage {
    height: 500px !important;
  }
}