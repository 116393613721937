// Special rules for the migration header and footer
.m1682422210-header,
.m1682422210-menumobile,
.m1682422210-footer {
  font-family: "Open Sans";
  font-weight: 400;
  line-height: 26px;
  color: #4B4F64;
  --color-black-2: #29283E;
  --color-black-3: #4B4F64;
  --color-grey-4: #C3C3C3;
  --color-blue-1: #4573D2;


  a {
    text-decoration: none;
    color: #29283E;
    font-size: 1rem;
  }

  a:hover {
    opacity: 1;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .container-wrapper {
    width: 100%;
    margin: 0 auto;
    position: relative;
  }

  .container {
    width: 100%;
    padding: 0 1.6rem;
    max-width: 100%;
  }

  @media only screen and (min-width: 768px) {
    .container {
      padding: 0 2rem;
    }
  }

  @media only screen and (min-width: 834px) {
    .container {
      padding: 0 4rem;
    }
  }

  @media only screen and (min-width: 991px) {
    .container {
      max-width: 129.6rem;
      padding: 0 4.8rem;
    }
  }

  .row {
    --bs-gutter-x: 2rem;
    --bs-gutter-y: 0;

    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -.5);
    margin-left: calc(var(--bs-gutter-x) * -.5);
  }

  .row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
  }

  @media (min-width: 991px) {
    .col-lg-3 {
      flex: 0 0 auto;
      width: 25%;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  p {
    padding-top: 0;
    padding-bottom: 0;
  }

  img {
    display: block;
  }

  .d-none {
    display: none !important
  }

  @media (min-width: 991px) {
    .d-lg-none {
      display: none !important;
    }

    .d-lg-block {
      display: block !important
    }
  }


  .m1682422210-footer__phone,
  .m1682422210-footer__email,
  .m1682422210-footer__col a,
  .m1682422210-footer__contact {
    font-size: 1.2rem;
  }

  summary {
    cursor: pointer;
  }

  .btn {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1.4rem;
    line-height: 2.1rem;
    font-weight: 600;
    text-align: center;
    border: none;
    border-radius: 0.4rem;
    padding: 1.1rem 1.6rem 1rem;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
  }

  .btn-primary {
    color: #FFFFFF;
    background-color: #4573D2;
  }

  .icon-search:before {
    content: "\e905";
  }

  .icon-arrow-right:before {
    content: "\e90a";
  }

  .btn-cta {
    padding: 0;
    color: #4573D2;
    background-color: transparent;
  }

  .btn .icon {
    margin-left: 1.2rem;
    display: inline;
  }

  i.icon {
    height: unset;
    width: unset;
  }

  @media (min-width: 991px) {
    .col-lg-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }

    .col-lg-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
  }

  .m1682422210-header__megamenu-card .comp-card {
    background-color: #f5f7f9;
    max-width: 32rem;
    box-shadow: none;
  }

  .comp-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    margin-bottom: 25px;
    color: #29283E;
    background-color: #fff;
    border-radius: 6px;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    -webkit-box-shadow: 0 25px 116px rgba(41, 40, 62, .05), 0 12.66px 50.57px rgba(41, 40, 62, .0338), 0 5px 18.85px rgba(41, 40, 62, .025), 0 1.09px 6.71px rgba(41, 40, 62, .012);
    box-shadow: 0 25px 116px rgba(41, 40, 62, .05), 0 12.66px 50.57px rgba(41, 40, 62, .0338), 0 5px 18.85px rgba(41, 40, 62, .025), 0 1.09px 6.71px rgba(41, 40, 62, .012);
  }

  .comp-card:hover {
    background-color: #ebecef;
  }

  .comp-card.\--bkg-grey {
    background-color: #F5F7F9;
  }

  .comp-card:hover .comp-card__image img {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
  }

  .comp-card__image {
    overflow: hidden;
  }

  .comp-card__image img {
    width: 100%;
    height: 223px;
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    -o-transition: transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  }

  .comp-card__contain {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 2.4rem;
  }

  .comp-card__title {
    overflow: hidden;
  }

  .comp-card__desc {
    margin-top: 0.8rem;
    font-size: 1.4rem;
    line-height: 2.1rem;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }

  @media only screen and (min-width: 768px) {
    .comp-card__desc {
      font-size: 1.6rem;
      line-height: 2.6rem;
    }
  }

  .comp-card__cta {
    margin-top: 1.2rem;
  }

  .comp-card__cta .btn {
    padding-top: 10px;
    padding-bottom: 11px;
    text-align: left;
    display: inline;
  }

  .comp-card__link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .comp-card.\--icon {
    padding: 2.4rem;
  }

  .comp-card.\--icon:hover .comp-card__image img {
    -webkit-transform: none;
    transform: none;
  }

  .comp-card.\--icon .comp-card__image {
    width: 3.2rem;
    height: 3.2rem;
    margin-bottom: 1.2rem;
  }

  @media only screen and (min-width: 768px) {
    .comp-card.\--icon .comp-card__image {
      width: 4.8rem;
      height: 4.8rem;
    }
  }

  .comp-card.\--icon .comp-card__image img {
    max-width: 2.5rem;
    max-height: 2.5rem;
  }

  @media only screen and (min-width: 768px) {
    .comp-card.\--icon .comp-card__image img {
      max-width: 4rem;
      max-height: 4rem;
    }
  }

  .comp-card.\--icon .comp-card__contain {
    padding: 0;
  }

  .comp-card-section {
    padding: 3.2rem 0;
    /*************
        MODIFY
        *************/
  }

  @media only screen and (min-width: 991px) {
    .comp-card-section {
      padding: 8rem 0;
    }
  }

  .comp-card-section .comp-content-heading {
    max-width: 79.4rem;
    margin: 0 auto;
    margin-bottom: 4rem;
  }

  @media only screen and (min-width: 768px) {
    .comp-card-section .comp-content-heading {
      margin-bottom: 4.8rem;
    }
  }

  .comp-card-section__container {
    display: grid;
    gap: 2rem;
  }

  @media only screen and (min-width: 768px) {
    .comp-card-section__container {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media only screen and (min-width: 991px) {
    .comp-card-section__container {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media only screen and (min-width: 768px) {
    .comp-card-section.\--2-items .comp-card-section__container {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media only screen and (min-width: 991px) {
    .comp-card-section.\--3-items .comp-card-section__container {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .comp-card-section.bkg-midnight .bkg-secondary {
    height: 58%;
  }

  @media only screen and (min-width: 991px) {
    .comp-card-section.bkg-midnight .bkg-secondary {
      height: 20%;
    }
  }

  .m1682422210-menumobile__navigation-subItem .comp-card__title {
    min-height: auto;
  }

  .btn-primary:disabled,
  .btn-primary:hover {
    background-color: #75a0f5;
    color: #fff;
  }
}

.m1682422210-header {
  -webkit-box-shadow: 0 2.5rem 11.6rem rgba(41, 40, 62, 0.05), 0 1.266rem 5.057rem rgba(41, 40, 62, 0.0338), 0 0.5rem 1.885rem rgba(41, 40, 62, 0.025), 0 0.109rem 0.671rem rgba(41, 40, 62, 0.012);
  box-shadow: 0 2.5rem 11.6rem rgba(41, 40, 62, 0.05), 0 1.266rem 5.057rem rgba(41, 40, 62, 0.0338), 0 0.5rem 1.885rem rgba(41, 40, 62, 0.025), 0 0.109rem 0.671rem rgba(41, 40, 62, 0.012);
}

.m1682422210-header .m1682422210-header__search{
  .search_autocomplete {
    left: 8px;
    top: 42.5px;
  }
}

.m1682422210-header .m1682422210-header__search,
.m1682422210-menumobile {
  .search_autocomplete {
    transition: 1s;
    position: absolute;
    background: white;
    border-radius: 6px;
    margin-top: 3px;
    width: 100%;
    border: 1px solid #29283E1A;
    box-sizing: content-box;
    max-height: 172px;
    overflow: scroll;
    padding-top: 2px;
    padding-bottom: 2px;
    scroll-snap-type: y mandatory;
    box-shadow: 0px 8px 12px 6px #29283E14, 0px 10px 12px -8px #29283E1F;
    z-index: 10;
  }

  .search_autocomplete li {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 2px 4px;
    padding: 5.5px 4px 4px 4px;
    border-radius: 4px;
    height: 32px;
    // margin-right: 0px;
    font-size: 14px;
    line-height: 21px;

    scroll-snap-align: center;

    margin-right: 9px;
  }

  .search_autocomplete li:hover,
  .search_autocomplete li.active {
    cursor: pointer;
    background: #29283E0D;
    font-weight: 400;
  }

  .search_autocomplete::-webkit-scrollbar {
    width: 12px;
  }

  .search_autocomplete::-webkit-scrollbar:horizontal {
    display: none;
  }

  .search_autocomplete::-webkit-scrollbar-thumb {
    background: #29283E53;
    border-radius: 8px;

    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

}


.m1682422210-menumobile.webkit-scrollbar,
.m1682422210-header.webkit-scrollbar {
  form {
    .search_autocomplete li {
      margin-right: 0;
    }
  }
}


// Fonts
@font-face {
  font-family: "icomoon";
  src: url("/sites/all/themes/eco2014/font/migration/icomoon.eot?d4cr5r");
  src: url("/sites/all/themes/eco2014/font/migration/icomoon.eot?d4cr5r#iefix") format("embedded-opentype"), url("/sites/all/themes/eco2014/font/migration/icomoon.ttf?d4cr5r") format("truetype"), url("/sites/all/themes/eco2014/font/migration/icomoon.woff?d4cr5r") format("woff"), url("/sites/all/themes/eco2014/font/migration/icomoon.svg?d4cr5r#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Copy & add m1682422210-

.m1682422210-footer {
  background-color: #1C1C1C;
  color: #FFFFFF;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 600;
}

.m1682422210-footer h5 {
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: 600;
}

.m1682422210-footer a {
  color: #FFFFFF;
  cursor: pointer;
}

.m1682422210-footer a:hover {
  color: #4573D2;
}

.m1682422210-footer ul li {
  margin-bottom: 2rem;
  font-size: 1.2rem;
}

.m1682422210-footer__title {
  position: relative;
  margin-bottom: 1.2rem;
  padding: 0;
}

@media only screen and (min-width: 991px) {
  .m1682422210-footer__title {
    pointer-events: none;
  }
}

.m1682422210-footer__title::-webkit-details-marker,
.m1682422210-footer__title::marker {
  content: "";
  display: none;
}

@media only screen and (min-width: 991px) {
  .m1682422210-footer__title::after {
    content: none;
  }
}

.m1682422210-footer__col {
  margin-bottom: 3rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
}

.m1682422210-footer__col[open] .m1682422210-footer__title::after {
  -webkit-transform: rotate(180deg) translateY(50%);
  transform: rotate(180deg) translateY(50%);
}

.m1682422210-footer__phone {
  margin-top: 1.2rem;
}

.m1682422210-footer__phone a,
.m1682422210-footer__email a {
  margin-left: 0.5rem;
}

.m1682422210-footer__social {
  margin-top: 1.6rem;
  margin-bottom: 1.2rem;
}

.m1682422210-footer__social ul {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.m1682422210-footer__social ul li {
  margin-right: 0.4rem;
  margin-bottom: 0;
}

.m1682422210-footer__download {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
}

@media only screen and (min-width: 991px) {
  .m1682422210-footer__download {
    margin-bottom: 0;
  }
}

.m1682422210-footer__download h5 {
  margin-bottom: 2rem;
}

.m1682422210-footer__download a {
  text-decoration: underline;
}

.m1682422210-footer__download-link {
  display: inline-block;
  margin-right: 1.2rem;
  margin-bottom: 1.2rem;
  text-decoration: none;
}

.m1682422210-footer__download .m1682422210-footer__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 1.2rem;
}

.m1682422210-footer__copyright {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
}

.m1682422210-footer__top {
  padding: 2rem 0 3.2rem;
}

@media only screen and (min-width: 991px) {
  .m1682422210-footer__top {
    padding: 4rem 0;
  }
}

.m1682422210-footer__bottom {
  background-color: #303030;
  padding: 3rem 0;
}

@media only screen and (min-width: 991px) {
  .m1682422210-footer__bottom {
    padding: 1.1rem 0;
  }
}

.m1682422210-footer__bottom-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (min-width: 991px) {
  .m1682422210-footer__bottom-wrap {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.m1682422210-footer__bottom a {
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: 600;
  margin: 0 1.2rem;
}

.m1682422210-footer__logo {
  margin-top: 2.6rem;
}

@media only screen and (min-width: 991px) {
  .m1682422210-footer__logo {
    margin-top: 0;
  }
}

.m1682422210-footer__logo a {
  line-height: 0;
  margin: 0;
  display: block;
}

.m1682422210-header {
  height: 6rem;
  position: relative;
  z-index: 2;
  /*************
    MODIFY
    *************/
}

@media only screen and (min-width: 991px) {
  .m1682422210-header {
    height: 12.1rem;
  }
}

.m1682422210-header__container {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1;
}

.m1682422210-header__main {
  background-color: #FFFFFF;
  position: relative;
  z-index: 3;
}

@media only screen and (min-width: 768px) {
  .m1682422210-header__main {
    border-bottom: solid 0.1rem #DDDDDD;
  }
}

.m1682422210-header__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 6rem;
}

.m1682422210-header__logo {
  width: 100%;
  max-width: 17.9rem;
}

.m1682422210-header__tools {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.m1682422210-header__search {
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 4.5rem;
  height: 4.5rem;
  margin-right: 0.8rem;
  position: relative;
  -webkit-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease;
}

@media only screen and (min-width: 768px) {
  .m1682422210-header__search {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.m1682422210-header__search.\--active {
  width: 15rem;
}

@media only screen and (min-width: 991px) {
  .m1682422210-header__search.\--active {
    width: 25.4rem;
  }
}

.m1682422210-header__search.\--active .m1682422210-header__search-input {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.m1682422210-header__search-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 4.5rem;
  height: 4.2rem;
  border: none;
  background: none;
  font-size: 2rem;
  cursor: pointer;
  position: relative;
  z-index: 1;
  left: 5.5px;
}

.m1682422210-header__search-btn:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.m1682422210-header form input.m1682422210-header__search-input {
  height: 40px;
  width: 254px;
  padding-left: 40px;
  padding-right: 12px;
  padding-bottom: 1px;
  border: 1px solid #4573D2;
  border-radius: 50rem;
  position: absolute;
  top: 2.5px;
  left: 8px;
  bottom: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  outline: none;
}

.m1682422210-header__menu {
  display: none;
}

@media only screen and (min-width: 768px) {
  .m1682422210-header__menu {
    display: block;
    margin-right: 0.8rem;
  }
}

.m1682422210-header__menu ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (min-width: 834px) {
  .m1682422210-header__menu li:not(:last-child) {
    margin-right: 0.8rem;
  }
}

.m1682422210-header__menu a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: 600;
  border-radius: 0.4rem;
  padding: 1.1rem 0.8rem 1rem;

  &:hover {
    background-color: rgba(41, 40, 62, .05);
  }
}

@media only screen and (min-width: 834px) {
  .m1682422210-header__menu a {
    padding: 1.1rem 1.6rem 1rem;
  }
}

.m1682422210-header__link {
  margin-right: 2.4rem;
}

@media only screen and (min-width: 768px) {
  .m1682422210-header__link {
    margin-right: 0.8rem;
  }
}

.m1682422210-header__link .cta {
  padding: 1.1rem 0.8rem 1rem;
}

@media only screen and (min-width: 834px) {
  .m1682422210-header__link .cta {
    padding: 1.1rem 1.6rem 1rem;
  }
}

.m1682422210-header__account {
  display: none;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .m1682422210-header__account {
    display: block;
  }
}

.m1682422210-header__account-toggle a {
  display: inline-block;
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: 600;
  color: #4573D2;
  border-radius: 0.4rem;
  padding: 1.1rem 0.8rem 1rem;
}

@media only screen and (min-width: 834px) {
  .m1682422210-header__account-toggle a {
    padding: 1.1rem 1.6rem 1rem;
  }
}

.m1682422210-header__account-toggle a:hover {
  background-color: rgba(41, 40, 62, .05);
}

.m1682422210-header__navigation {
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 6rem;
  background-color: #FFFFFF;
  position: relative;
  z-index: 2;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

@media only screen and (min-width: 768px) {
  .m1682422210-header__navigation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.m1682422210-header__navigation ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  overflow: hidden;
  overflow-x: auto;
}

@media only screen and (min-width: 1200px) {
  .m1682422210-header__navigation ul {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.m1682422210-header__navigation li:not(:last-child) {
  margin-right: 0.8rem;
}

.m1682422210-header__navigation a {
  display: inline-block;
  padding: 1.3rem 2rem;
  font-size: 1.6rem;
  line-height: 2.6rem;
  white-space: nowrap;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}

.m1682422210-header__navigation a.\--active {
  background-color: rgba(41, 40, 62, .05);
}

.m1682422210-header__navigation a.\--active span::before {
  -webkit-transform: translateY(-50%) rotate(-180deg);
  transform: translateY(-50%) rotate(-180deg);
}

.m1682422210-header__navigation a:hover {
  background-color: rgba(41, 40, 62, .05);
}

.m1682422210-header__navigation a span {
  padding-right: 2.8rem;
  position: relative;
  pointer-events: none;
}

.m1682422210-header__navigation a span::before {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  content: "\e907";
  width: 2.4rem;
  height: 2.4rem;
  font-family: "icomoon";
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.m1682422210-header__megamenu {
  min-height: calc(100vh - 121px);
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%;
  left: 0;
  z-index: 1;
  pointer-events: none;
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.m1682422210-header__megamenu-container {
  padding: 2.4rem 0 4.8rem;
  background: #FFFFFF;
  border-top: solid .1rem #ddd;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  opacity: 0;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  pointer-events: auto;

  -webkit-box-shadow: 0 2.5rem 11.6rem rgba(41, 40, 62, .1), 0 1.266rem 5.057rem rgba(41, 40, 62, .067), 0 0.5rem 1.885rem rgba(41, 40, 62, .05), 0 0.109rem 0.671rem rgba(41, 40, 62, .024);
  box-shadow: 0 2.5rem 11.6rem rgba(41, 40, 62, .1), 0 1.266rem 5.057rem rgba(41, 40, 62, .067), 0 0.5rem 1.885rem rgba(41, 40, 62, .05), 0 0.109rem 0.671rem rgba(41, 40, 62, .024);
}

.m1682422210-header__megamenu-container.\--active {
  -webkit-transform: none;
  transform: none;
  opacity: 1;
}

.m1682422210-header__megamenu-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -0.4rem;
}

.m1682422210-header__megamenu-list li {
  width: 50%;
  padding: 0 0.4rem;
}

.m1682422210-header__megamenu-item {
  padding: 1.2rem;
  padding-bottom: 0.9rem;
  margin-bottom: 0.8rem;
  -webkit-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  position: relative;
  color: #29283e
}

.m1682422210-header__megamenu-item:hover {
  background-color: rgba(41, 40, 62, .05);
}

.m1682422210-header__megamenu-item-title {
  font-size: 1.8rem;
  line-height: 2.7rem;
  font-weight: 600;
}

.m1682422210-header__megamenu-item-text {
  height: 5.2rem;
  margin-top: 0.8rem;
  font-size: 1.6rem;
  line-height: 2.6rem;
  overflow: hidden;
}

.m1682422210-header__megamenu-item-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.m1682422210-header__megamenu-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.m1682422210-header__megamenu-card .comp-card {
  max-width: 32rem;
}

.m1682422210-header.\--hidden-nav .m1682422210-header__navigation {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.m1682422210-header.\--hidden-nav .m1682422210-header__megamenu {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.user-logged-in .m1682422210-header .m1682422210-header__container {
  top: 3.9rem;
}

@media only screen and (min-width: 975px) {
  .user-logged-in.toolbar-tray-open .m1682422210-header .m1682422210-header__container {
    top: 7.9rem;
  }
}

.m1682422210-menumobile {
  display: block;
  position: fixed;
  top: 6rem;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  pointer-events: none;
  overflow: hidden;
  /*********
    MODIFY
    *********/
}

@media only screen and (min-width: 991px) {
  .m1682422210-menumobile {
    display: none;
  }
}

.m1682422210-menumobile__toggle {
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

@media only screen and (min-width: 768px) {
  .m1682422210-menumobile__toggle {
    display: none;
  }
}

.m1682422210-menumobile__toggle span {
  width: 1.8rem;
  height: 0.2rem;
  background-color: #1C1C1C;
  position: absolute;
  top: 1.3rem;
  left: 0.3rem;
  -webkit-transition: width 0.2s ease;
  -o-transition: width 0.2s ease;
  transition: width 0.2s ease;
  pointer-events: none;
}

.m1682422210-menumobile__toggle::before,
.m1682422210-menumobile__toggle::after {
  content: "";
  width: 1.8rem;
  height: 0.2rem;
  background-color: #1C1C1C;
  position: absolute;
  left: 0.3rem;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  pointer-events: none;
}

.m1682422210-menumobile__toggle::before {
  top: 0.6rem;
}

.m1682422210-menumobile__toggle::after {
  top: 2rem;
}

.m1682422210-menumobile__toggle.\--open-menu span {
  width: 0;
}

.m1682422210-menumobile__toggle.\--open-menu::before {
  top: 1.1rem;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.m1682422210-menumobile__toggle.\--open-menu::after {
  top: 1.1rem;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.m1682422210-menumobile__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 100%;
  padding: 2.4rem 0rem 3.6rem;
  background-color: #FFFFFF;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.m1682422210-menumobile__search {
  position: relative;
  margin-bottom: 2.4rem;
}

.m1682422210-menumobile__search input {
  width: 100%;
  height: 4.8rem;
  padding-left: 5.6rem;
  border: none;
  border-radius: 2.4rem;
  background-color: #F5F7F9;
}

.m1682422210-menumobile__search button {
  width: 2.4rem;
  height: 2.4rem;
  font-size: 2rem;
  background: none;
  border: none;
  position: absolute;
  left: 18px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.m1682422210-menumobile__navigation {
  width: 100%;
  min-height: 100%;
  margin-bottom: 2.4rem;
}

.m1682422210-menumobile__navigation>ul>li:not(:last-child) {
  margin-bottom: 0.4rem;
}

.m1682422210-menumobile__navigation>ul>li>a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 6rem;
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: 600;
}

.m1682422210-menumobile__navigation>ul>li>a:hover,
.m1682422210-menumobile__navigation>ul>li>a:active {
  color: inherit;
}

.m1682422210-menumobile__navigation>ul>li.\--has-sub a span {
  padding-right: 2.8rem;
  position: relative;
  pointer-events: none;
}

.m1682422210-menumobile__navigation>ul>li.\--has-sub a span::before {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  content: "\e907";
  width: 2.4rem;
  height: 2.4rem;
  font-family: "icomoon";
  font-size: 1rem;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.m1682422210-menumobile__navigation>ul>li.\--active a span::before {
  -webkit-transform: translateY(-50%) rotate(-180deg);
  transform: translateY(-50%) rotate(-180deg);
}

.m1682422210-menumobile__navigation>ul>li>ul {
  margin-top: 1.6rem;
}

.m1682422210-menumobile__navigation-subItem {
  display: none;
  margin: 1.6rem 0;
}

.m1682422210-menumobile__navigation-subItem ul {
  margin-bottom: 1.6rem;
}

.m1682422210-menumobile__navigation-subItem .comp-card__title {
  min-height: auto;
}

.m1682422210-menumobile__navigation-subItem .m1682422210-header__megamenu-item-title,
.m1682422210-menumobile__navigation-subItem .m1682422210-header__megamenu-item-text {
  font-size: 1.4rem;
  line-height: 2.1rem;
}

.m1682422210-menumobile__navigation-subItem .comp-card .btn {
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin-bottom: 0;
}

.m1682422210-menumobile__link .btn {
  padding: 1.4rem 1.6rem 1.3rem;
}

.m1682422210-menumobile__link .btn:not(:last-child) {
  margin-bottom: 0.8rem;
}

.m1682422210-menumobile.\--open-menu {
  pointer-events: auto;
  overflow-y: auto;
}

.m1682422210-menumobile.\--open-menu .m1682422210-menumobile__container {
  -webkit-transform: none;
  transform: none;
}

.user-logged-in .m1682422210-menumobile {
  top: 9.9rem;
}

.m1682422210-header,
.m1682422210-menumobile,
.m1682422210-header {
  .visually-hidden {
    position: absolute !important;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    word-wrap: normal;
  }

  .block {
    margin-bottom: 0;
  }

}

.m1682422210-menumobile {

  a,
  div,
  span {
    font-size: 1.4rem;
  }

  .m1682422210-menumobile__search {
    form input {
      padding-left: 5.6rem;
      border: .1rem solid transparent;

      &:active,
      &:focus,
      &:focus-visible {
        border-color: #4573d2;
        outline: 0;
      }
    }

    button[type="submit"] {
      padding: 0;
    }
  }

  i.icon.icon-search-thin {
    width: auto;
  }

  .icon-search-thin:before {
    content: "\e905";
    color: black;
  }

  .btn-block {
    width: 100%;
    display: block;
  }

  .btn {
    text-align: center;
  }

  .btn-grey {
    color: #29283e;
    background-color: rgba(41, 40, 62, .1);
  }
}

.m1682422210-footer__toggle {
  position: relative;
  margin-bottom: 1.2rem;
  padding: 0;
}

.m1682422210-footer__padding {
  margin-bottom: 3rem;
  font-size: 1.2rem;
  line-height: 1.8rem;

  a {
    font-size: 1.2rem;
  }

  p {
    font-size: 1.2rem;
  }
}

.m1682422210-footer {
  .block {
    margin-bottom: unset;
  }
}

.m1682422210-footer__info {
  font-size: 1.2rem;
}

.m1682422210-footer__dropdown {
  display: none;
  padding-left: 1.2rem;
  padding-top: 0.7rem;
}

.m1682422210-footer__padding.\--active .m1682422210-footer__dropdown {
  display: block;
}

.m1682422210-footer__toggle::after {
  content: "\e907";
  font-family: "icomoon";
  font-weight: 400;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 1.2rem;
}

.m1682422210-footer__copyright p {
  font-size: 1rem;
}

.m1682422210-footer__padding.\--active .m1682422210-footer__toggle::after {
  -webkit-transform: rotate(180deg) translateY(50%);
  transform: rotate(180deg) translateY(50%);
}

.m1682422210-footer a:hover {
  color: rgba(255, 255, 255, .6);
}


.m1682422210-menumobile h3 {
  font-size: 32px;
  line-height: 38px;
  font-weight: 700;
  margin: 0;
}

@media only screen and (min-width: 991px) {
  .m1682422210-footer__dropdown {
    display: block;
    padding: 0;
  }

  .m1682422210-footer__toggle::after {
    content: none
  }

  .m1682422210-footer ul li {
    margin-bottom: 0.8rem;
  }

  .m1682422210-footer__social ul li {
    margin-bottom: 0;
  }

  .m1682422210-header h6 {
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
    margin: 0;
  }
}

.m1682422210-header__megamenu-info {
  border-top: 1px solid var(--color-grey-4);
  margin: 43px 0 24px 0;
  padding: 27px 0 0 0;
  box-sizing: border-box;
  line-height: 150%;
  color: var(--color-black-2);
}

.m1682422210-header__megamenu-info__header {
  padding: 0 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%
}

.m1682422210-header__megamenu-info__body {
  padding: 0 12px;
  margin-top: 8px;
  padding: 0 12px;
  color: var(--color-black-3)
}

.m1682422210-header__megamenu-info__body-header {
  font-weight: 600;
  margin-bottom: 12px;
}

.m1682422210-header__megamenu-info__footer {
  margin-top: 35px;
}

.m1682422210-header__megamenu-info__footer-item {
  display: grid;
  grid-template-columns: 38px 1fr;
  height: 75px;

  a {
    text-decoration: none;
    color: var(--color-blue-1);
    font-size: 16px;
  }
}

.m1682422210-header__megamenu-info__footer-item__icon {
  width: 20px;
  height: 20px;
  margin-top: 3px;
  margin-left: 5px;
}

.m1682422210-header__megamenu-info__footer-item__header {
  font-weight: 600;
  margin-bottom: 5px;
}

.m1682422210-header__megamenu-info__footer-item__icon--phone {
  background: url("../images/migration/icons/icon-phone.svg") no-repeat left center;
  background-size: 20px 20px;
}

.m1682422210-header__megamenu-info__footer-item__icon--email {
  background: url("../images/migration/icons/icon-email.svg") no-repeat left center;
  background-size: 20px 20px;
}

.m1682422210-header__megamenu-info__footer-item__icon--chat {
  background: url("../images/migration/icons/icon-chat.svg") no-repeat left center;
  background-size: 22px 22px;
  width: 22px;
  height: 22px;
  margin-top: -2px;
}

.m1682422210-header__container .heading-5, .m1682422210-header__container h6 {
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
}

.m1682422210-header__container .heading-2, .m1682422210-header__container h3 {
  font-size: 32px;
  line-height: 38px;
  font-weight: 700;
}

@media only screen and (min-width: 991px) {
  .m1682422210-header__container .heading-5, .m1682422210-header__container h6 {
      font-size: 18px;
      line-height: 27px;
  }
}

@media only screen and (min-width: 768px) {
  .m1682422210-header__megamenu-info {
    margin-top: 20px;
    padding-top: 33px;
  }

  .m1682422210-header__megamenu-info__header {
    font-size: 18px;
  }

  .m1682422210-header__megamenu-info__body {
    margin-top: 3px;
    display: flex;
    gap: 3px;
    font-size: 12px;
  }

  .m1682422210-header__megamenu-info__body-header {
    margin-bottom: 0;
  }

  .m1682422210-header__megamenu-info__footer {
    margin-top: 21px;
    display: flex;
    gap: 50px;
  }

  .m1682422210-header__megamenu-info__footer-item {
    grid-template-columns: 50px 1fr;
  }

  .m1682422210-header__megamenu-info__footer-item__header {
    margin-bottom: 2px;
  }

  .m1682422210-header__megamenu-info__footer-item__icon {
    width: 24px;
    height: 24px;
    margin-top: 0;
    margin-left: 9px;
  }

  .m1682422210-header__megamenu-info__footer-item__icon--phone {
    background-size: 24px 24px;
  }

  .m1682422210-header__megamenu-info__footer-item__icon--email {
    background-size: 24px 24px;
  }

  .m1682422210-header__megamenu-info__footer-item__icon--chat {
    background-size: 28px 28px;
    width: 28px;
    height: 28px;
  }
}
