//video hero
.video-section-wrapper-container{
  display: none;

  .video-section-wrapper-content{
    margin: 0 auto;
    position: relative;

    iframe{
      display: block;
    }

    .video-section-close-button{
      position: absolute;
      background: black;
      width: 29px;
      height: 29px;
      top: 0px;
      margin: -10px -10px 0 -15px;
      color: white;
      border: 2px solid white;
      z-index: 10000;
      font-weight: bold;
      font-size: 25px !important;
      line-height: 25px;
      text-align: center;
      cursor: pointer;
      border-radius:25px;
    }
  }

  .video-section-wrapper-content-wide{
    .video-section-close-button{
      margin: 5px -10px 0 5px;
    }
  }
}
