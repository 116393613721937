// Logo on landingpage
.page-order-flow {
  padding-top: 52px;

  header {
    height: 59px;
    top: 0;
    margin-bottom: 16px;

    .logo {
      width: 234px;
    }

    .cart {
      height: 42px;
      margin-right: 13px;
      top: -7px;
      width: 228px;

      &.step1 {
        width: 390px;
      }

      .product-price-total {
        top: 2px;
      }
    }

    .buttons {
      margin-bottom: 28px;

      #edit-back {
        margin-left: 20px;
      }

      #edit-proceed {
        margin-left: 7px;
      }
    }
  }

  .field-name-field-section {
    margin-left: -199px;
  }

  .field {
    .field-collection-item-container {
      margin-left: 27px;
    }
  }
}

.ribbon-wrapper {
  height: 59px;
  left: -2px;
  line-height: 1rem;
  top: -2px;
  width: 59px;
}

.ribbon-orange {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  font-size: 0.7rem;
  left: -16px;
  top: 15px;
  width: 75px;

  &::before,
  &::after {
    content: "";
    border-top-width: 2px;
    border-left-width: 2px;
    border-right-width: 2px;
    bottom: -1px;
  }
}

.field-name-field-package-payoffs {
  font-size: 1.3rem;
  height: 168px;
  width: 166px;

  li {
    padding-bottom: 13px;
  }
}

.field-name-field-product {
  width: 150px;

  .icons-sp {
    height: 33px;
  }
}

.highlighted-label {
  margin-top: 20px;

  .label-special-offer {
    width: 98px;
    top: 10px;
    font-size: 1rem;
  }
}

.label-most-popular {
  font-size: 1rem;
  height: 39px;
  left: 8px;
  margin-top: -23px;
  padding-top: 3px;
  width: 130px;

  &.has-special-offer {
    margin-top: -12px;
  }
}

.field-collection-package-item-wrapper {
  width: 146px;

  .highlighted-text {
    font-size: 1.4rem;
    margin-top: -13px;
  }

  .column-head {
    min-height: 205px;

    h3 {
      padding: 13px 0 13px 0;

      &.has-special-offer {
        padding-top: 20px;
      }
    }

    .product-usp {
      h3 {
        font-size: 1rem;
      }
    }

    .old-price {
      padding-bottom: 7px;
      margin-top: -7px;
    }

    .currency {
      top: -13px;
    }

    .price {
      font-size: 2.9rem;

      span {
        top: 26px;
        font-size: 5pt;
      }

    }

    .description {
      font-size: 0.9rem;
      line-height: 1.4rem;
    }

  }

  .gradient-top {
    &::after {
      width: 175px;
      height: 7px;
      top: -7px;
    }
  }

  .gradient-bottom::before {
    width: 175px;
    height: 7px;
  }

  .md_inlb.gradient-top::after {
    top: -38px;
  }

  label {
    margin-bottom: -5px;

    .child-features {
      margin-top: -5px;

      .markup {
        font-size: 0.9rem;
      }

      i {

        &.gradient-bottom::before {
          width: 145px;
        }
      }

    }

    .markup {
      height: 34px;
      font-size: 0.9rem;

      &.parent {
        .feature_info {
          margin-top: 10px;
        }
      }
    }

    i {
      &.fa-times {
        margin-top: 10px;
      }
    }

    &.first-column .markup {
      height: 33px;
      margin: 0 0 0 -182px;
      padding: 0 0 0 7px;
      width: 176px;

      .md_tbl_cell_middle:first-child {
        width: 139px;
        line-height: 1.2rem;
      }

      span {
        font-size: 0.9rem;
      }

      .expand-collapse-icon {
        margin-right: 10px;
        margin-top: 2px;
        padding: 4px 0 0 4px;
      }

      &.first {
        .expanded {
          margin-right: 10px;
        }
      }
    }
  }
}

// Moved this out to optimize the CSS.
.outer-div,
.product,
.vat,
.total {
  margin-bottom: 17px;
  padding-bottom: 3px;
}

.node-statement {
  >ul>li {
    padding-right: 13px;
    line-height: 1.6rem !important;
  }
}

/* Views styling for addons-block */
.addons-more-group {

  /* Styles common across all layouts - mobile + others */
  .view-header {
    h3 {
      padding: 13px 0 13px 0;

      &.has-special-offer {
        padding-top: 20px;
      }
    }

    .addons-title-wrapper {
      background-position: 0 7px;
    }

    .addons-title {
      padding: 0 13px;
    }

    .show-hide-toggle {
      top: 12px;
    }
  }

  h3 {
    padding-top: 33px;
  }

  .views-row {
    margin-bottom: 7px;
    margin-top: 7px;
    padding: 7px;
    font-size: 1rem;

    .addon-updated-txt {
      font-size: 0.7rem;
      margin-left: 7px;
    }

    .views-field-description {
      width: 15px;
      height: 15px;
    }
  }
}

/*
* Retry popup shown when an error ocurred...
* ... or when an error re-occurs
*/
.page-order-flow-signup-retry {
  .block {
    margin-bottom: 1.3rem;
  }

  form#order-flow-signup-retry {
    .field-collection-item-container {
      font-size: 1rem;
    }
  }

  .colorbox-footer {
    padding-top: 2rem;
    margin-top: 2rem;

    .retry-text,
    .retry-error {
      font-size: 1rem;
    }
  }
}

.btn_info {
  font-size: 0.9rem;
  height: 13px;
  line-height: 12px;
  margin-top: 2px;
  width: 13px;

  &.feature_info {
    margin-right: 11px;
  }

  a {
    padding: 4px;
  }

}

.rel-wrapper {
  .anchor_order_form {
    top: -52px;
  }
}

#order-flow-signup-user-info {
  .tooltip-question-icon {
    background-size: 9px 9px;
    height: 13px;
    width: 13px;
    margin-right: 10px;
    margin-top: 5px;
    padding: 4px 0 0 4px;
  }

  .tooltip:hover::before {
    margin-left: 5px;
  }
}

body {
  .node-product-package {
    width: 100%;

    [class^="cta-"] a {
      border-radius: 33px;
    }

    .label-most-popular.has-special-offer {
      width: 95%;
      margin-top: -23px !important;
      height: 52px !important;
    }

    .field-name-field-package-payoffs {
      display: none;
    }

    .field-name-field-product {
      display: block;
      padding-top: 26px;
      width: 100%;

      .feature_info {
        font-size: 1rem !important;
      }
    }

    .highlighted-label .label-special-offer {
      width: 90%
    }

    .field-collection-package-item-wrapper {
      width: 100%;
    }

    .column-head {
      text-align: left;
      min-height: initial;

      .bottom .rtecenter {
        display: none
      }

      .product-usp {
        display: none;
      }

      .ribbon-wrapper {
        height: initial;
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        background: $orange;
      }

      .ribbon-orange {
        background-color: transparent;
        box-shadow: none;
        color: #fff;
        font-size: 2rem;
        left: 1px;
        padding-bottom: 7px;
        padding-top: 7px;
        text-align: center;
        transform: rotate(0);
        top: 0;
        width: 100%;

        &::before,
        &::after {
          content: none;
        }
      }

      h3,
      h3+div {
        display: inline-block;
        padding: 26px 0;
      }

      h3 {
        font-size: 2.2rem;
        padding-left: 7px;

        &.ff1 {
          text-align: center;
          width: 100%;
        }
      }

      h3+div {
        position: relative;
        float: right;

        &.cta-top {
          position: relative;
          right: auto;
          width: 100%;
        }
      }

      .description {
        font-size: 1.3rem;
        line-height: 2.6rem;
      }

      .price {
        font-size: 3.6rem !important;

        span {
          top: 39px;
        }
      }

      .amount-time {
        display: inline-block !important;
      }

      .top {
        .pts.pblh {
          min-width: 40%;
        }
      }
    }

    .cta-top {
      padding: 0 0 13px 0;
      text-align: center;

      a {
        font-size: 1.6rem;
        padding: 7px 33px;
      }
    }

    .gradient-bottom::before {
      width: 100%;
    }

    label {
      margin-left: -1px;
      text-align: left;
    }

    .first-column .markup,
    .markup {
      background-color: #fff;
      border: 1px solid #ddd;
      color: #000;
      display: table;
      left: 0;
      margin: 0;
      padding: 0 0 0 33px;
      position: absolute;
      text-align: left;
      vertical-align: middle;
      width: 100%;

      .md_tbl_cell_middle {
        position: relative;
        z-index: 2;
        font-size: 1.3rem !important;
      }

      .md_tbl_cell_middle:first-child {
        width: 100%;
        line-height: 1.2rem;
      }

      span {
        font-size: 0.9rem;
        font-weight: normal;
      }

      // span with +/- expand/collapse circle, uses class definition from _icons.scss
      .expand-collapse-icon {
        margin-right: 10px;
        margin-top: 2px;
        padding: 4px 0 0 4px;
        position: absolute;
        z-index: 999;
      }

      &.first {
        .expanded {
          margin-right: 9px;
        }
      }

      a[href] {
        @include a-linkcolor($blue, $blue, none);
      }

      a:not([href]) {
        color: $gray-darkest;
        text-decoration: none;
      }
    }

    .markup a {
      font-size: 15px;
    }

    i.icons-sp {
      width: 33px;
      position: relative;
      z-index: 2;
      background-size: 16px 12px;
    }

    &.unchecked {
      display: none;
    }

    .md_inlb.s100.icons-sp.unchecked {
      width: initial;

      .ff2 {
        position: absolute;
        font-size: 15px;
        right: 0px;
        padding-top: 4px;
        padding-right: 10px;
      }

      div[id^="feature-"] {
        display: none;
      }
    }
  }

  .view-product-add-ons {
    [class^="views-row"] {
      float: none;
      width: 100%;
      padding-top: 0;
      padding-bottom: 0;

      .views-field-description {
        display: none;

        &+div span,
        &+div+div {
          font-size: 1.3rem !important;
        }
      }
    }
  }

  // Step 2 & 3
  &.page-order-flow-signup {

    // Hide insert link for logo
    form header>a {
      display: none;
    }

    header ul.menu>li.first>a {
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: 117px 25px;
      box-shadow: none;
      content: '';
      display: block;
      height: 26px;
      overflow: hidden;
      position: relative;
      width: 117px;
      z-index: inherit;
      margin-left: 7px;
    }

    .logo {
      display: block;
      float: left;
      width: initial;

      ul {
        margin: 0;
        margin-top: 13px;
      }
    }

    .cart {
      clear: left;
      float: left;
      height: initial;
      margin-left: 7px;
      text-align: left;
      width: initial;

      .product-price-total {
        font-size: 1rem !important;
      }
    }

    .tooltip {
      display: none;
    }
  }

  .order-steps {
    width: 100%;
    height: 33px;
    margin-bottom: 13px;

    ul {
      background: none;
      margin-left: 7px;
      width: 100%;

      li {
        border-radius: 1px;
        border: 1px solid $gray-darker;
        font-size: 1rem !important;
        margin-right: 10px;
        opacity: .5;
        padding-left: 0;
        text-align: center;
        width: 30%;
        line-height: 33px;

        &.checkmark {
          background-color: $gray-lightest;
          opacity: 1;
        }

        &.active {
          border: none;
          background-color: #fff;
          opacity: 1;
        }

        a {
          background-image: none;
          font-size: 1rem !important;
          width: 100%;

          span {
            font-size: 1rem !important;
            padding-left: 0;
          }
        }
      }
    }
  }

  .subscription,
  .company,
  .user {
    width: 100%;
  }

  .user {
    margin-bottom: 26px;
  }

}

.order-flow-content {
  width: 100%;

  .signup-confirm {
    margin: 0 10px;

    .order-flow-confirm-title {
      text-align: center;
    }

    .item_head {
      overflow: auto;

      h2 {
        margin: 0;
        font-size: 2.4rem;
      }

      [class^="edit"] {
        float: right;
      }
    }
  }
}

.client-form--order {
  padding: 26px 10px;
  
  .client-form__parralel-info {
    display: none;
  }
}

.client-form--order-confirm {

  .client-form__fieldset {
    width: 100%;

    input[type="submit"] {
      margin-left: auto;
    }
  }

  .client-form__fieldframe {

    legend {
      font-size: 2.4rem;
    }
  }
}