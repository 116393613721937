$app-ext-page-width: 1200px;
$app-ext-page-left-width: 240px;
$app-ext-row-spacing: 62px;
$app-ext-row-y-margins: 30px;

@mixin app-ext-button-link() {
  a {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 1.6rem;
    line-height: 25px;
  }
}

@mixin app-ext-link-color() {
  color: $blue-dodger;
  font-weight: 600;
}

@mixin app-ext-link() {
  @include app-ext-link-color();
  text-decoration: none;

  &:visited,
  &:link {
    color: $blue-dodger;
  }
}

@mixin app-ext-links() {
  a {
    @include app-ext-link();
  }
}

@mixin app-ext-links-list() {
  @include app-ext-link-color();
  @include app-ext-links();
}


.app-ext__content {
  display: flex;
  max-width: $app-ext-page-width;
  margin-left: auto;
  margin-right: auto;

  margin-top: $app-ext-row-spacing;
  margin-bottom: $app-ext-row-spacing;
}

.app-ext__content-left {
  position: relative;
  width: $app-ext-page-left-width;
  min-width: $app-ext-page-left-width;
  margin-left: 10px;
}

.app-ext__content-left-top--sticky {
  width: $app-ext-page-left-width;
  position: fixed;
  top: 20px;
  z-index: 300;
  overflow: initial;
  transition: box-shadow 2s linear;
}

.app-ext__content-right {
  width: 100%;
  margin-left: 40px;
  margin-right: 15px;
}

.app-ext__content-tile__content {
  display: none;
}

.app-ext__content-icon {
  width: $app-ext-page-left-width;
  height: $app-ext-page-left-width;
  border-radius: 6px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1), 0 4px 12px 0 rgba(47, 51, 68, 0.15);
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: auto;
    max-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.app-ext__content-owner-link {
  margin-top: 42px;

  @include app-ext-button-link();
}

.app-ext__content-trial-link {
  margin-top: 7px;

  a {
    border: 1px solid $blue-dark-newid;
    background: white;
  }

  a,
  a:link,
  a:visited {
    color: $blue-dark-newid;
  }

  @include app-ext-button-link();
}

.app-ext__content-prices {
  margin-top: 12px;
  color: black;

  .field-label {
    display: none;
  }

  >.field>.field-items>.field-item {
    display: flex;
    justify-content: space-between;
  }

  .field-name-field-appext-prices-label {
    margin-right: auto;
  }

  .field-name-field-appext-prices-price {
    margin-left: 6px;
    font-weight: 600;
  }

  .field-name-field-appext-prices-suffix {
    margin-left: 6px;
  }

}

.app-ext__content-prices--simple,
.app-ext__content-prices--empty {
  text-align: center;
}

.app-ext__content-prices--empty {
  color: $blue-dodger;
  cursor: pointer;
  font-weight: 700;
}

.app-ext__content-prices--narrow {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;

  .field-name-field-appext-prices-label {
    margin-right: 0;
  }

  .field-name-field-appext-prices-prefix {
    margin-left: 6px;
  }
}

.app-ext__content-prices-meta-block {
  overflow: hidden;
}

.app-ext__content-demands {
  padding-top: 12px;
  width: $app-ext-page-left-width;
  color: black;

  .field-label {
    display: none;
  }
}

.app-ext__content-demands--sticky {
  position: fixed;
  bottom: 40px;
}

.app-ext__content-demands__header {
  font-size: 1.8rem;
  font-weight: 600;
}

.app-ext__content-demands__body {
  margin-top: 12px;
}

.pane-app-ext-page-body-content {
  &:after {
    content: "";
    clear: both;
    display: table;
  }
}

.app-ext__content-company {
  font-style: italic;
}

.client-form__app-ext-comment div.star a,
div.field-name-field-appext-rating div.star,
.app-ext__star {
  margin-right: 5px;
  height: 17px;
  width: 16px;
  display: block;
  background-size: cover;
  background-image: url("../images/star.svg");

  .on {
    height: 100%;
    width: 100%;
    display: block;
    background-size: cover;
    background-image: url("../images/star-active.svg");
    background-position-y: 0;
  }

  .off {
    background: none;
  }
}

.app-ext__icon-tags {
  display: flex;
  width: 100%;
  margin-top: 12px;
  height: 22px;
  gap: 10px
}

.app-ext__icon-tags-data {
  display: none;
}

.app-ext__icon-tag {
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
}

.app-ext__icon-tags--content {
  margin-top: 14px;
  height: 40px;
  gap: 24px
}

.app-ext__icon-tag--visma {
  background-image: url("../images/icon-visma-grey.svg");
}

.app-ext__icon-tag--visma--on {
  background-image: url("../images/icon-visma.svg");
}

.app-ext__icon-tag--dealer {
  background-image: url("../images/icon-certificeret-forhandler-grey.svg");
  margin-left: 3px;
}

.app-ext__icon-tag--dealer--on {
  background-image: url("../images/icon-certificeret-forhandler.svg");
}

.app-ext__icon-tag--partner {
  background-image: url("../images/icon-certificeret-partner-grey.svg");
}

.app-ext__icon-tag--partner--on {
  background-image: url("../images/icon-certificeret-partner.svg");
}

.app-ext__icon-tag--market {
  background-image: url("../images/icon-market-grey.svg");
}

.app-ext__icon-tag--market--on {
  background-image: url("../images/icon-market.svg");
}

.client-form__app-ext-comment div.star.on a,
.client-form__app-ext-comment div.star.hover a {
  background-image: url("../images/star-active.svg");
  background-position-y: 0;
}

.client-form__app-ext-comment {

  .client-form__fieldset {
    box-shadow: none;
    position: relative;
    padding: 30px 39px;

    .spinner-small {
      &:before {
        top: initial;
        margin-top: 15px;
      }
    }

    input.form-submit {
      width: 100%;
      background-color: #2c3650 !important;
      border: 1px solid #2c3650;

      &:disabled {
        background-color: $gray-dark-ligth !important;
        border-color: $gray-dark-ligth;
        cursor: default;
      }
    }
  }

  label {
    .form-required {
      display: none;
    }
  }

  textarea {
    resize: none;
  }

  .client-form__fieldset {
    .field-name-field-appext-rating {
      position: relative;

      .form-item {
        label.error {
          position: absolute;
          top: 55px;
          text-align: left;
        }
      }
    }
  }
}

div.field-name-field-appext-rating .description {
  display: none;
}

.field-name-field-appext-rating {
  display: flex;
  align-items: center;
}

.app-ext__content-company,
.app-ext__content-description,
.app-ext__reviews-line {
  line-height: 23px;
}

.app-ext__reviews-line {
  display: flex;
  align-items: center;
}

.app-ext__reviews-line__information {
  margin-left: 5px;

  b {
    font-weight: 600;
  }
}

.app-ext__reviews-line--not-enough__text,
.app-ext__reviews-line--empty__text {
  margin-left: 1px;
}

.app-ext__content-comments {
  margin-top: $app-ext-row-spacing;

  #comments {
    margin: 0;

    .comment-row {
      margin-top: 32px;

      &.first {
        margin-top: 0;
      }
    }

    .field-name-field-appext-c-company {
      font-size: 1.6rem;
      line-height: 20px;
      font-style: italic;
    }

    .field-name-comment-body {
      font-size: 1.6rem;
      line-height: 26px;
      ;
    }

  }
}

.app-ext__content-comment-headline {
  display: flex;

  >div:first-child {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 22px;
  }

  >div:last-child {
    margin-left: auto;
    font-size: 1.2rem;
    line-height: 17px;
  }
}

.app-ext__content-body-info {
  @include app-ext-links;
}

.app-ext__content-body-info__more {
  .field-name-field-appext-more-links {
    .field-item {
      margin-bottom: 15px;
    }
  }
}

.app-ext__content-body-info__more,
.app-ext__content-body-info__company,
.app-ext__content-comments__info,
.app-ext__content-body-abstract {
  margin-top: $app-ext-row-spacing;
  display: flex;
  justify-content: space-between;
  margin-right: $app-ext-row-y-margins;
  margin-left: $app-ext-row-y-margins;

  >div {
    width: 50%;

    &:first-child {
      padding-right: 20px;
    }

    >div {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.app-ext__content-body-info__company {

  .field-name-field-appext-support {
    p:first-of-type {
      padding-top: 0;
    }

    p:last-of-type {
      padding-bottom: 0;
    }
  }
}

.app-ext__content-body-info__logo,
.app-ext__content-comments__info-logo {
  text-align: center;

  img {
    width: auto;
    height: 220px;
  }
}

.app-ext__content-comments__form-holder {
  display: none;
  position: fixed;
  overflow: auto;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.app-ext__content-comments__results {
  @include client-form__fieldset;
  box-shadow: none;
  display: none;
  position: relative;
  flex-direction: column;
  align-items: center;
}

.app-ext__content-comments__results__img {
  margin: 10px 100px 0 100px;
}

.app-ext__content-comments__results__header {
  width: 310px;
  margin-top: 25px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  color: black;
}

.app-ext__content-comments__results__body {
  width: 310px;
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  line-height: 26px;
  color: black;
}

.app-ext__content-comments__results__close {
  width: 100%;
  margin-top: auto;

  a {
    margin-top: auto;
    width: 100%;
  }
}

.app-ext__content-comments__close {
  position: absolute;
  right: 20px;
  top: 17px;
  width: 13px;
  height: 13px;
  margin-top: 0;
  margin-left: 9px;
  background: url(../images/icon-close-bold.svg) no-repeat transparent;
  cursor: pointer;
}

.app-ext__teaser-name-line,
.app-ext__content-name-line,
.app-ext__content-comments-name-line {
  color: black;
  display: block;
}

.app-ext__teaser-name-line {
  font-size: 1.8rem;
}

.app-ext__teaser-name {
  cursor: pointer;
}

.app-ext__content-body-info__tags-list {
  @include app-ext-links-list();
}

$app-ext_comments_form_result_header_right_side: 280px;

.client-form__app-ext-comment {
  div.app-ext__content-comments-tags {
    overflow: hidden;
    text-overflow: ellipsis;
    color: $blue-dodger;
    white-space: nowrap;
    width: $app-ext_comments_form_result_header_right_side;

    @include app-ext-links-list();
  }
}

.app-ext__teaser-name,
.app-ext__content-name,
.app-ext__content-comments-name {
  display: inline;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 24px;
  margin-right: 5px;
  text-decoration: none;
  color: black;
  vertical-align: middle;
}

.app-ext__content-name {
  font-size: 2.4rem;
  line-height: 33px;
  margin-bottom: 0;
}

.app-ext__teaser-new,
.app-ext__teaser-premium,
.app-ext__content-new,
.app-ext__content-premium,
.app-ext__content-comments-new,
.app-ext__content-comments-premium {
  display: inline-block;
  vertical-align: middle;
}

.app-ext__teaser-new,
.app-ext__teaser-premium,
.app-ext__content-new,
.app-ext__content-premium,
.app-ext__content-comments-new,
.app-ext__content-comments-premium div {
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: $helvetica;
  line-height: 19px;
  padding: 0 5px;
  margin-right: 5px;

  border-radius: 9px;
  background-color: $blue-dodger-original;
}

.app-ext__content-new,
.app-ext__content-comments-new,
.app-ext__teaser-new {
  background-color: $medium-teal;
}

.app-ext__teaser-new,
.app-ext__teaser-premium {
  line-height: 16px;
}


.app-ext__teaser-company,
.app-ext__content-comments-company {
  font-size: 1.4rem;
  font-style: italic;
  color: black;
}

.app-ext__content-comments-description {
  $font-size: 14px;
  $line-height: 1.4;
  $lines-to-show: 2;

  font-size: $font-size;
  color: black;
  display: block;
  /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: $app-ext_comments_form_result_header_right_side;
  height: $font-size*$line-height*$lines-to-show;
  /* Fallback for non-webkit */
  margin: 0 auto;
  line-height: $line-height;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

}


.app-ext__teaser-icon,
.app-ext__content-comments__icon {
  width: 110px;
  min-width: 110px;
  height: 110px;
  border-radius: 6px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1), 0 4px 12px 0 rgba(47, 51, 68, 0.15);
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: auto;
    max-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.app-ext__teaser-body:hover {
  .app-ext__teaser-icon {
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1), 0 4px 18px 0 rgba(47, 51, 68, 0.15);
  }
}

.app-ext__teaser-icon {
  cursor: pointer;
}

.app-ext__icon-discount {
  background: url("../images/discount.svg") 0 center no-repeat transparent;
  height: 51px;
  width: 38px;
  position: absolute;
  top: 0;
  right: 8px;
}

.app-ext__content-comments-tile {
  display: flex;
  margin-bottom: 20px;
}

.app-ext__content-comments__results--error {
  display: none;
  @include client-form__fieldset;
  box-shadow: none;
  padding: 30px 39px;
}

.app-ext__content-comments__list {
  margin-top: 44px;
  margin-left: $app-ext-row-y-margins;

  section {
    article:nth-child(n+7) {
      display: none;
    }
  }
}

.app-ext__content-comments__actions {
  margin-top: 28px;
  display: flex;
  justify-content: center;

  a {
    border: 1px solid $blue-dark-newid;
    background-color: white;
    color: $blue-dark-newid;
    min-width: 240px;
    margin-right: 20px;
    margin-left: 20px;

    padding-right: 20px;
    padding-left: 20px;
    font-size: 1.6rem;
    line-height: 25px;

    &:link,
    &:visited {
      color: $blue-dark-newid;
    }
  }
}

.app-ext__content-comments__actions-more {
  display: none;
}

.app-ext__contnet-add-comment a {
  padding-right: 20px;
  padding-left: 20px;
  font-size: 1.6rem;
  line-height: 25px;
}

.app-ext__content-body-info__tags,
.app-ext__content-body-availability,
.app-ext__content-body-content {
  margin-top: $app-ext-row-spacing;
  margin-left: $app-ext-row-y-margins;
  margin-right: $app-ext-row-y-margins;
}

.app-ext__content-block__header {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 12px;
}

.app-ext__content-block__body {
  line-height: 26px;
}

.field-name-field-appext-ability {
  margin-left: 10px;
  margin-top: 0;
  margin-bottom: 0;
  min-height: 150px;

  li {
    margin-top: 13px;
    list-style: none;
    position: relative;

    &:before {
      content: '•';
      position: absolute;
      left: -10px;
      font-size: 1.4rem;
      font-family: none;
    }
  }
}

.app-ext__content-gallery-position-one {
  display: none;
}

.app-ext__content-gallery {
  background-color: $gray-app;
  position: relative;
  width: 100%;
  margin: 32px auto 0 auto;
  visibility: hidden;
  height: 435px;

  .owl-carousel {
    position: relative;
    z-index: 0;

    .owl-item {

      &>div {
        height: 435px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 24px;
        padding-bottom: $app-ext-row-spacing;
        padding-left: 70px;
        padding-right: 70px;

        &>img {
          display: inline-block;
          height: auto;
          width: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }


    }

    .owl-nav {

      .owl-prev,
      .owl-next {
        background-color: $white;
        border-radius: 0;
        height: 40px;
        width: 40px;
        margin: -21px 0 0 0;
        outline: 0;
        position: absolute;
        text-indent: -9999px;
        top: 50%;
        transition-duration: 300ms;
        transition-property: all;
        transition-timing-function: ease-in-out;
        z-index: 10;
      }

      .owl-prev {
        background: url("../images/owl-slider-arrow.svg") 0 center no-repeat $white;
      }

      .owl-next {
        background: url("../images/owl-slider-arrow.svg") center no-repeat $white;
        transform: rotate(180deg);
        right: 0;
      }
    }
  }

  .owl-dots {
    text-align: center;
    margin-top: -48px;
    z-index: 500;
    position: absolute;
    left: 0;
    right: 0;

    .owl-dot {
      border-radius: 50%;
      height: 10px;
      width: 10px;
      margin: 0 6px;
      padding: 0;
      background-color: #D8D8D8;

      &.active {
        background-color: black;
      }

      &:focus {
        outline: 0;
      }
    }
  }
}

.app-ext__teaser-body {
  display: flex;

  .app-ext__content-comments-description {
    display: block;
    height: unset;
  }
}

.app-ext__teaser-tags {
  overflow: hidden;
  text-overflow: ellipsis;
  color: $blue-dodger;
  white-space: nowrap;
  width: 230px;

  @include app-ext-links-list();

  a {
    font-size: 1.4rem;
  }
}

.app-ext__teaser-data {
  display: none;
}

.app-ext__filters-holder {
  visibility: hidden;
}

.pane-app-ext-view,
.pane-app-ext-view-search {
  visibility: hidden;
}

.app-ext__apps-info {
  visibility: hidden;
}

#content:has(.app-ext__view) {
  overflow: hidden;
}

.app-ext__view {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  position: relative;

  .view-content {
    display: flex;
    flex-wrap: wrap;

    .col-md-4,
    .col-sm-6 {
      padding-left: 9px;
      padding-right: 9px;
    }
  }

  &.app-ext__view--filtered {
    margin-top: 25px;
  }
}

.app-ext__teaser {
  width: 350px;
  margin-bottom: 25px;

  .app-ext__reviews-line {
    font-size: 1.4rem;

    .app-ext__star:not(:first-child) {
      display: none;
    }
  }
}

.app-ext__teaser-link {
  @include app-ext-link();
  font-weight: 700;
}

.app-ext__filters {
  box-shadow: 0 5px 10px -5px $gray-lights;
  overflow: auto;
  margin-bottom: 18px;
  background-color: white;
}

.app-ext__filters--sticky {
  position: fixed;
  top: 0;
  z-index: 300;
  width: 100%;
  overflow: initial;
  transition: box-shadow 2s linear;

  .app-ext__filters__hanger {
    display: none;
  }
}

.client-form--app-ext-filters {
  max-width: 1200px;
  margin-top: 0px;
  margin-left: auto;
  margin-bottom: 20px;
  margin-right: auto;

  overflow: hidden;

  .client-form__fieldset {
    display: flex;
    margin-top: 20px;
    margin-left: 10px;
    width: 100%;

    input[type=checkbox] {
      display: inline-flex;
    }

    .form-item--select {
      padding-left: 15px;
      width: 200px;
      background-image: url(../images/select-down.svg);
      background-size: 14px 14px;
      margin-right: 8px;

      &.focused {
        background-image: url(../images/select-up.svg);
        border-color: $orange;
        color: #000;
      }
    }

    .form-item {
      margin-bottom: 0px;
      position: relative;

      .app-ext__filters__search {
        @include placeholder-color($gray-more-dark);
        border-radius: 23.5px;
        width: 200px;
        padding-left: 40px;
        background-image: url(../images/icon-search-small.svg);
        background-repeat: no-repeat;
        background-position: 15px 16px;
        background-size: 15px 15px;

        &:focus {
          background-image: url(../images/icon-search-small-orange.svg);
        }
      }

      .app-ext__filters__search--close {
        padding-right: 35px;
      }
    }

    div.form-type-checkbox {
      margin-top: 0;
      margin-bottom: 13px;
      overflow: auto;
      display: table;

      label {
        color: #000;
        font-weight: 600;
        position: unset;
        display: table-cell;
        vertical-align: top;
      }

      &.disabled {
        label {
          color: $gray-lights;
        }

        input {
          border-color: $gray-app;
        }
      }
    }

    div.app-ext__filters__category-box--requirement__special-item {
      margin: 10px;
    }

    .app-ext__filters__category-box--requirement__special-descr {
      margin: 10px;
      color: black;

      ul {
        margin-top: 10px;
        margin-bottom: 12px;
        margin-left: 20px;
      }
    }

    .app-ext__filters__sort-select-wrapper {
      margin-left: auto;
    }

    .app-ext__filters__sort-select {
      width: auto;
      border: 0;
      text-align: right;
      padding-right: 50px;
    }

  }
}

.app-ext__filters__category-box-wrapper {
  position: absolute;
  z-index: 230;
}

.app-ext__filters__category-box {
  background-color: #fff;
  box-shadow: 0 0 10px 0 $gray-lights;
  border-radius: 3px;
  padding: 30px;
  margin-top: 55px;
  column-count: 4;
  column-gap: 70px;
}

.app-ext__filters__category-box--industry {
  column-count: 2;
}

.app-ext__filters__category-box--requirements {
  padding: 20px;
  column-count: unset;
}

.app-ext__filters__category-box--requirement__holder {
  display: flex;
}

.app-ext__filters__category-box--requirement__special {
  display: inline-block;
  margin-right: 20px;
}

.app-ext__filters__category-box--requirement__general {
  display: inline-block;
  margin: 10px;
  column-count: 2;
  column-gap: 10px;
}

.app-ext__filters__category-box-wrapper--sort {
  right: calc((100% - 1200px)/2);
}

.app-ext__filters__category-box--sort {
  min-width: 255px;
  column-count: 1;
  padding-top: 17px;
  padding-bottom: 35px;
}

.app-ext__apps {
  max-width: 960px;
  margin: 0 auto;

  .pane-title {
    display: none;
  }

  .view-empty {
    display: none;
  }

  .view-content>div {
    will-change: transform;
    transform: translateZ(0);
  }
}

.app-ext__filters-bar {
  color: $gray-more-dark;
  font-size: 1.6rem;
  margin-top: 13px;
  margin-left: 10px;
  display: flex;
}

.app-ext__filters-bar__label {
  margin-right: 9px;
}

.app-ext__filters-bar__tags {
  display: flex;
}

.app-ext__filters-bar__tag {
  display: flex;
  align-items: center;
  border-radius: 3px;
  padding: 2px 7px;
  margin-right: 8px;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  background-color: $gray-light;
  color: black;
  cursor: pointer;

  &:after {
    content: "";
    display: inline-block;
    width: 13px;
    height: 13px;
    margin-top: 1px;
    margin-left: 9px;
    background: url(../images/icon-close-bold.svg) no-repeat transparent;
  }

}

.app-ext__filters-bar__more {
  margin-right: 8px;
}

.app-ext__filters-bar__clear {
  color: $orange;
  font-weight: bold;
  cursor: pointer;
}

.app-ext__filters__sort-list-element {
  font-weight: 600;
  margin-top: 18px;
  color: black;
  cursor: pointer;
}

.app-ext__apps-empty {
  min-height: 500px;
  padding-top: 300px;
  margin-top: 30px;
  background-repeat: no-repeat;
  background-size: 450px;
  background-position: top;

  a {
    white-space: nowrap;
  }
}


.app-ext__hero-canvas {
  background-color: $gray-app;
}

.app-ext__hero-holder {
  max-width: 1200px;
  padding-right: 5px;
  margin-left: auto;
  margin-right: auto;
}

.app-ext__hero {
  display: flex;
  align-items: center;
  width: 100%;
  height: 500px;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: right;
}

.app-ext__hero-content {
  width: 50%;
  overflow: hidden;
  margin-left: 10px;

  h1 {
    font-size: 3.6rem;
    line-height: 3.6rem;
    margin: 0;
  }
}

.app-ext__filters__search-close {
  display: none;
  position: absolute;
  right: 20px;
  top: 17px;
  width: 13px;
  height: 13px;
  margin-top: 0;
  margin-left: 9px;
  background: url(../images/icon-close-bold.svg) no-repeat transparent;
  cursor: pointer;
}

.app-ext__filters__search-close--results {
  display: block;
}

.app-ext__filters__hanger {
  display: flex;
  align-items: center;
  position: absolute;
  background-color: $blue-dodger;
  color: white;
  border-radius: 3px;
  box-shadow: 0 0 10px 0 $gray-light;
  white-space: nowrap;
  padding: 13px;
  margin-top: -57px;

  &:before {
    content: "";
    position: absolute;
    top: 44px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 9px 0 9px;
    border-color: $blue-dodger transparent transparent transparent;
  }
}

.app-ext__filters__hanger-close {
  width: 13px;
  height: 13px;
  margin-top: 2px;
  margin-left: 9px;
  background: url(../images/icon-close-white-bold.svg) no-repeat transparent;
  cursor: pointer;
}

.app-ext__apps-info {
  display: none;

  max-width: 1200px;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 25px;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;

  font-weight: 600;
  color: black;
}

.app-ext__filters-mobile-filters {
  display: none;
}

.app-ext__colorbox {
  #cboxWrapper {
    border-radius: 4px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1), 0 8px 24px 0 rgba(47, 51, 68, 0.25);
  }

  #cboxTopLeft,
  #cboxTopCenter,
  #cboxTopRight {
    height: 20px;
  }

  #cboxLoadedContent {
    margin: 0;
  }

  #cboxWrapper {
    #cboxClose {
      top: 10px;
      right: 5px;
      background-image: url(../images/icon-close-blue.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 20px 20px;
      width: 40px;
      height: 40px;
      outline: 0;
    }
  }

  #cboxContent {
    #cboxClose {
      top: -10px;
      right: -10px;
    }
  }

  #cboxPrevious {
    background: url(../images/owl-slider-arrow.svg) center no-repeat transparent;
    background-size: 40px 40px;
    left: 0;
  }

  #cboxNext {
    background: url(../images/owl-slider-arrow.svg) center no-repeat transparent;
    background-size: 40px 40px;
    transform: rotate(180deg);
    left: unset;
    right: 0;
  }

  #cboxPrevious,
  #cboxNext {
    border-radius: 0;
    height: 70px;
    width: 70px;
    margin: -36px 0 0 0;
    outline: 0;
    position: absolute;
    text-indent: -9999px;
    top: 50%;
  }

  .app-ext__teaser {
    margin-left: 55px;
    margin-right: 55px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .app-ext__teaser-body {
    display: block;
  }

  .app-ext__content-comments-description {
    margin: 0;
  }

  .app-ext__teaser-name {
    cursor: default;
  }

  .app-ext__teaser-company {
    font-style: normal;
  }

  .app-ext__teaser-icon {
    cursor: default;
  }

  .app-ext__teaser-content {
    margin-top: 6px;
  }

  .app-ext__teaser-tags {
    width: 100%;
    white-space: normal;
  }

  .app-ext__content-prices {
    font-size: 1.4rem;
    margin-top: 0;
  }

  .app-ext__content-prices--narrow {
    margin-left: 0;
  }

  .app-ext__content-prices--empty {
    cursor: default;
    color: black;
    font-weight: 300;
    text-align: left;
  }
}

.app-ext__view-data {
  display: none;
}

.app-ext__popup {
  width: 630px;
  height: auto;
}

.app-ext__popup-header {
  display: flex;
  justify-content: space-between;
}

.app-ext__popup-media {
  height: 100%;
  width: 367px;
  text-align: center;
  flex-shrink: 0;

  img {
    height: 206px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1), 0 4px 12px 0 rgba(47, 51, 68, 0.15);
  }

  .youtube-player {
    margin: 0;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1), 0 4px 12px 0 rgba(47, 51, 68, 0.15);

    &.sdres,
    &.hqres {
      img {
        height: unset;
      }
    }
  }

}

.app-ext__popup-block-header {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 22px;
  margin-top: 20px;
  color: black;
}

.app-ext__popup-actions {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  margin-top: 20px;

  .app-ext__content-owner-link {
    margin-top: 0;
    margin-left: 25px;
  }
}

.app-ext__popup-goto {
  color: #2C3650;
  font-family: "Open Sans";
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  text-align: right;
  text-decoration: none;
}

.app-ext__popup-logo-box {
  width: 100%;
  margin-right: 20px;
}

.app-ext__popup-body {
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;

  >div {
    width: 50%;
    margin-right: 13px;

    ~div {
      margin-right: 0;
      margin-left: 13px;
    }
  }

  .app-ext__popup-block-header {
    margin-bottom: 6px;
  }

  .field-name-field-appext-ability {
    li {
      margin-top: 6px;
    }
  }

  .field-name-field-appext-abstract {
    .field-item {
      overflow: hidden;
      transition-property: height;
      transition-duration: 0.3s;
      transition-timing-function: ease-out;
      transition-delay: 0s;
    }
  }
}

.app-ext__popup-descr-more-link {
  color: $blue-dodger;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;

  &::after {
    content: "";
    display: inline-block;
    vertical-align: text-bottom;
    margin-left: 3px;
    width: 19px;
    height: 19px;
    background-size: cover;
    background-image: url("../images/external-link-blue-bold.svg");
  }
}

.app-ext__popup-availability {
  font-size: 1.4rem;

  .app-ext__popup-block-header {
    margin-top: 0;
  }
}

.owl-item .field-video,
.app-ext__content-body-video {
  text-align: center;
  margin-top: $app-ext-row-spacing;

  .youtube-player {
    width: 560px;
    height: 315px;
    margin: 0 auto;
    padding-bottom: 0;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);

    img {
      transition: unset;
    }

    &.maxres {
      img.youtube-thumb {
        width: 560px;
        height: 315px;
      }
    }
  }
}

.owl-item .field-video .youtube-player img {
  max-height: unset;
}

.owl-item .field-video {
  margin-top: auto;
}

.app-ext__view--all {
  margin-top: 20px;
  opacity: 0;
}

.block-site-app-ext {
  margin-bottom: 0;
}

.page-comment {
  .comment-form {
    margin: 20px;

    .app-ext__content-comments-description {
      margin: 0;
    }
  }
}

.node-type-app-ext {
  #cboxContent {
    #cboxClose {
      background-size: 25px;
      width: 20px;
      height: 20px;
    }
  }
}

.app-ext__icon-tag--tooltip {
  position: relative;
  cursor: help;
  z-index: 0;
}

.app-ext__icon-tag--tooltip:hover {
  z-index: 10;
}

.app-ext__icon-tag--tooltip:hover::before {
  border-style: solid;
  border-color: #fff transparent;
  border-width: 0 8px 12px 8px;
  top: 24px;
  bottom: unset;
  left: 1px;

  position: absolute;
  z-index: 99;
  content: "";
}

.app-ext__icon-tag__text {
  display: none;
}

.app-ext__icon-tag__text::first-line {
  font-weight: 600;
}

.app-ext__icon-tag--tooltip:hover .app-ext__icon-tag__text {
  display: block;
  position: absolute;

  background: #fff;
  box-shadow: 0px -2px 14.2px rgba(41, 40, 62, 0.10);
  border-radius: 6px;
  color: var(--Midnight-Blue, #29283E);

  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;

  bottom: unset;
  top: 36px;
  left: -26px;
  padding: 16px;

  width: 348px;
  white-space: pre-wrap;
}

@media (min-width: 876px) {
  .app-ext__icon-tags--content .app-ext__icon-tag--tooltip:hover::before {
    top: 42px;
    left: 12px;
  }

  .app-ext__icon-tags--content .app-ext__icon-tag--tooltip:hover .app-ext__icon-tag__text {
    top: 52px;
  }
}

.app-ext--app-row:hover {
  z-index: 1;
}

.app-ext__teaser-icon-box {
  width: 110px;
  margin-right: 10px;
}
