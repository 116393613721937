/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 * 3. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 */
html {
  font-family: $base-font-family; /* 1 */
  font-size: 100% * ($base-font-size / 16px); /* 3 */
  // Establish a vertical rhythm unit using $base-line-height.
  // @include adjust-leading-to(1);
}

/* Remove default margin. */
body {
  margin: 0;
  padding: 0;
}

p,
pre {
  margin: 0;
  padding: 0.75em 0;
}

button,
input,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 2 */
  margin: 0; /* 3 */
  max-width: 100%; /* 5 */
  box-sizing: border-box; /* 5 */
}

/**
 * Lists
 */
dl,
menu,
ol,
ul {
  /* Address margins set differently in IE 6/7. */
  margin: 1.5em 0;
}
ol,
ul {
  ol,
  ul {
    /* Turn off margins on nested lists. */
    margin: 0;
  }
}
dd {
  margin: 0 0 0 $indent-amount; /* LTR */
}

/* Address paddings set differently in IE 6/7. */

menu,
ol,
ul {
  //padding: 0 0 0 $indent-amount; /* LTR */
  padding: 0; /* LTR */
}

label {
  display: block;
  font-weight: 700;
}

figure {
  margin: 0;
}

img {
  border: 0;
  max-width: 100%;
  width: auto;
}
