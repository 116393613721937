.node-accountant-full {
  color: $gray-darkest;
  font-size: 1.5rem;
  margin-bottom: 5rem;

  .panel-pane {
    display: block;
    clear: both;
    padding: 0;
  }

  h1 {
    font-size: $h1-font-size;
  }

  h2 {
    font-size: $h2-font-size;
  }

  h3{
    font-size: $base-font-size;
    font-weight: bold;
  }

  .inline-content{
    display: inline;
    div{
      display: inline;
    }
  }

  .headline{
    margin-bottom: 3rem;
  }

  .block-top-margin{
    margin-top: 2rem;
  }

  .infoblock{
    margin-bottom: 2rem;
    white-space: nowrap;
  }

  .simple-list{
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .block-header{
    margin-bottom: 0.5rem;
  }

  .paragraph-header{
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  .paragraph-header-top{
    margin-bottom: 1rem;
  }

  .contact-field{
    border-bottom: 1px solid $gray-lighter;

    .field-label{
      width: 50%;
      float: left;
    }
  }

  .article-separator {
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid $gray-lighter;
  }

  .gm-style-iw{
    font-size: 8px;
  }

}

[class*="-accountant"] {
  .button-oversight a {
    @extend %btn-white-light-base;
    background-color: transparent;
    white-space: nowrap;

    &:hover {
      background-color: transparent;

      &::before {
        background: url("../images/arrow-left-dark-blue.svg") center center no-repeat;
      }
    }

    &::before {
      @extend %btn-white-light-pseudo-base;
      background: url("../images/arrow-left-light-blue.svg") center center no-repeat;
      left: -5px;
      top: 1px;
    }
  }

  .button-try a{
    @extend %btn-white-light-base;
    background-color: transparent;
    color: $orange !important;
    white-space: nowrap;

    &:hover {
      background-color: transparent;
      color: $orange-darker;
    }
  }

  #cludo-search-form-revisorlist{
    margin-bottom: 30px;
  }
}

.field-name-field-accountant-numclients {
  padding: 0 0 13px 23px;
  position: relative;
  background: none;
}

.field-name-field-accountant-numclients:before {
  width: 19px;
  height: 18px;
  content: "\0020";
  display: block;
  overflow: hidden;
  text-indent: -1000px;
  position: absolute;
  top: 2px;
  left: 0px;
  background: transparent url("../images/user-male3-black.svg") no-repeat;
}

[class*="-accountants"] {
  [class*="-list-overview"] {
    font-size: 1.5rem;

    .top{
      border-top: 1px solid $gray-lighter;
    }

    .row{
      border-bottom: 1px solid $gray-lighter;
      margin: 0;

      div[class^="col-"]{
        padding: 1.5rem 0 1.5rem 0;
      }

      .links{
        margin-top: 2em;
      }

      .title {
        font-weight: bold;
        margin-bottom: 2em;
      }

      .description{
        margin-right: 10rem;
        margin-left: 2rem;
      }

      .services{
        margin-bottom: 2rem;
      }

      .address{
        white-space: nowrap;

        a {
          background: url("../images/arrow-right-small-blue.svg") no-repeat 93% 3px transparent;
          padding-right: 2em;
        }
      }
    }
  }

  a {
    color: $blue;
    text-decoration: none;
  }

  .pager-previous,
  .pager-next{
    padding: 0;

    a {
      display: inline-block;
      width: 16px;
      height: 30px;
      margin-bottom: -10px;
      text-indent: -1000px;
    }
  }

  .pager-next{
    a{
      background-image: url("../images/arrow-next-grey.png");
    }
  }

  .pager-previous{
    a {
      background-image: url("../images/arrow-prev-grey.png");
    }
  }

  .panel-pane.description{
    @extend %wrap;

    border-top: 1px solid $gray-lighter;
    margin-top: 2.2rem;

    .pane-title{
      background: $white;
      display: inline-block;
      font-size: 2.2rem;
      line-height: 1.2em;
      top: -0.7em;
      padding-right: 0.5em;
      position: relative;
    }

    .pane-content{
      text-align: center;
    }
  }

  .panel-2col-stacked .center-wrapper{
    @extend %wrap;

    .panel-col-last{
      float: right;
      width: auto;
    }

    &:after{
      content: " ";
      display: table;
      clear: both;
    }
  }

  .pane-content {
    margin: 0 !important;
  }

  nav.alphabet {
    margin: 20px -13px;

    ul {
      display: table;
      width: 100%;

      li {
        a {
          color: $gray-darker;
          text-decoration: none;
          &:hover {
            color: $gray;
          }
        }
        display: table-cell;
        text-align: center;
        margin: 0;
      }
    }
  }
}

.view-accountant-map{
  margin: 15px 0 50px 0;
}

.view-accountant-city-overview {
  display: inline-block;
  margin-bottom: 50px;
  width: 100%;

  h3 {
    border-bottom: 1px solid;
    border-color: $gray-lighter;
    font-size: 2rem;
    font-weight: 300;
    padding: 15px 0 15px 0;
    margin-bottom: 15px;
  }

  ul {
    column-count: 4;
    column-gap: 50px;
    margin-bottom: 5rem;
    margin-top: 0;
    width: 100%;

    li {
      clear: both;
      display: block;
      font-size: 1.6rem;
      margin-left: 0;
      margin-bottom: 0.5rem;
    }

    a {
      &:link, &:visited {
        color: $blue;
      }
    }
  }
}

.google-cse-form {
  font-size: 0 !important;
  text-align: center;
  white-space: nowrap;

  .form-text{
    border-right-width: 0;
    border: 1px solid $blue-dark-newid;
    color: $gray-dark;
    display: inline-block;
    font-size: 2rem;
    height: 55px;
    line-height: normal;
    margin:0;
    padding-left: 1rem;
    vertical-align: top;
    width: 418px;
  }

  .form-submit {
    background: $white url(../images/search-arsenic.svg) no-repeat 50% 50%;
    border: 1px solid $blue-dark-newid;
    background-size: 20px 20px;
    border-radius: 0;
    height: 55px;
    width: 82px;
  }
}