form {
  input:not([type="checkbox"]):not([type="submit"]) {
    padding: 0 10px 0;
  }

  input[type="checkbox"] {
    text-align: center;
  }
}

// Search form in main content area.
#search-block-form,
#search-form {
  width: 940px;
  margin: 50px auto 0;
  padding-left: 200px;

  .form-text {
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    height: 55px;
    margin-bottom: 30px;
    margin-top: 20px;
    padding: 5px;
    width: 450px;
  }

  .form-submit {
    background: #fff url(/sites/all/themes/eco2014/images/search-arsenic.svg) no-repeat 50% 50%;
    background-size: 20px 20px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    color: transparent;
    font-size: 16px;
    height: 55px;
    left: -5px;
    margin-bottom: 30px;
    margin-top: 20px;
    padding: 5px;
    position: relative;
    width: 60px;
  }
}

.block-webform {

  .messages,
  .messages--error,
  .error {
    width: 100%;
  }
}


.field-name-field-product .tooltip::before {
  left: 20px;
  top: -33px;
}

.field-name-field-product .tooltip::after {
  bottom: 50px;
  color: white;
  left: -97px;
}