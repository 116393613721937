.site_app_login {
  &.system_message {
    padding: 1rem;
  }
}

.client-form--login {
  margin-right: 0;
  padding-bottom: 33px;

  .client-form__intro{
    width: 100%;
  }

  .forgot-your-login {
    float: none;
    display: block;
    margin-top: 29px;

    a {
      font-size: 1.3rem;
    }
  }

  .form-error {
    position: relative;
    top: 0;
    margin-bottom: 20px;
    width: 100%;

    .form-error-content {
      padding: 13px;
    }

    &::before {
      display: none;
    }

    &::after {
      display: none;
    }
  }
}