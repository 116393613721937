@charset"UTF-8";
.mb_YTPBar,.mb_YTPBar span.mb_YTPUrl a {
  color:#fff
}
@font-face {
  font-family:ytpregular;
  src:url(../font/ytp-regular.eot)
}
@font-face {
  font-family:ytpregular;
  font-style:normal;
  font-weight:300;
  src:url(../font/ytp-regular.woff)format('woff'),url(../font/ytp-regular.ttf)format('truetype');
}
.mb_YTPlayer:focus {
  outline:0
}
.mbYTP_wrapper {
  -webkit-backface-visibility:hidden;
  backface-visibility:hidden;
  box-sizing:border-box;
  display:block;
  perspective:1000;
  transform-style:preserve-3d;
  transform:translateZ(0)translate3d(0,0,0);
}
.mb_YTPlayer .loading {
  animation:fade .1s infinite alternate;
  background:rgba(0,0,0,.51);
  border-radius:5px;
  color:#fff;
  font-family:"Droid Sans",sans-serif;
  font-size:12px;
  padding:2px 4px;
  position:absolute;
  right:10px;
  text-align:center;
  top:10px;
}
@keyframes fade {
  0% {
    opacity:.5
  }
  100% {
    opacity:1
  }
}
.fullscreen {
  border:none!important;
  display:block!important;
  height:100%!important;
  left:0!important;
  margin:0!important;
  opacity:1!important;
  position:fixed!important;
  top:0!important;
  width:100%!important;
}
.mbYTP_wrapper iframe {
  max-width:4000px!important
}
.inline_YTPlayer {
  background:rgba(0,0,0,.5);
  border-radius:4px;
  box-shadow:0 0 5px rgba(0,0,0,.7);
  left:0;
  margin-bottom:20px;
  overflow:hidden;
  position:relative;
  vertical-align:top;
}
.inline_YTPlayer img {
  border:none!important;
  margin:0!important;
  padding:0!important;
  transform:none!important;
}
.mb_YTPBar,
.mb_YTPBar .buttonBar {
  box-sizing:border-box;
  left:0;
  padding:5px;
  width:100%;
}
.mb_YTPBar .ytpicon {
  font-family:ytpregular;
  font-size:20px;
}
.mb_YTPBar .mb_YTPUrl.ytpicon {
  font-size:30px;
}
.mb_YTPBar {
  background:#333;
  bottom:0;
  display:block;
  font:14px/16px sans-serif;
  height:10px;
  opacity:.1;
  position:fixed;
  text-align:left;
  transition:opacity .5s;
  z-index:1000;
}
.mb_YTPBar.visible,
.mb_YTPBar:hover {
  opacity:1
}
.mb_YTPBar .buttonBar {
  background:0 0;
  font:12px/14px Calibri;
  position:absolute;
  top:-30px;
  transition:all .5s;
}
.mb_YTPBar:hover .buttonBar {
  background:rgba(0,0,0,.4);
}
.mb_YTPBar span {
  display:inline-block;
  font:16px/20px Calibri,sans-serif;
  height:25px;
  position:relative;
  vertical-align:middle;
  width:30px;
}
.mb_YTPBar span.mb_YTPTime {
  width:130px;
}
.mb_YTPBar span.mb_OnlyYT,
.mb_YTPBar span.mb_YTPUrl {
  cursor:pointer;
  display:block;
  position:absolute;
  right:10px;
  top:6px;
  width:auto;
}
.mb_YTPBar span.mb_YTPUrl img {
  width:60px
}
.mb_YTPBar span.mb_OnlyYT {
  left:300px;
  right:auto;
}
.mb_YTPBar span.mb_OnlyYT img {
  width:25px;
}
.mb_YTPBar .mb_YTPMuteUnmute,
.mb_YTPBar .mb_YTPPlaypause,
.mb_YTPlayer .mb_YTPBar .mb_YTPPlaypause img {
  cursor:pointer;
}
.mb_YTPBar .mb_YTPProgress {
  background:#222;
  bottom:0;
  height:10px;
  left:0;
  width:100%;
}
.mb_YTPBar .mb_YTPLoaded {
  background:#444;
  height:10px;
  left:0;
  width:0;
}
.mb_YTPBar .mb_YTPseekbar {
  background:#000;
  bottom:0;
  box-shadow:rgba(82,82,82,.47)1px 1px 3px;
  height:10px;
  left:0;
  width:0;
}
.mb_YTPBar .YTPOverlay {
  -webkit-backface-visibility:hidden;
  -webkit-transform-style:"flat";
  backface-visibility:hidden;
  box-sizing:border-box;
}
.YTPOverlay.raster {
  background:url(../images/raster.png)
}
.YTPOverlay.raster.retina {
  background:url(../images/raster@2x.png)
}
.YTPOverlay.raster-dot {
  background:url(../images/raster_dot.png)
}
.YTPOverlay.raster-dot.retina {
  background:url(../images/raster_dot@2x.png)
}
.mb_YTPBar .simpleSlider {
  border-radius:3px;
  border:1px solid #fff;
  box-sizing:border-box;
  cursor:pointer!important;
  height:10px;
  margin-right:10px;
  overflow:hidden;
  position:relative;
  width:100px;
}
.mb_YTPBar.compact .simpleSlider {
  width:40px
}
.mb_YTPBar .simpleSlider.muted {
  opacity:.3
}
.mb_YTPBar .level {
  background-color:#fff;
  bottom:0;
  box-sizing:border-box;
  left:0;
  position:absolute;
}
.mb_YTPBar .level.horizontal {
  height:100%;
  width:0
}
.mb_YTPBar .level.vertical {
  height:auto;
  width:100%
}
