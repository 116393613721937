$base-font-size: 10px;
$base-line-height: 16px;

$h1-font-size: 2 * $base-font-size;
$h2-font-size: 1.5 * $base-font-size;
$h3-font-size: 1.17 * $base-font-size;
$h4-font-size: 1 * $base-font-size;
$h5-font-size: 0.83 * $base-font-size;
$h6-font-size: 0.67 * $base-font-size;

// Wrapper
$wrap-width: 611px;
$wrap-page-width: 624px;
$wrap-page-wide-width: 660px;
$wrap-page-max-width: 660px;

// Trial, presentation
$form-width-default: 442px;
$default-border-radius: 2px;