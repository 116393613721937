/* infobox for GMap */
.map_infobox {
  width: 580px !important;

  ul li {
    margin-left: 10px;
    padding: 0px;
  }

  .bd {
    border-bottom: 1px solid;
    border-color: $gray-lighter;
  }

  /* Tabs styles */
  .md_list_t4 {
    padding-left: 10px;
    margin-left: 0;
    margin-bottom: 18px !important;
    list-style: none;
    padding-bottom: 0;
  }

  .md_list_t4 li {
    padding-bottom: 0
  }

  .md_list_t4 > li > a {
    display: block;
  }

  .md_list_t4 > li > a:hover {
    text-decoration: none;
  }

  .md_list_t4_tabs:before,
  .md_list_t4_tabs:after {
    display: table;
    content: "";
  }

  .md_list_t4_tabs:after {
    clear: both;
  }

  .md_list_t4_tabs > li {
    float: left;
  }

  .md_list_t4_tabs_ext1 > li {
    float: right;
  }

  .md_list_t4_tabs {
    border-bottom: 1px solid $gray-lighter;
  }

  .md_list_t4_tabs > li {
    margin-bottom: -1px;
    padding-right: 10px;
  }

  .md_list_t4_tabs > li > a {
    padding: 8px 12px 8px 12px;
    margin-right: 2px;
    line-height: 12px;
    border: 1px solid transparent;
    border-radius: 4px 4px 0px 0px;
  }

  .md_list_t4_tabs > .sel > a,
  .md_list_t4_tabs > .sel > a:hover {
    color: $gray-darkest;
    background-color: $white;
    border: 1px solid $gray-lighter;
    border-bottom-color: transparent;
    cursor: default;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .row::after, .cell:after {
    content: "\0020";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
    overflow: hidden;
  }

  .cell {
    float: left;
  }

  a, a:visited {
    color: $blue-light;
    text-decoration: none;
  }

  .content_services li{
    width: 15rem;
  }

  a:hover {
    color: $blue-light;
    text-decoration: underline;
  }

  .content_services li:before{
    content: '\2713';
    padding-right: 0.5rem;
  }
}
