$client-form-font-weight: 300;
$client-form-font-size: 1.4rem;
$client-form-main-color: $orange;
$client-form-filed-width: 430px;
$client-form-width: 480px;
$client-form-filed-height: 47px;
$client-form-text-main-color: $gray-more-dark;
$client-form-field-border: 1px solid $gray-lights;
$client-form-radio-label-height: 35px;
$client-form-item-bottom-margin: 18px;
$client-form-radio-left-space: 27px;
$client-form-filed-x-padding: 10px;


@mixin label-error {
  position: relative;
  top: 17px;
  margin-top: -17px;
  width: 100%;
  font-size: 1.2rem;
  line-height: 1.7rem;
  text-align: right;
  color: red;
}

@mixin client-form__fieldset {
  background-color: white;
  margin: 40px auto;
  box-shadow: 2px 2px 9px 0 $nobel-light;
  border: none;
  border-radius: 6px;
  width: $client-form-width;
  padding: 25px;
  text-align: left;
  color: $client-form-text-main-color;
}

.client-form__label__link,  .privacy-text{
  a {
    font-size: $client-form-font-size;
    text-decoration: underline;
    color: $client-form-text-main-color;

    &:link {
      color: $client-form-text-main-color;
    }

    &:visited {
      color: $client-form-text-main-color;
    }
  }
}

.client-form {
  text-align: center;

  font-size: $client-form-font-size;
  font-weight: $client-form-font-weight;

  a:not([class^="btn-"]) {
    text-decoration: underline;
    color: $client-form-main-color;

    // There is some default styles that style 'link' pseudo class.
    // We need it here to override.
    &:link {
      color: $client-form-main-color;
    }

    &:visited {
      color: $client-form-main-color;
    }
  }

  .client-form__intro {
    color: $gray-darkest;
  }

  .client-form__fieldset {
    @include client-form__fieldset;

    label a, .privacy-text a{
      text-decoration: underline;
    }

    label, .privacy-text{
      @extend .client-form__label__link;

      display: block;
      position: relative;
      text-align: left;
      font-size: $client-form-font-size;
      font-weight: $client-form-font-weight;
      line-height: 1.9rem;
      text-align: left;
      margin-bottom: 3px;

      &.option {
        font-weight: $client-form-font-weight;
      }
    }

    .form-item {
      margin-top: 0;
      margin-bottom: $client-form-item-bottom-margin;
      margin-right: 0;
      margin-left: 0;
      text-align: left;

      div.error {
        @include label-error;
      }

    }

    .webform-component-checkboxes,
    .form-checkboxes,
    .form-type-checkbox,
    .form-item--checkbox {
      margin-top: 29px;
    }

    .form-item--checkbox label {
      font-weight: $client-form-font-weight;
    }

    select,
    .form-item--select,
    input[type="checkbox"],
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"],
    input[type="tel"],
    input[type="image"],
    textarea {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      display: block;
      border: $client-form-field-border;
      border-radius: 0;
      width: $client-form-filed-width;
      padding-left: $client-form-filed-x-padding;
      padding-right: $client-form-filed-x-padding;
      font-size: 1.6rem;
      background-color: white;
      caret-color: $client-form-main-color;

      &:focus {
        outline: none;
        border: 1px solid $client-form-main-color;
      }
    }

    input[type="number"]{
      &:disabled{
        color: $gray-lightest;
        border-color: $gray-lightest
      }
    }


    input[type="checkbox"],
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="tel"],
    input[type="image"],
    textarea {
      color: $black;
    }

    select,
    .form-item--select {
      color: $client-form-text-main-color;
    }

    // Select element.
    .form-type-radio {
      margin-bottom: 0;

      input[type=radio] {
        display: none;
        height: unset;

        &:disabled+label {
          color: $gray-dark-originaler;
          cursor: default;
        }

        &:disabled+label::before {
          background: url(../images/radio-inactive.svg) no-repeat;
          background-size: cover;
          cursor: default;
        }

        +label::before {
          content: '';
          display: inline-block;
          position: absolute;
          top: 6px;
          left: 0;
          width: 20px;
          height: 20px;
          background: url(../images/radio-unselected.svg) no-repeat;
          background-size: cover;
          cursor: pointer;
        }

        &:checked+label::before {
          content: '';
          background: url(../images/radio-selected.svg) no-repeat;
          background-size: cover;
        }
      }

      label {
        display: block;
        margin-left: 0;
        margin-bottom: 0;
        min-height: $client-form-radio-label-height;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: $client-form-radio-left-space;
        cursor: pointer;
      }
    }

    select,
    .form-item--select,
    input {
      @include placeholder-hide;

      display: block;
      height: $client-form-filed-height;
      padding-top: 0;
      padding-bottom: 0;

      &.error {
        margin-bottom: 0;
        border-bottom-color: red;
      }
    }

    input[type="radio"].error+label {
      border-bottom: 1px solid red;
    }

    textarea {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    select,
    .form-item--select {
      border-radius: 0;
      background-image: url('../images/arrow-select-default.svg');
      background-position: calc(100% - 20px) center;
      background-repeat: no-repeat;
      -webkit-appearance: none;
    }

    input[type=checkbox] {
      float: left;
      display: block;
      margin-top: 0px;
      margin-right: 16px;
      width: 20px;
      height: 20px;
      padding: 0;

      outline: 0;
      -webkit-appearance: none;
      -moz-outline-radius: 0;

      &:checked {
        border-color: $client-form-main-color;
        background-image: url('../images/checkmark-white.svg');
        background-color: $client-form-main-color;
      }

      +.title,
      +.suffix {
        margin-bottom: 0;
      }

      +label {
        display: table;
        font-size: $client-form-font-size;
        margin-bottom: 0;

        span {
          font-size: $client-form-font-size;
        }

        // Fix a demo form custom styling on the checkbox label.
        .suffix {
          .mls {
            margin-left: 0;
          }

          .pls {
            padding-left: 0;
          }
        }

      }
    }

    .tooltip-question-icon {
      position: relative;
      top: 2px;
      float: none;
      display: inline-block;
      margin: 0 0 0 3px;
      width: 18px;
      height: 18px;
      font-style: normal;
      font-size: $client-form-font-size;
      text-align: left;

      &::before {
        content: "?";
        position: relative;
        top: -1px;
        left: 5px;
      }
    }

    .tooltip {
      &:hover {
        &::before {
          content: "";

          bottom: 3px;
          right: -20px;
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;
          border-right: 6px solid white;
        }

        &::after {
          bottom: -65px;
          left: inherit;
          right: -270px;
          padding: 15px;
          box-shadow: 2px 2px 8px 1px rgba(0, 0, 0, 0.5);
          background-color: #fff;
          color: #000;
        }
      }
    }

    .webform-component-select,
    .form-item--select,
    .select {
      label {
        display: none;
      }
    }

    .webform-component-select-label-display,
    .form-item--select-label-display {
      label {
        display: unset !important;
      }
    }

    .spacer-line {
      position: relative;
      z-index: 1;
      text-align: center;
    }

    .spacer-line span {
      padding: 0 40px;
      background: #fff;
    }

    .spacer-line:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 50%;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      border-top: 1px solid #ddd;
      width: 100%;
    }

    .form-submit {
      color: $white;
      background: $blue-havelock;
      border-style: unset;
      font-weight: 700;
      padding: 10px 40px;
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
      cursor: pointer;
    }

    .client-form__calendar {
      position: relative;

      .client-form__calendar__text {
        position: absolute;
        width: $client-form-filed-width;
        height: $client-form-filed-height;
        padding-top: 10px;
        padding-left: 10px;
        font-size: 1.4rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .client-form__calendar__button {
        background-image: url('../images/field-date-orange.svg');
        background-position: calc(100% - 20px) center;
        background-repeat: no-repeat;
        background-color: transparent;
      }
    }

    .client-form__listitem {
      display: inline-block;
      margin-top: 20px;
      line-height: 1.9rem;

      .client-form__listitem__content {
        display: inline-block;
        font-size: 1.4rem;
      }

      .client-form__listitem__remove {
        display: inline-block;
        margin-left: 8px;
        width: 13px;
        cursor: pointer;
        background: url(../images/x-icon-orange.svg) no-repeat 50% 50%;
      }
    }

    .form-item-wrapper--description-enclosed {
      margin-top: 0;
      margin-bottom: 4px;
      margin-left: 0;
      margin-right: 0;

      .form-item {
        margin: 0;

        &.form-disabled {
          color: $gray-dark-originaler;

          &+.form-item__description {
            color: $gray-dark-originaler;
          }
        }
      }

      .form-item__description {
        display: block;
        margin-top: -6px;
        margin-left: $client-form-radio-left-space;
        color: $client-form-main-color;
      }

      label {
        border: none;
        width: 100%;
        height: unset;
        min-height: unset;
      }

      .form-item__description {
        font-size: 1.2rem
      }
    }


    // Radio button with additional data must show the error line under additional data
    .error {
      .form-item-wrapper--description-enclosed {
        border-bottom: 1px solid red;

        input[type="radio"].error+label {
          border-bottom: none;
        }

        &+.error {
          top: 12px;
        }
      }
    }
  }

  .client-form__fieldframe,
  fieldset {
    margin: 0 0 10px 0;
    padding: 0;
    height: auto;
    border: none;
    overflow: auto; //Fix, so the margins are not collide with the enclosed elemetn's margins.

    legend {
      font-size: 1.6rem;
      margin-bottom: 15px;
    }

    .form-item:last-child {
      margin-bottom: 0;
    }

    .form-item.mbn {
      margin-bottom: 0;
    }
  }

  .client-form__fieldframe:not(:first-of-type),
  fieldset:not(:first-of-type) {
    margin-top: 25px;
  }

  // Style the uploaded file names.
  .tabledrag-processed {

    .form-item--select,
    select {
      width: auto;
      background-position: calc(100% - 3px) center;
    }

    td {
      background: white;
    }
  }

  .form-item--select {
    cursor: pointer;
    line-height: $client-form-filed-height;
  }

  .client-form__items-row {
    display: flex;
    justify-content: space-between;

    >div {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    input[type="text"] {
      width: 100%;
    }
  }


  .captcha_error {
    font-size: 1.4rem;
    line-height: 1.7rem;
    margin-bottom: 18px;
    color: white;

    a,
    a:visited,
    a:link {
      color: white;
    }

    &:before {
      content: ' ';
      display: inline-block;
      font-size: 0;
      height: 16px;
      width: 16px;
      margin-right: 5px;
      vertical-align: middle;
      background-image: url("../images/exclamation-point.svg");
    }
  }
}

.client-form__app-ext-comment,
.client-form--app-comment {
  .client-form__fieldset {
    .form-item {
      label.error {
        @include label-error;
      }
    }
  }
}

.client-form--left {
  .client-form__fieldset {
    margin-left: 0;
  }
}

.client-form--dark {
  .client-form__fieldset {
    // Dark background.
    background-color: black;
    color: white;

    label, .privacy-text {
      a {
        // Link colors must be light.
        color: white;

        &:link {
          color: white;
        }

        &:visited {
          color: white;
        }
      }
    }

    .tooltip-question-icon {
      // White border fo the tooltip icon because of the dark background.
      border-color: white;
      color: white;
    }
  }
}

.client-form--center {
  .client-form__fieldset {
    margin-left: auto;
  }
}

.client-form--slim-left {
  @extend .client-form--left;

  text-align: left;

  .client-form__fieldset {
    padding: 0;
    background-color: transparent;
    box-shadow: none;

    .form-submit {
      margin-left: 0;
    }
  }
}

.client-form--slim-line {
  @extend .client-form--slim-left;

  .client-form__fieldset {
    margin: 0;
  }
}

.ui-datepicker-form {
  width: 321px;
  font-family: $open-sans;
  font-size: 1.4rem;
  font-weight: normal;
  border: none;
  border-radius: 6px;
  box-shadow: 2px 2px 9px 0 #D5D5D5;
  color: $client-form-text-main-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;

  .ui-widget-header {
    margin: 12px 24px 0px 24px;
    background: none;
    border: none;
    font-weight: normal;
    color: $client-form-text-main-color;
    padding: 0;

    .ui-icon {
      background: url(../images/owl-slider-arrow.svg) center no-repeat transparent;
      background-size: 25px;
    }
  }

  .ui-datepicker-title {
    margin: 0 28px;
  }

  .ui-datepicker-prev {
    opacity: 0.45;
    left: 0;
  }

  .ui-datepicker-next {
    opacity: 0.45;
    right: 0;
    transform: rotate(180deg);
  }

  .ui-datepicker-next,
  .ui-datepicker-prev {
    top: 1px;
    cursor: pointer;

    &.ui-state-disabled {
      opacity: 0.25;
    }
  }

  .ui-state-disabled {
    opacity: 0.4;
  }

  .ui-datepicker-next-hover,
  .ui-datepicker-prev-hover {
    background: none;
    border: none;
  }

  table {
    width: 287px;
    margin-bottom: 5px;
  }

  thead {
    border-bottom: 1px solid #CACACA;
  }

  th {
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: normal;
    text-align: center;
    height: 41px;
    width: 41px;
    padding: 0;
    border: 0;
  }

  td {
    height: 43px;
    width: 41px;
    padding: 0;
    border: 0;

    .ui-state-default {
      height: 34px;
      width: 34px;
      margin: auto;
      font-size: 1.2rem;
      padding: 0;
      border: none;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $client-form-text-main-color;
    }

    .ui-state-default.ui-state-active {
      background-color: $client-form-main-color;
      color: white;
      border-radius: 50%;
    }

  }
}
