#skip-link {

  a,
  a:visited {
    padding: 1px 0 2px 0;
  }
}


/**
 * Breadcrumb navigation.
 */
.breadcrumb {
  font-size: 1.3rem !important;
  padding: 10px;
  width: 100%;

  .crumbs-separator {
    display: inline-block;
    color: $gray-darker;
    padding: 0 3px;
  }

  a,
  span {
    display: inline-block;
    font-size: 1.3rem !important;
  }
}

.messages {
  padding: 7px 7px 7px 33px;
  background-position: 5px 5px;
}

%error {
  width: 100%;
  margin: 13px auto;
}

/* Primary tabs. */
.tabs-primary__tab {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.tabs-secondary__tab,
.tabs-secondary__tab.is-active {
  margin: 1.5em/2 2px;
}

.more-help-link a {
  padding: 1px 0 1px 13px;
}

.unpublished {
  font-size: 49px;
}

#user-login,
#user-pass {
  width: 325px;
  padding-bottom: 20px;
}

.form-type-radio,
.form-type-checkbox {
  .description {
    margin-left: 1.6em;
  }
}

html.js #user-login-form li.openid-link,
#user-login-form li.openid-link {
  margin-left: -13px;
}

form {
  th {
    padding-right: 1em;
    /* LTR */
    border-bottom: 2px solid #ccc;
  }
}

.fieldset-legend {
  html.js .collapsible & {
    padding-left: 10px;
  }
}

.progress {

  .bar {
    background: #ccc;
    border-color: #666;
    margin: 0 0.2em;
    border-radius:2px;
  }
}

.tooltip {

  &:hover::after {
    border-radius: 1px;
    bottom: 17px;
    left: -68px;
    padding: 3px 10px;
    width: 143px;
  }

  &:hover::before {
    border-width: 4px 4px 0 6px;
    bottom: 13px;
    left: 5px;
  }
}

.tooltip-question-icon {
  font-size: 0.7rem;
  height: 13px;
  line-height: 12px;
  margin-right: 13px;
  margin-top: 5px;
  padding-right: 2px;
  width: 13px;
}

.popupwrapper {
  iframe {
    min-width: 364px;
    min-height: 205px;
  }

  &:before {
    width: 16px;
    height: 16px;
    margin: -10px -7px 0 0;
    font-size: 16px !important;
    line-height: 16px;
    border-radius:16px;
  }
}

.region-chat-tab {
  height: 72px;
  width: 72px;
  right: 26px;
  bottom: 26px;

  font-size: 1.6rem;
  line-height: 72px;

  .block-menu_chat_tab_link {
    height: 72px;
    width: 72px;

    box-shadow: 1px 1px 5px rgba(170, 170, 170, 0.6);
  }

  .chat-close {
    height: 8px;
    width: 8px;

    bottom: -5px;
    left: 2px;

    background-size: 5px;
    box-shadow: 1px 1px 5px rgba(170, 170, 170, 0.6);
  }

  &.chat-in-footer {
    .block-menu_chat_tab_link {
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6);
    }

    .chat-close {
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6);
    }
  }
}

.media_embed {
  margin: 0 13px;
}

.css-spinner:after {
  border-right: 1px solid #ffbf5e;
  border-top: 1px solid #ffad33;
  border-left: 1px solid #f39200;
  border-bottom: 1px solid #bf7300;
  height: 13px;
  left: 317px;
  margin: 3px 0 0 -3px;
  width: 13px;
}

.region-chat-tab {
  display: none;
}

.popupwrapper {
  iframe {
    position: absolute;
    width: 80%;
    height: 50%;
    border: 0;
    left: 10%;
    top: 25%;
    min-width: initial;
    min-height: initial;
  }

  &:before {
    right: 90%;
  }
}

.html.section-sikkerhed {
  .ul-g1 {
    grid-template-columns: 1fr;
    text-align: left;
    width: 100%;
    margin: 26px 0 !important;

    li {
      margin: 7px 0;
    }
  }
}

/** Fix on suppor page **/
div.sorry-for-the-mess {
  height: auto;
  padding: 10px;
  margin: 0 -10px;

  span {
    margin-left: 13px;
    font-size: 16px;
  }
}

/** Fix regnskabsprogram **/

.section-regnskabsprogram {
  .node-dictionary {
    .pane-block-6 {
      .fsc>p {
        font-size: 2.9rem !important;
      }
    }
  }

  .quote-bullet li {
    width: 100%;
    margin-left: -3px;
    padding-left: 7px;
    margin-bottom: 33px;
    padding-right: 13px;
    margin-left: 23px;
    font-size: 9px;
  }

  iframe {
    width: 100%
  }

  .section-13 {
    .s1of2 {
      padding: 0 13px !important;

      img {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}

/** FIX 
http://local.e-conomic.dk/regnskabsprogram/revisorer-bogholdere/revisor
**/
.page-node-3128 {
  .section-2 {
    .fsc>div>div {
      width: 100% !important;
      padding: 0 !important;
      margin-right: 0 !important;
    }
  }
}

/** FIX
http://local.e-conomic.dk/regnskabsprogram/revisorer-bogholdere/bogholder
**/
.page-node-3163 {
  .section-2 {
    .fsc>div>div {
      width: 100% !important;
      padding: 0 !important;
      margin-right: 0 !important;
    }
  }
}

/** FIX cookie dialog **/
body.html .banner .content {
  .text-wrap .text {
    font-size: 1rem;
    padding: 7px;
  }

  .no button,
  .yes button {
    font-size: 1.3rem;
  }
}

.hide-desktop{
  display: unset;
}