/**
 * General page.
 */
$height-micro: 70px;
$height-tiny: 100px;
$height-small: 200px;
$height-medium-3: 300px;
$height-medium-4: 400px;
$height-medium: 500px;
$height-large: 700px;
$content-narrow: 600px;
$content-wide: 680px; // Text should be 550px wide. The container has 15px on each side.
$background-size-width: 480px;

.fci {
  clear: both;
  overflow: initial;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;

  ul, ol {
    margin: 0;
  }

  // Overwrite default settings for block element inside .field-collection-item element.
  .s1of2 {
    .block {
      margin-bottom: 0;
    }
  }

  // Transition 1st section faster than all other.
  // opacity: 0;
  // transition: opacity 0.2s ease-in-out;

  // Transition other sections a little slower.
  //&:not(.section-1) {
  //  transition: opacity 0.5s ease-in-out;
  //}
  
  &.animate,
  &.app-hero {
    opacity: 1;
  }
  &.app-hero-overview{
    opacity: 1;
  }
}

.fcic {
  margin: 0 auto;
  max-width: $wrap-page-width; // 960px (_init.scss)
}

// Overwrite fixed width if full-width chosen.
.full-section-width {
  .fcic {
    max-width: 100% !important;
  }
}

.h-micro {
    .fsc {
        height: $height-micro;
    }
}

.h-tiny {
    .fsc {
        height: $height-tiny;
    }
}

.h-small {
  .fsc {
    height: $height-small;
  }
}

.h-medium-3 {
  .fsc {
    height: $height-medium-3;
  }
}

.h-medium-4 {
  .fsc {
    height: $height-medium-4;
  }
}

.h-medium {
  .fsc {
    height: $height-medium;
  }
}

.h-large {
  .fsc {
    height: $height-large;
  }
}

// Shared properties
.fciw {
  display: table;
  overflow: initial;
}

.border-top {
  border-top: 1px solid;
  border-color: $gray;
}

.l1 {
  .fcic {
    background-image: none;
  }

  .fciw {
    width: 100%;
  }

  .fsc {
    width: 100%;
    display: table-cell;
    vertical-align: middle;
  }

  .block {
    .node-feature {
      text-align: right;
    }
  }
}

.l2 {
  .fcic {
    background-image: none;
  }

  .fciw {
    margin: 0 auto;
  }

  .fsc {
    display: table-cell;
    margin: 0 auto;
    vertical-align: middle;
    width: $content-wide;
  }
}

.l3 {
  .fcic {
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: $background-size-width;
  }

  .fsc {
    width: $content-narrow;
    display: table-cell;
    vertical-align: middle;
  }
}

.l3-r {
  .fcic {
    background-repeat: no-repeat;
    background-position: 0 100%;
    background-size: $background-size-width;
  }

  .fciw {
    margin-left: 50%;
  }

  .fsc {
    width: $content-narrow;
    display: table-cell;
    vertical-align: middle;
  }
}

.l4 {
  &.align-side {
    .fcic {
      background-repeat: no-repeat;
      background-position: 500px 100%;
      background-size: 450px;
      max-width: wrap-page-width; // was 1280px, is now 960px
    }

    // Align image middle
    &.valign-middle {
      .fcic {
        background-position: 600px 50%;
      }
    }
  }

  &.align-center {
    .fcic {
      background-repeat: no-repeat;
      background-position: 680px 100%;
      background-size: 400px;
      max-width: $wrap-page-width; // was 1280px, is now 960px
    }

    // Align image middle
    &.valign-middle {
      .fcic {
        background-position: 700px 50%;
      }
    }

    .fciw {
      margin-right: 50%;
    }
  }

  .fsc {
    width: $content-narrow;
    display: table-cell;
    vertical-align: middle;
  }
}

.l4-r {
  &.align-side {
    .fcic {
      background-repeat: no-repeat;
      background-position: -30px 100%;
      background-size: 450px;
      max-width: $wrap-page-width; // was 1280px, changed to 960px to get narrow look
    }

    // Align image middle
    &.valign-middle {
      .fcic {
        background-position: 0 50%;
      }
    }

    .fciw {
      margin-left: 50%;
    }
  }

  &.align-center {
    .fcic {
      background-repeat: no-repeat;
      background-position: 200px 100%;
      background-size: 400px;
      max-width: $wrap-page-wide-width; // 1280px
    }

    // Align image middle
    &.valign-middle {
      .fcic {
        background-position: 45px 50%;
      }
    }

    .fciw {
      margin-left: 50%;
    }
  }

  .fsc {
    width: $content-narrow;
    display: table-cell;
    vertical-align: middle;
  }
}

.l5 {
  // If no content set 0 height
  &.no-content {
    .fsc {
      min-height: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .fcic {
    background-repeat: no-repeat;
  }

  .fciw {
    margin: 0 auto;
    width: $content-wide;
  }

  .fsc {
    display: table-cell;
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
    width: 100%;
  }
}

.l5-r {
  // If no content set 0 height
  &.no-content {
    .fsc {
      min-height: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .fcic {
    background-repeat: no-repeat;
  }

  .fciw {
    margin: 0 auto;
    width: $content-wide;
  }

  .fsc {
    display: table-cell;
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
    width: 100%;
  }
}

// Global
.h-micro,
.h-tiny {
  .fsc {
    padding: 20px 15px;
  }
}

.h-small {
  .fsc {
    padding: 40px 15px;
  }
}

.h-medium-3 {
  .fsc {
    padding: 40px 15px;
  }
}

.h-medium-4 {
  .fsc {
    padding: 40px 15px;
  }
}

.h-medium {
  .fsc {
    padding: 60px 15px;
  }
}

.h-large {
  .fsc {
    padding: 80px 15px;
  }
}

.h-auto {
  .fsc {
    padding: 0 15px;
  }
}

.no-padding {
  .fsc {
    padding: 0 15px;
  }

  .block {
    margin-bottom: 0;
  }
}

.fsc {
  position: relative;
}

// Arrow top of section
// Shared properties for top arrow.
[class*="arr-t-s"] {
  &::before {
    border-radius: 50%;
    content: '';
    position: absolute;
    z-index: 10;
  }
}

.arr-t-s1 {
  &::before {
    background: url("../images/arrow-down-section-orange.png") center center no-repeat;
    background-color: white;
    height: 70px;
    left: calc(50% - (59px/2));
    width: 70px;
    top: -35px;
  }
}

.arr-t-s2 {
  &::before {
    background: url("../images/arrow-down-section-white.png") center center no-repeat;
    background-color: $orange;
    height: 39px;
    left: calc(50% - (59px/2));
    width: 39px;
    top: -30px;
    border: 10px solid white;
  }
}

// Arrow bottom of section
// Shared properties for bottom arrow.
[class*="arr-b-s"] {
  &::after {
    border-radius: 50%;
    content: '';
    position: absolute;
    z-index: 10;
  }
}

.arr-b-s1 {
  &::after {
    background: url("../images/arrow-down-section-orange.png") center center no-repeat;
    background-color: white;
    height: 70px;
    left: calc(50% - (70px/2));
    width: 70px;
    bottom: -35px;
  }
}
.arr-b-s2 {
  &::after {
    background: url("../images/arrow-down-section-white.png") center center no-repeat;
    background-color: $orange;
    height: 39px;
    left: calc(50% - (59px/2));
    width: 39px;
    bottom: -30px;
    border: 10px solid white;
  }
}

.hide-section-link {
  position: absolute;
  right: 10px;
  z-index: 1;
}

.hide-section {
  display: block;
  margin-right: 20px;
  float: right;
  width: 30px; 

  .close-x {
    stroke: black;
    fill: transparent;
    stroke-linecap: round;
    stroke-width: 5;
  }
}
// Light orange/orange
.bg-grad1 {
  background-image: linear-gradient(to bottom, #f2a900, #ed7e23);
}
// Deep dark blue/dark blue
.bg-grad2 {
  background-image: linear-gradient(to bottom, #0b1329, #2c3650);
}
// Light gray/dark blue
.bg-grad3 {
  background-image: linear-gradient(to bottom, #a097aa, #2c3650);
}
// White/gray
.bg-grad4 {
  background-image: linear-gradient(to bottom, #fff, #fff 46%, #ccc);
}
// Light gray/gray
.bg-grad5 {
  background-image: linear-gradient(to bottom, #eee, #d5d5d5 45%, #777);
}
// Gray/dark gray
.bg-grad6 {
  background-image: linear-gradient(to bottom, #d5d5d5, #d5d5d5 46%, #29283E);
}