/*form styles*/
.responsive-mobile div[class*="block-eloqua_permission_form"] {
  height: unset;
}

/** New styles **/
.template-one-gdpr {
  height: 650px;

  .template-one-gdpr__header-section {
    width: $client-form-width;
  }

  .template-one-gdpr__wrapper {
    width: 930px;
    margin: 0 auto;
    text-align: center;
    padding-top: 40px;
    position: relative;

    fieldset {
      border: 0 none;
      box-sizing: border-box;
      width: 100%;
      margin: 0 auto;
      padding-top: 10px;

      /*stacking fieldsets above each other*/
      position: absolute;

      /*Hide all except first fieldset*/
      &:not(:first-of-type) {
        display: none;
      }
    }

    div#final-step {
      line-height: 1.5;
      margin: 70px auto;
      width: 50%;
    }
  }

  img {
    margin-top: 65px;
    margin-left: 10px;
    width: 100%;
  }

  .submit-final {
    @extend .btn-orange-l;
    border-radius: 50px;
  }

  .template-one-gdpr__final-step {
    img {
      width: auto;
      margin: 0;
    }
  }
}

.template-one-gdpr--no-header {
  height: 530px;
}

.template-one-gdpr-permission {
  height: 550px;

  .template-one-gdpr__header-section {
    width: 100%;
  }
}

.client-form--gdpr {
  @extend .client-form--left;

  .client-form__fieldset {
    p {
      margin: 0 0 20px 0;
      padding: 0;
    }

    .form-item--checkbox {
      margin-top: 20px;
    }

    .form-submit:disabled {
      background-color: #aaa !important;
    }
  }
}

.gdpr-center-vh {
  display: flex;
  align-items: center;
  align-content: center;

  & img {
    margin: 0 auto;
  }
}

.wd-4771 {
  &.template-one-gdpr {
    height: unset;
  }

  .template-one-gdpr__wrapper {
    width: 100%;

    fieldset {
      padding: 0;
    }
  }

  .client-form .client-form__fieldset {
    box-shadow: unset;
    padding: 0;
    width: 100%;
  }

  .template-one-gdpr__final-step {
    div#final-step {
      width: 100%;

      .icon {
        width: 100px;
      }

      h3,
      p {
        text-align: center;
        line-height: 1.5;
      }
    }
  }

  .client-form__fieldset {
    margin-left: auto;
  }

  .template-one-gdpr__header-section,
  .template-one-gdpr-mobile .template-one-gdpr__header-section {
    width: 100%;
  }

  .client-form .client-form__fieldset .form-submit {
    margin: 0;
  }

  .client-form .client-form__fieldset input[type="email"] {
    width: 100%;
  }

}

// NEW STYLES 2024

.insider-section {
  display: flex;
  margin: 80px 0;
  border-radius: 8px;
  box-shadow: 0 25px 116px rgba(41, 40, 62, .05),
    0 12.66px 50.57px rgba(41, 40, 62, .0338),
    0 5px 18.85px rgba(41, 40, 62, .025),
    0 1.09px 6.71px rgba(41, 40, 62, .012);

  a {
    text-decoration: none !important;
    color: #4573d2 !important;
    font-size: 1.4rem;
  }

  p, label {
    font-weight: normal !important;
  }

  .client-form {
    text-align: unset;
    color: #29283e;
    line-height: 26px;

    button {
      justify-content: center;
      border: initial;
    }
  }

  .insider-title {
    color: #29283E;
    font-size: 32px;
    line-height: 38px;
    font-weight: 700;
  }

  .insider-image {
    flex: 1;

    img {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
  }

  .input[type=email] {
    color: #29283e;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    font-family: "Open Sans";
    height: 42px;
    border: 1px solid #595959;
    border-radius: 4px;
    padding: 10px 12px;
    outline: 0;
    width: 100%;
  }

  .input[type=email]:focus {
    border-color: #4573D2;
  }

  .invalid-control input, .invalid-control input[type="radio"]:not(:checked)+label::before {
    border-color: red !important;
  }

  .invalid-control label {
    color: red;
  }

  .invalid-control .invalid-feedback {
    display: block;
  }

  .invalid-feedback {
    display: none;
    color: red;
  }

  .client-form__fieldset {
    background-color: unset;
    margin: unset;
    box-shadow: unset;
    border: none;
    border-radius: unset;
    width: unset;
    padding: unset;
    text-align: unset;
    color: unset;
  }

  .success-message, .failure-message {
    display: none;
    text-align: center;
    font-size: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80%;
  }

  .success-message img {
    margin-bottom: 20px;
  }
}


@media (max-width: 768px) {

  /* Makes the insider-section stack elements vertically on small screens */
  .insider-section {
    flex-direction: column;
    align-items: center;
    margin: 40px 0;

    .client-form__fieldset {
      padding: unset !important;
    }

    .insider-content {
      padding: 2rem;
    }

    .insider-form button {
      width: 100%;
    }
  }

  /* Adjusts padding and layout for the insider-content and image container for small screens */
  .insider-image {
    padding: 0rem;
    flex: none;
  }

  /* Ensures the image comes before the content when the flex-direction is column */
  .insider-image {
    order: -1;
  }

}

@media (min-width: 768px) {
  .insider-content {
    width: 60%;
    padding: 3rem 4rem;

    .client-form__fieldset {
      padding: unset;
    }
  }

  .insider-form button {
    width: auto;
  }

  .insider-image img {
    border-top-right-radius: 8px;
    border-top-left-radius: 0;
    border-bottom-right-radius: 8px;
  }
}
