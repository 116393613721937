.nc-line1 {
  margin: 20px 0;
}

[class^="template"] {
  text-align: center;

  p {
    padding-top: 0;
  }

  .fl {
    float: none;
  }

  .col {
    display: block;
    margin: 0 0 20px 0 !important;
    width: 100% !important;
  }

  .profile-image {
    width: 100%;

    img {
      width: 50%;
    }
  }

  [class*="s1of"] {
    margin-bottom: 20px;
    width: 100%;

    .box {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
      margin-right: 0;
    }

    &:nth-child(3) {
      position: inherit;
    }
  }
}

.template1 {
  .profile-image {
    margin-right: 20px;
    width: 111px;
  }
}

.template2 {
  .icon-block {
    height: 192px;
    padding: 7px 1px;
    margin-right: 7px;
    margin-bottom: 39px;
    box-shadow: $black-30 0 2px 4px;

    .top-block {
      height: 163px;
    }

    .bottom-block {
      padding-top: 3px;

      a {
        padding-top: 169px;
        top: -169px;

        &:hover {
          background: transparent url(../images/circle-orange.svg) 46px 0 no-repeat;
        }
      }
    }
  }
}

.template2,
.template3,
.template4,
.template15 {
  [class*="s1of"] h2 {
    font-size: 1.3rem;
  }

  .s1of2 {
    h2+p {
      padding-top: 3px;
    }

    p {
      padding-left: 59px;
      padding-right: 59px;
    }

    .icon {
      margin-bottom: 12px;
    }
  }

}

.template2,
.template3 {
  .box {
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 7px;
    border: 1px solid $gray-lighter;
    font-size: 1rem;
    margin-right: 13px;
    padding: 7px 13px;

    h2 {
      padding-bottom: 13px;
    }

    h3 {
      font-size: 1rem;
    }

    p {
      line-height: 3.3rem;
      min-height: 98px;
    }
  }

  .icon-wrapper {
    margin: 20px;
  }

  & .hover {

    .box:before {
      left: 69px;
      top: 17px;
      width: 49px;
      height: 49px;
    }
  }
}

.template3 {

  .bottom-link {
    padding-top: 7px;
    padding-bottom: 7px;
    margin-left: -13px;
  }
}

.template4,
.template6 {

  .s1of3 {

    &:first-child {
      margin-left: 0;
      margin-right: 0;
    }

    &:nth-child(3) {
      right: -29px;
    }

    .icon {
      margin-bottom: 13px;
    }
  }
}

.template4 {
  .icon-wrapper {
    //  zoom: 0.65;

    i.icon {
      height: 50px;
      width: 50px;
    }
  }
}

.template7 {

  .content-top,
  .content-bottom {
    padding: 13px 0;
  }

  .icon {
    height: 33px;
  }

  .col {
    width: 100%;
    margin: 0 2px;
    font-size: 0.9rem;

    .headline {
      padding: 7px 0 13px 0;
    }

    .price {
      padding-top: 7px;

      .currency {
        top: -15px;
      }

      .actual-price {
        font-size: 3.1rem;
      }
    }

    .per-month {
      top: -3px;
    }

    .text-top,
    .text-bottom {
      padding: 13px;
      height: 65px;
    }

    .text-top {
      height: 98px;
    }

    .bullet {
      height: 13px;
      width: 13px;
    }
  }
}

.template8 {
  text-align: left;

  .name,
  .address,
  .zip,
  .phone {
    display: block;
    width: 100%;
    padding: 3px;
  }

  .name {
    padding-left: 7px;
  }
}

.template9 {
  text-align: left;

  p {
    padding: 0;
  }

  .headline {
    font-size: 1.6rem;
    padding-right: 7px;
    top: -13px;
  }
}

.template10 {
  [class^="col"] {
    .read-more {
      font-size: 2rem;
    }
  }

  [class^="col"].clickable {
    height: 143px;

    p {
      padding: 0 26px;
    }

    .read-more {
      margin-top: 3px;
    }

    i.icon {
      height: 26px;
      width: 26px;
    }

    h2 {
      font-size: 1.3rem;
      padding-top: 7px;
    }
  }
}

.template10,
.template12,
.template31 {

  // grid 3 x 3
  .row {
    display: block;
  }

  [class^="col"] {
    border: none;
    border-bottom: 1px solid $gray-lightest;
    display: block;
    height: inherit;
    margin-bottom: 13px;
    width: 100%;

    h2,
    p {
      padding: 13px 0;
    }

    h2 {
      line-height: 2.9rem;
    }
  }

  .row:last-child [class^="col"] {
    border-bottom: 1px solid $gray-lightest;
  }
}

.template11 {
  .node-app.node-teaser {
    border-radius: 3px;
    min-height: 114px;
  }

  .field-name-field-app-icon {
    height: 110px;
    width: 100%;

    .icon-wrapper {
      padding-top: 35px;
    }
  }

  .field-name-field-app-name {
    margin: 15px 0 !important;
    padding: 0 !important;
  }

  .col-xs-6 {
    width: 100%;
  }

  .field-name-field-app-abstract {
    line-height: 2rem;
  }

  .read-more {
    position: inherit;
    bottom: inherit;
    padding: 0 8px;
    margin: 7px 0;
  }

  .col-md-3.col-sm-4.col-xs-6 {
    width: 100%;
    margin-right: 13px;
  }

  i.icon {
    height: 98px;
    margin-top: 33px;
    width: 98px;
  }

  .new-lable {
    font-size: 1rem;
    height: 26px;
    left: -7px;
    padding-top: 8px;
    top: -10px;
    width: 26px;
  }
}

.template12 {
  background-repeat: no-repeat;
  background-size: cover;

  .col3 {
    border-right: none !important;
  }

  [class^="col"] {
    border: 0 !important;
    height: 293px;
    opacity: 0.8;
  }

  .date {
    bottom: 0;
    position: relative;
    width: initial;
  }

  ul li {
    display: block;
    width: 100%;
  }
}

.template13 {
  margin: 0 7px;

  .s100px {
    width: 100%;

    .icon-wrapper {
      margin: 0 auto;
    }
  }

  h2 {
    font-size: 2.6rem;
    line-height: 3.3rem;
    margin-bottom: 1rem;
  }
}

.template14 {
  .link_row {
    height: auto;
    padding: 7px 0;

    h4 {
      line-height: 1.6rem;
      padding-bottom: 0.5rem;
    }
  }
}

.template15 {
  .clickable {
    margin-left: 0;
    margin-right: 0;
  }

  .box,
  img {
    width: 100% !important;
  }

  .box {
    padding-bottom: 13px;
  }

  p {
    margin-top: 7px;
    font-size: 1.8rem;
    line-height: 2.3rem;
  }
}

.template16 {
  .template-tooltip {
    display: none;
  }

  li,
  li:nth-child(3n+3) {
    margin-right: 26px;
    min-height: 55px;

    margin-bottom: 13px;
    width: 100%;

    >div>i {
      height: 26px;
      margin: 13px;
      width: 26px;
    }

    >div,
    >div+p {
      line-height: 1.2rem;
      top: 3px;
    }

    >div+p {
      font-size: 1rem;
    }
  }

}

.template17 {
  &:before {
    left: 17px;
  }

  >li>.circle {
    left: 10px;
    margin-left: 0;
    top: 10px;
  }

  >li>.panel {
    float: right;
    margin-bottom: 23px;
    width: 87%;
  }

  >li>.panel p {
    font-size: 1.4rem;
  }

  >li>.panel:before {
    border-left-width: 0;
    border-right-width: 10px;
    left: -10px;
    right: auto;
  }

  >li>.panel:after {
    border-left-width: 0;
    border-right-width: 9px;
    left: -9px;
    right: auto;
  }
}

.template18 {
  .row {
    display: table;
  }

  .text {
    display: table-cell;
    float: left;
    font-size: 1.3rem !important;
    text-align: left;
    width: 66% !important;
  }

  .link {
    display: table-cell;
    vertical-align: middle;

    a {
      font-size: 1.3rem !important;
    }
  }
}

.template19 {
  img {
    height: auto !important;
    width: auto !important;
  }

  h2 {
    margin: 7px;
  }

  p {
    font-size: 2rem;
  }
}

.template20 {
  .title {
    h2 {
      margin: 0 auto;
      width: 80%;
    }
  }

  .box {
    width: 100%;

    .text {
      width: 100%;
    }

    .logo {
      display: none;
    }

    &.right-box {
      margin-left: 0;
    }
  }
}

.template20-b {
  text-align: left !important;

  li {
    padding: 7px 7px;
    text-align: left;
  }

  .title {
    margin-left: 20px;

    &:before {
      height: 13px;
      margin: 0 7px 0 -22px;
      width: 13px;
    }
  }

  li>p.content {
    display: none;
    margin-left: 0;
  }
}

.template21,
.template21-b {

  &:before,
  &:after {
    background: none;
  }
}

.template22 {

  .fl,
  .fr {
    float: none;
  }

  .col1 {
    .wrapper {
      margin-bottom: 13px;
    }
  }

  .wrapper {
    width: 100%
  }

  .wrapper-text {
    left: 0;
    width: 1%;
  }
}

div.template23 {
  ul {
    li {
      display: table;
      width: 100%;
      height: 320px;

      .img {
        display: none;
      }

      .txt {
        width: 100%;
        padding: 26px 20px;

        .quote {
          font-size: 1.4rem;
          line-height: 2.6rem;
        }

        .splitter {
          margin: 20px auto;
        }

        .author {
          font-size: 1rem;
          margin-bottom: 20px;
        }

        .read-more {
          a {
            padding-bottom: 2px;
          }
        }
      }
    }
  }


}

.template25 {
  &>ul {
    &>li {
      margin-bottom: 20px;
      width: 100%;

      &:nth-child(odd) {
        margin-right: 10px;
      }

      &>.title {
        height: 78px;

        &>.icon-cell,
        &>.headline-cell {
          .icon {
            height: 42px;
            width: 42px;
          }
        }

        &>.headline-cell h2 {
          font-size: 1.4rem;
          line-height: 2.3rem;
          margin-right: 26px;
          margin-top: 13px;
          margin-bottom: 13px;
        }
      }

      &>.content {
        font-size: 1rem;
        line-height: 1.3rem;
        padding: 0 26px;

        &>.content-wrapper {
          padding-top: 7px;
          line-height: 2.3rem;
          text-align: left;

          &>.read-more {
            padding-bottom: 10px;
            padding-top: 7px;
            width: 100%;
          }

          p {
            padding-top: 13px;
            padding-bottom: 13px;
          }
        }

        li {
          margin-bottom: 13px;
        }
      }
    }
  }

  .icon-cell {
    width: 20%;
  }
}

.template26 {

  .internal:before,
  .half-circle {
    display: none;
  }

  .internal {
    h2 {
      margin-bottom: 13px;
    }
  }

  .add-module-info {
    margin-top: 20px !important;
  }

  li {
    display: block;
    width: 100%;
    margin-bottom: 20px;

    .top {
      display: block;
      width: 100%;
      border-radius: 0;
      height: inherit;
      padding-top: 13px;
      padding-bottom: 13px;
      background-color: $white;
      border-width: 3px;

      .bg-img {
        display: none;
      }
    }

    .text {
      line-height: 2.6rem;
      margin-top: 0;
      padding-top: 20px;
    }
  }
}

.template27 {
  li {
    float: left;
    width: 100%;

    &:nth-child(odd) {
      float: left;
    }

    &:nth-child(2) {
      margin-top: 0;
    }

    [class^="arrow"] {
      display: none;
    }

    .img {
      height: 100%;

      img {
        height: 100%;
        width: 100%;
      }
    }

    .text-wrapper p {
      font-size: 2rem;
      padding-top: 10px;
    }

    .counter {
      margin-left: 0;
      padding-top: 5px;
    }
  }
}

.template31 {
  display: none;
}

.template33 {
  font-size: 1.2rem;

  .icon-wrapper {
    height: 78px;
    padding-top: 20px;
    width: 78px;

    .icon {
      height: 39px;
    }
  }

  h2 {
    padding-bottom: 7px;
    padding-top: 20px;
  }

  .line {
    height: 10px;
  }
}

.template35,
.template35-static {
  li {
    margin-bottom: 13px;

    &:nth-child(5) {
      max-height: inherit;
      overflow: inherit;

      &:before {
        background: none !important;
      }
    }

    &:nth-child(n + 7) {
      visibility: visible!important;
      opacity: 1!important;
    }

  }

  .content-wrapper {
    line-height: 2.6rem;
  }

  .headline-cell {
    display: none;
  }
}

.template35,
.template35-static {

  .title {
    height: 26px;

    .icon-cell {
      width: 26px;
    }
  }

  .icon {
    height: 26px;
    width: 26px;
  }

  .headline-cell h3 {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }

  .content {
    font-size: 0.9rem;
    line-height: 1.3rem;

    .content-wrapper {
      padding-top: 7px;
      padding-right: 10px;
    }
  }
}

// auto-hide
.template35 {
  ul {
    li {
      &:nth-child(5) {

        &:before {
          content: '';
          height: 65px;
        }
      }
    }
  }

  .bounce {
    height: 13px;
    left: calc(50% - 12px);
    width: 12px;
  }
}

.template36 {
  li {
    display: inline-block;
  }
}

.template38 {
  &:before {
    content: initial;
  }

  li {
    display: block;
    width: 100% !important;

    &:nth-child(2) {
      padding-top: 20px;
      margin-bottom: 26px;
    }
  }
}

.template39 {
  .row {
    height: initial;

    div:first-child {
      text-align: left;
    }

    &.tbl-header,
    &.tbl-sub-header div:first-child {
      font-weight: 700;
    }

    div,
    .link-buy,
    .link-more,
    a[class^="btn"] {
      font-size: 1.6rem;
    }
  }

  .checkmark {
    margin-top: -3px;
    margin-bottom: -7px;
  }

  a[class^="btn"] {
    margin-bottom: 0;
  }
}

.nc-cards-t1 {
  &.nc-cards-v3 {
    .text {
      height: unset;
    }
  }

  &.nc-cards-v4 {
    .card {
      width: 80%
    }
  }
}

// Support tabs
.nc-tabs-content {
  .support-list-tabs li {
    width: 115px;
    height: 30px;
    font-size:15px;
    letter-spacing: -0.8px;
    margin: 0
  }
}

.nc-cards-t1 {
  i.icon {
    margin: 0 auto 5px auto;
    width:25px;
    height:25px;
  }

  &>.card {
    height: unset;
    width: 80%;
    border-radius: 4px;
    margin: 0 16px 16px;
  }

  .headline {
    margin-top: 7px;
  }

  .cta {
    min-height: 65px;
    padding-top: 21px;
    padding-left: 13px;
    padding-right: 13px;

    [class*="btn-"] {
      font-size: 1rem;
    }

    a:not([class*="btn-"]) {
      font-size: 1.6rem;
      top: 5px;
    }

  }

  .text {
    padding: 5px
  }

  &.nc-cards-v2>.card {
    height: unset;
  }

  &.nc-cards-v2 .cta {
    padding: 7px 13px;
  }

  &.nc-cards-v2,
  &.nc-cards-v3 {
    .text {
      min-height: 98px;
      padding-top: 7px;
    }
  }

  &.nc-cards-v3>.card {
    height: auto;
    margin: 0 10px 16px;
  }

  &.nc-cards-v3 .cta {
    padding-left: 20px;
    padding-right: 20px;
  }

  &.nc-cards-v4>.card {
    height: 88px;

    a {
      margin-top: -7px;
      padding-bottom: 13px;
      padding-top: 10px;
    }

    .headline {
      line-height: 0.65;
    }
  }  
}

.template-hero1 {
  flex-direction: column-reverse;
}

.template-x1 {
  padding: 0 10px;
  width: 100%;

  h2, p {
    width:100%
  }
}

.template-modal-a {
  .price-modal {
    .posts {
      width: 50%;
    }

    .content h2 {
      padding: 0 10px 20px;
      line-height: 1.2;
    }

    .footer {
      position: relative;
    }

    .footnote--heade__info{
      margin-top: 20px;
    }

    .footnote--marknote{
      margin-top: 20px;
    }
  }
}

.video_420, .video_500, .video_560, .video_640, .video_700, .video_900{
  width: 100%;
}

.owl-carousel-mobile-container{
  width: 100%;
  margin-left: -10px;

  .owl-dots {
    text-align: center;
    margin-top: 0;

    .owl-dot {
      border: none;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      margin: 0 6px;
      padding: 0;
      background-color: #d3d3d3;

      &.active {
        background-color: #2D2B34;
      }

      &:focus {
        outline: 0;
      }
    }
  }

  .lol-trust {
    .owl-item {
      padding-right: 10px;
      padding-left: 10px;
  
      li{
        width: 100%;
        margin-bottom: 35px;
        
        quote{
          display: block;
          position: relative;
        }

        quote:before {
          top: -30px !important;
        }

      }
  
      li:first-child {
        margin-right: 0
      }
  
      li:last-child {
        margin-left: 0
      }
  
      .profile-img{
        display: inline;
      }
    }
    .active{
      font-weight: normal;
    }
  
  }
}

.pricing-table{
  table{    
    width: 100%;
    border-collapse: collapse;

    &:not(:first-child){
      margin-top: 40px;
    }

    td{
      text-align: left;
      height: 51px;
      width: 100%;
      padding: 16px 8px;
    }

    tr:not(:first-child){
      td{
        &:first-child{
          border-right: none;
          width: 50%;
        }
  
        &:last-child{
          border-left: none;
          width: 50%;
        }
      }
    }

  }
}

.imageblock {
  width: 100vw;

  .owl-nav {
    display: none;
  }

  .owl-item {
    display: inline-block;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 0;
  }

  .owl-dots {
    text-align: center;
    margin-top: 5px;

    .owl-dot {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin: 0 5px;
      padding: 0;
      background-color: #c4c4c4;

      &.active {
        background-color: #ef7d00;
      }

      &:focus {
        outline: 0;
      }
    }
  }
}