.node-full.node-dictionary {
  max-width: 614px;
}

.node-dictionary {
  font-size: 1rem;

  h2 {
    font-size: 1.6rem;
    margin-top: 13px;
  }

  .pane-related-words-pp-related-words {
    h4 {
      font-size: 1.6rem;
      margin-top: 13px;
      line-height: 2.3rem;
    }
  }

  ul {
    margin: 3px 10px;
  }

  .row {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .field-name-field-dictionary-definition {
    font-size: 1.2rem;
    padding: 10px 10px 7px 39px;
    margin: 0 0 20px 0;
    min-height: 36px;

    &:before {
      height: 20px;
      left: 10px;
      top: 8px;
      width: 20px;
    }
  }

  .field-name-field-dictionary-upsale {
    padding: 7px 10px;
    margin-bottom: 20px;
  }

  .field-name-body h2 {
    font-size: 1.4rem;
  }

  .panel-pane {
    margin-bottom: 13px;
  }

  h1 {
    font-size: 2.6rem;
    line-height: 2.9rem;
    padding-bottom: 7px;
  }

  [class*="-related-words"] {
    h3 {
      font-size: 1.4rem;
      padding-bottom: 7px;
      margin-bottom: 7px;
    }

    .field-name-field-dictionary-related-words {
      a {
        font-size: 0.9rem;
      }

      margin-bottom: 7px;
    }
  }

  .col-sm-3 {
    [class*="-related-words"] {
      h3 {
        font-size: 1.2rem;
      }
    }
  }

  .nav-button a,
  .demo-button a {
    font-size: 0.8rem;
    line-height: 0.9rem;
    padding: 7px 10px;
  }
}

.node-type-dictionary {
  &.html {
    .col-sm-3 {
      display: none;
    }

    h2 {
      margin-bottom: 13px;
    }
  }
}