/**
 * OwlCarousel (Synced Owls)
 */
.synced-owls {
  background-color: $white;
  position: relative;
  max-width: 620px;
  margin: 0 auto;

  .slides.owl-carousel {
    margin-bottom: 10px;
    position: relative;
    z-index: 0;

    .owl-item {
      padding: 5px;
      & > div {
       box-shadow: rgba(175, 175, 175, 1) 0px 1px 3px;
        height: 450px;
        text-align: center;
        padding: 5px;

        &:before {
          content: ' ';
          display: inline-block;
          font-size: 0;
          height: 100%;
          vertical-align: middle;
        }
      }

      & img {
        height: auto;
        max-width: 100%;
        vertical-align: middle;
        display: inline;
        width: auto;
      }
    }

    .owl-prev,
    .owl-next {
      background-color: $white;
      border-radius: 0;
      height: 45px;
      margin: -23px 0 0 0;
      outline: 0;
      position: absolute;
      text-indent: -9999px;
      top: 50%;
      transition-duration: 300ms;
      transition-property: all;
      transition-timing-function: ease-in-out;
      width: 45px;
      z-index: 10;
    }

    .owl-prev {
      background: url("../images/top-arrows.png") 0 center no-repeat $white;
      left: 5px;
      box-shadow: rgba(175, 175, 175, 1) 1px 0px 2px;
    }

    .owl-next {
      background: url("../images/top-arrows.png") -45px center no-repeat $white;
      right: 5px;
      box-shadow: rgba(175, 175, 175, 1) -1px 0px 2px;
    }
  }

  .thumbnails {
    .owl-item {
      padding: 0 6px 0 4px;
      text-align: center;

      & > div {
        border: 1px solid #ccc;
        height: 90px;
        max-width: 124px;
        padding: 3px;

        &:before {
          content: ' ';
          display: inline-block;
          font-size: 0;
          height: 100%;
          vertical-align: middle;
        }

        &:hover {
          border: 4px solid #0085cc;
          padding: 0;
        }
        img {
          max-height: 82px;
        }
      }

      &.synced {
        & > div {
          border: 4px solid #0085cc;
          padding: 0;
        }
      }

      & img {
        display: inline;
        height: auto;
        max-width: 100%;
        cursor: pointer;
        vertical-align: middle;
        width: auto;
      }
    }

    .owl-nav {
      .owl-prev,
      .owl-next {
        background-color: $white;
        border-radius: 0;
        height: 45px;
        margin-bottom: 0;
        margin-top: -23px;
        outline: 0;
        position: absolute;
        text-indent: -9999px;
        top: 50%;
        transition-duration: 300ms;
        transition-property: all;
        transition-timing-function: ease-in-out;
        width: 45px;
        z-index: 0;
      }

      .owl-prev {
        background: url("../images/bottom-arrows.png") 0 center no-repeat;
        left: -45px;
      }

      .owl-next {
        background: url("../images/bottom-arrows.png") -45px center no-repeat;
        right: -45px;
      }
    }
  }

  .owl-nav{
    opacity: 0.5;
  }
}

.owl-carousel-slider-block {
  background-color: $white;
  position: relative;
  margin: 0 auto;
  height: 667px;
  max-width: 813px;
  border-radius: 7px;
  background-color: #FFFFFF;
  box-shadow: 4px 4px 11px 0 rgba(131,131,131,0.5);
  padding: 26px;
  z-index: 1;
  
  .item {
    position: relative;
    margin: 0 auto;

    img {
      box-sizing: border-box;
      height: 405px;
      border: 1px solid #000000;
    }

    h3 {
      height: 37px;
      color: #000000;
      font-family: "Open Sans";
      font-size: 20px;
      font-weight: bold;
      line-height: 37px;
      text-align: center;
      margin:30px 0 10px 0;
    }

    .description {
      height: 66px;
      color: #222222;
      font-family: "Open Sans";
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      font-weight: 300;
    }

    .link {
      height: 23px;
      color: #EF7D00;
      font-family: "Open Sans";
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      text-align: center;
      margin-top: 40px;
    }
  }

  .owl-nav {
    opacity: 0.5;

    .owl-prev,
    .owl-next {
      background-color: $white !important;
      border-radius: 0;
      height: 45px;
      margin: -23px 0 0 0;
      outline: 0;
      position: absolute;
      text-indent: -9999px;
      top: 330px;
      transition-duration: 300ms;
      transition-property: all;
      transition-timing-function: ease-in-out;
      width: 45px;
    }

    .owl-prev {
      background: url("../images/top-arrows.png") 0 center no-repeat $white !important;
      left: -50px;
      box-shadow: rgba(175, 175, 175, 1) 1px 0px 2px;
    }

    .owl-next {
      background: url("../images/top-arrows.png") -45px center no-repeat $white !important;
      right: -50px;
      box-shadow: rgba(175, 175, 175, 1) -1px 0px 2px;
    }
  }

  .owl-dots {
    margin-top: 60px !important;

    .owl-dot span {
      width: 20px;
      height: 20px;
      background-color: $white !important; 
      border: 1px solid $orange !important;
    }

    .owl-dot.active span {
      background-color: $orange !important; 
    }
  }

}