// @file
// Custom sass mixins
//
// Define the custom mixins for your project here.
// http://sass-lang.com/docs/yardoc/file.SASS_REFERENCE.html#defining_a_mixin

// Makes an element visually hidden, but accessible.
// @see http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
@mixin element-invisible {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  @if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
    clip: rect(1px 1px 1px 1px); // IE6 and IE7 use the wrong syntax.
  }
  clip: rect(1px, 1px, 1px, 1px);
}

// Turns off the element-invisible effect.
@mixin element-invisible-off {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}

// Makes an element visually hidden by default, but visible when focused.
@mixin element-focusable {
  @include element-invisible;

  &:active,
  &:focus {
    @include element-invisible-off;
  }
}

@mixin linkcolor($link, $hover:$link, $decorationhover:underline, $visited: $link, $decoration: none){
  a{color:$link; text-decoration:$decoration; }
  a:link   { color:$link;}
  a:visited{ color:$visited;}
  a:hover  { color:$hover; text-decoration:$decorationhover;}
}

@mixin a-linkcolor($link, $hover:$link, $decorationhover:underline, $visited: $link, $decoration: none){
  &{text-decoration:$decoration; }
  @if $link == $hover {
    color:$link;
    &:link, &:visited { color: $link;}
  } @else {
    color:$link;
    &:hover  { color:$hover;}
    &:link   { color:$link;}
    &:visited{ color:$visited;}
  }
  &:hover  { color:$hover; text-decoration:$decorationhover;}
}

// Buttons
@mixin button($background-color, $bg-opacity, $border, $border-color, $font-size, $padding, $width, $hover-bg-color, $hover-txt-color: null) {
  @if $bg-opacity == null {
    background-color: $background-color;
  } @else {
    background-color: rgba($background-color, $bg-opacity);
  }
  @if $border != null {
    border: $border solid $border-color;
  } @else {
    border:0;
  }
  font-size: $font-size;
  padding: $padding;
  @if $width != null {
    min-width: $width;
  }
  &:hover {
    background-color: $hover-bg-color;
    @if $hover-txt-color != null {
      color: $hover-txt-color;
    }
  }
}

@mixin svg-bg($name, $extension: png, $repeat: no-repeat, $width: 130px, $height: 40px) {
  background-image: url(../images/#{$name}.svg);
  background-size: $width;
  background-repeat: $repeat;
  width: $width;
  height: $height;
}

@mixin social-btn($name, $type: solid) {
  text-indent: -9999px;
  display: inline-block;
  overflow: hidden;
  display: block;
  height: 32px;
  width: 32px;
  margin-right: 10px;
  @if $type == solid {
    background-image: url('../images/icon-#{$name}-solid.png');
  } @else {
    background-image: url('../images/icon-#{$name}.png');
  }
}

// Media Queries
@mixin respond-to($media) {
  @if $media == large-screen {
    @media only screen and (min-width: $large-width) { @content; }
  }
  @else if $media == standard-screen {
    @media only screen and (min-width: $standard-width) and (max-width: $large-width - 1) { @content; }
  }
  @else if $media == medium-screen {
    @media only screen and (min-width: $medium-width) and (max-width: $standard-width - 1) { @content; }
  }
  @else if $media == small-screen {
    @media only screen and (min-width: $small-width) and (max-width: $medium-width - 1) { @content; }
  }
  @else if $media == extra-small-screen {
    @media only screen and (max-width: $small-width) { @content; }
  }
}

// Hide placeholders
@mixin placeholder-hide {
  &::-webkit-input-placeholder {
    /* WebKit browsers */
    color: transparent;
  }

  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: transparent;
  }

  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: transparent;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: transparent;
  }
}

// Placeholder color
@mixin placeholder-color($color) {
  &::-webkit-input-placeholder {
    /* WebKit browsers */
    color: $color;
  }

  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $color;
  }

  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $color;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: $color;
  }
}