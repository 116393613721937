/*
* Whenever Colorbox loads content in a iframe, the body in that iframe will have .cbox class.
*/
body.cbox {
  font-size: 0.7rem;

  #page {
    font-size: 1.2rem;
  }
}

#cboxLoadedContent {
  margin-bottom: 4px;
  margin-top: 16px;
}

#cboxContent {
  #cboxClose {
    top: -4px;
  }
}

.responsive-mobile {
  #cboxLoadedContent {
    p {
      font-size: 1.3rem;
    }
  }
}