.page-site-trial {
  #content {
    margin: 0 auto;
    width: 100%;
  }
}

.client-form--demo-1 {
  .client-form__fieldset {
    padding: 0;

    .form-submit {
      // Shrink the distance to the form
      margin-top: 20px;
      margin-left: auto;
    }

    .form-item--checkbox {
      width: unset;
    }
  }

  .client-form__longitem {
    display: block;
  }
}

.client-form--demo-4 {
   .form-item--center {
     width: unset;
   }
 }
 