.synced-owls {
  max-width: 100%;
  background-color: $gray-lightest;

  .slides.owl-carousel {
    margin-bottom: 7px;

    .owl-item {
      padding: 3px;

      &>div {
        height: 293px;
        padding: 3px;
      }

      &.synced {
        &>div {
          border-width: 3px;
        }
      }
    }

    .owl-prev,
    .owl-next {
      height: 29px;
      margin: -15px 0 0 0;
      width: 29px;
    }

    .owl-prev {
      left: 3px;
      background-position-x: -7px;
    }

    .owl-next {
      right: 3px;
      background-position-x: -52px;
    }
  }
}

.owl-carousel-slider-block {
  h3 {
    font-size: 16px;
    line-height: 20px;
    margin: 5px 0;
  }
  .description {
    font-size: 12px;
    line-height: 16px;
  }
}