/*
* Whenever Colorbox loads content in a iframe, the body in that iframe will have .cbox class.
*/
body.cbox{
  font-size: 1rem;
  overflow: hidden;
  padding-top: 0 !important;

  #page{
    font-size: 1.8rem;
  }
  .fci {
    .fcic {
      width: 100%;
      margin-left: 0;
      margin-right: 0;

      .fsc {
        padding: 0 !important;
        vertical-align: top !important;
      }
    }
  }

  .order-flow-content {
    header {
      display: none !important;
    }
  }

}

#cboxLoadedContent {
  /*
  * Overflow properties need to be '!important' to override JS-defaults
  * set inline in the Colorbox JavaScript
  */
  overflow-x: hidden !important;
  overflow-y: auto !important;
  margin-bottom: 6px;
  margin-top: 24px;
  display: block;
  clear: both;

  .fciw {
    height: auto !important;
  }

  .field-collection-item {
    width: auto;
    .fcic {
      width: 100%;
    }
  }

  .fsc {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: auto !important;
    display: block !important;
    vertical-align: top !important;
  }
}

#cboxContent {
  #cboxClose {
    display: block;
    clear: both;
    top: -6px;
    background: url('../images/colorbox_close.png');
    background-position: 0 0;
    background-repeat: no-repeat;
    &:hover {
      background-position: 0 0;
      opacity: 0.5;
    }
    &:focus {
      outline:0 !important;
    }
  }
}

.responsive-mobile {
  #cboxLoadedContent {
    p {
      font-size: 2rem;
    }
  }
}
