$app-ext-row-content-margins: 46px;


.app-ext__content {
  display: block;
  margin-top: 0;
  max-width: unset;
  width: 100%;
}

.app-ext__content-gallery-position-one{
  display: block;
  height: 260px;
  background-color: $gray-app;
}

.pane-app-ext-page-gallery{
  display: none;
}

.app-ext__content-left {
  margin-top: 26px;
  margin-left: 20px;
  margin-right: 20px;
  width: unset;
  min-width: unset;
}

.app-ext__content-right {
  margin-left: 20px;
  margin-right: 20px;
  width: unset;
  min-width: unset;
}

.app-ext__content-gallery {
  margin-top: 0;
  height: unset;

  .owl-carousel {
    .owl-item {
      &>div {
        height: 260px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 55px;
        padding-right: 55px;
      }
    }
  }
}

.app-ext__content-tile__content{
  display: block;
}

.pane-app-ext-page-header{
  display: none;
}

.app-ext__content-icon {
  width: 110px;
  min-width: 110px;
  height: 110px;
}

.app-ext__content-tile {
  display: flex;

  .app-ext__content-tile__content {
    overflow: hidden;
  }

  .app-ext__content-body-info__tags-list {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-size: 1.4rem;

    a {
      font-size: 1.4rem;
    }
  }
}

.app-ext__content-comments-tile__content {
  overflow: hidden;

  div.app-ext__content-comments-tags {
    width: 100%;
    font-size: 1.4rem;

    a {
      font-size: 1.4rem;
    }
  }

  .app-ext__content-comments-name-line {
    line-height: 24px;
    display: block;
  }

  .app-ext__content-comments-name {
    font-size: 1.8rem;
  }
}

.app-ext__content-new,
.app-ext__content-premium,
.app-ext__content-comments-new,
.app-ext__content-comments-premium {
  >div {
    font-size: 1.2rem;
    line-height: 18px;
  }
}

.app-ext__content-company,
.app-ext__content-description,
.app-ext__reviews-line {
  line-height: 20px;
}

.app-ext__content-company,
.app-ext__content-description,
.app-ext__reviews-line {
  font-size: 1.4rem;
}

.app-ext__content-owner-link {
  margin-top: 18px;

  a {
    margin-bottom: 0;
  }
}

.app-ext__contnet-add-comment {
  a {
    margin-bottom: 5px;
  }
}


.app-ext__content-trial-link {
  a {
    margin-bottom: 0;
  }
}

.app-ext__content-prices {
  margin-top: 34px;
  line-height: 26px;
}

.app-ext__content-prices--empty {
  margin-top: 8px;
}

.app-ext__content-demands {
  padding-top: 0;
  margin-top: $app-ext-row-content-margins;
  line-height: 26px;
  width: 100%;
}

.app-ext__content-block__body {
  line-height: 26px;

  .field-name-field-appext-ability {
    li {
      margin-top: 0;
    }
  }
}

.app-ext__content-body-content {
  margin: $app-ext-row-content-margins 0 0 0;

  iframe {
    width: 100%;
  }

  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
  }

  .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.app-ext__content-comments {
  margin-top: $app-ext-row-content-margins;
}

.app-ext__content-body-info__more,
.app-ext__content-body-abstract,
.app-ext__content-body-info__company,
.app-ext__content-comments__info {
  display: block;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;

  >div {
    width: unset;
    margin-top: $app-ext-row-content-margins;

    &:first-child {
      margin-top: $app-ext-row-content-margins;
      padding-right: 0;
    }
  }
}

.app-ext__content-body-info__logo,
.app-ext__content-comments__info-logo {
  display: none;
}

.app-ext__content-comments__list {
  margin: 0;
}

.app-ext__content-comments {
  .field-name-comment-body {
    line-height: 26px;
  }

  & #comments .comment-row {
    margin-top: 24px;

    &.first {
      margin-top: $app-ext-row-content-margins;
    }
  }

  .date {
    font-size: 1.2rem;
  }
}

.app-ext__content-comments__actions {
  margin-top: $app-ext-row-content-margins;
  display: block;

  a {
    display: block;
    margin: 0;
  }
}

.app-ext__content-body-info__company {
  margin-top: $app-ext-row-content-margins;
}

.app-ext__content-body-info__more .field-name-field-appext-more-links .field-item {
  margin-bottom: 12px;
}

.app-ext__content-body-info__tags,
.app-ext__content-body-availability {
  margin-top: $app-ext-row-content-margins;
  margin-right: 0;
  margin-left: 0;
}

.app-ext__content-name-line {
  line-height: 24px;
}

.app-ext__content-name {
  display: inline;
  font-size: 1.8rem;
}

.client-form__app-ext-comment {
  margin: 7px;

  .client-form__fieldset {
    padding: 42px 13px 32px 13px;
  }
}

.app-ext__content-comments-tile {
  margin-left: 7px;
  margin-right: 7px;
  margin-bottom: 10px;
}

.app-ext__content-comments__results {
  width: unset;
  margin: 7px;
}

.app-ext__content-comments__results__img {
  margin-left: 53px;
  margin-right: 53px;
}

.app-ext__content-comments__results__header {
  margin-top: 40px;
}

.app-ext__content-comments__close {
  top: 20px;
}

.field-name-field-appext-rating {
  .fivestar-widget-static {
    display: flex;
  }
}


.app-ext__view {
  .app-ext__teaser {
    width: 100%;
  }

  .app-ext__teaser-content {
    overflow: auto;
  }

  .app-ext__teaser-tags {
    width: 100%;
  }

  .app-ext__teaser-body {
    line-height: 20px;

    div {
      line-height: 20px;
    }
  }

  .app-ext__teaser-new,
  .app-ext__teaser-premium {
    div {
      font-size: 1.2rem;
      line-height: 18px;
    }
  }

  .app-ext__teaser-name {
    font-size: 1.8rem;
    line-height: 24px;
  }

  .app-ext__content-comments-description {
    font-size: 1.4rem;
    max-width: 100%;
  }

  .app-ext__teaser-company {
    font-size: 1.4rem;
  }

  .app-ext__reviews-line {
    font-weight: 700;

    span,
    div {
      font-size: 1.2rem;
    }
  }

  .app-ext__reviews-line--not-enough__text,
  .app-ext__reviews-line--empty__text {
    font-weight: normal;
  }

  .app-ext__teaser-link {
    line-height: 22px;
  }

  .view-content {

    .col-md-4,
    .col-sm-6 {
      padding-left: 18px;
    }
  }

}

.app-ext__hero-holder {
  width: 100%;
  max-width: unset;
  padding: 10px;
}

.app-ext__hero {
  height: 100%;
  background-size: 350px;
  background-position: top;
  line-height: 2rem;
}

div.app-ext__hero-content {
  width: 100%;
  padding-top: 220px;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  margin: 0;
  font-size: 1.6rem;
  line-height: 2rem;

  p {
    padding-left: 0;
    padding-right: 0;
  }

  li {
    list-style-type: none;
  }

  ul {
    margin: 13px 0;
  }

  h1{
    font-size: 3.2rem;
    line-height: 3.2rem;
  }
}


.app-ext__filters {
  overflow: visible;
}

.client-form--app-ext-filters .client-form__fieldset {
  visibility: hidden;
}

.client-form--app-ext-filters {
  margin-bottom: 0;
  overflow: visible;

  .client-form__fieldset {
    margin: 0;
    padding-left: 15px;
    padding-right: 15px;
    overflow: visible;

    .form-item {
      width: 100%;

      .app-ext__filters__search {
        padding-left: 35px;
        background-position: 10px 9px;
        background-size: 17px 17px;
      }
    }

    .app-ext__filters__sort-select {
      text-align: left;
      padding-right: 0;
      border: 1px solid $gray-light;
    }

    .app-ext__filters-mobile-pane__holder {
      margin-top: 50px;

      .form-item {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .form-type-checkbox {
        margin-top: 13px;
        margin-bottom: 13px;

        label {
          width: 100%;
        }
      }
    }
  }
}

.app-ext__filters__search-close {
  right: 10px;
  top: 10px;
  width: 17px;
  height: 17px;
}

div.app-ext__filters-mobile-pane {
  z-index: 2000;
  background-color: white;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
  overflow-x: hidden;


  a.app-ext__filters-mobile-pane__apply {
    padding: 13px 26px;
    position: fixed;
    width: calc(100% - 50px);
    margin-left: 25px;
    line-height: 2.2rem;
    z-index: 400;
    bottom: 30px;
  }

  a.app-ext__filters-mobile-pane__apply--disabled {
    background-color: $gray-dark-ligth;
    border-color: $gray-dark-ligth;
    cursor: default;
  }
}

.app-ext__filters__category-box-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.app-ext__filters__category-box {
  display: block;
  width: calc(100% - 50px);
  margin-left: 25px;
  margin-bottom: 105px;
  margin-top: 12px;
  column-count: 1;
  padding-top: 18px;
  padding-bottom: 17px;

  div {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
}

.app-ext__filters__category-box--industry {
  column-count: 1;
}

.app-ext__filters__category-box--requirement__holder {
  display: block;
}

.app-ext__filters__category-box--requirement__special {
  margin-right: 0;
}

.client-form--app-ext-filters {

  .client-form__fieldset {

    .app-ext__filters__category-box--requirement__special > *:last-child{
      margin-bottom: 0;
    }

    .app-ext__filters__category-box--requirement__special-descr ul{
      margin-bottom: 0;
    }

  }
}



.app-ext__filters__category-box--requirement__general {
  margin-top: 0;
  column-count: 1;
}

.app-ext__filters__category-box-wrapper--sort {
  right: 0;
}

.app-ext__filters-mobile-pane__holder {
  padding: 15px 25px 25px 25px;
}

.app-ext__filters-mobile-filters {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 50px;
  font-weight: 600;
  white-space: nowrap;

  div {
    font-size: 1.6rem;
    color: black;
  }
}

.app-ext__filters-mobile-filters__icon {
  margin-top: 5px;
  margin-left: 10px;
  width: 18px;
  height: 18px;
  background: url(../images/icon-filters.svg) no-repeat transparent;
}

.app-ext__filters__hanger {
  right: 5px;
  margin-top: -48px;

  div {
    font-size: 1.4rem;
    line-height: 1.9rem;
  }

  &:before {
    right: 10px;
  }
}

.app-ext__filters-mobile-pane__discard {
  position: fixed;
  z-index: 300;
  background: white;
  width: 100%;

  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 15px;
  box-shadow: 0 0 10px 0 $gray-lights;
  line-height: 2.2rem;

  div {
    font-size: 1.6rem;
    color: black;
    font-weight: 600;
    line-height: 2.2rem;
  }
}

.app-ext__filters-mobile-pane__discard__icon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  background-image: url(../images/select-down.svg);
  background-size: 14px 14px;
  transform: rotate(90deg);
}

.app-ext__filters-bar {
  display: block;
  margin-top: 50px;
  margin-bottom: 75px;

  .app-ext__filters-bar__label,
  .app-ext__filters-bar__clear,
  .app-ext__filters-bar__more {
    font-size: 1.6rem;
    line-height: 1.6rem;
  }

  .app-ext__filters-bar__clear,
  .app-ext__filters-bar__more {
    margin-top: 12px;
  }
}

.app-ext__filters-bar__tags {
  flex-wrap: wrap;

  .app-ext__filters-bar__tag {
    margin-top: 12px;
    line-height: 2.2rem;
    font-size: 1.4rem;
  }
}

.app-ext__filters__sort-list-element {
  margin-top: 12px;
}


.app-ext__apps-empty {
  background-size: 350px;
  padding-top: 250px;
  margin-top: 50px;
  margin-left: 10px;
  margin-right: 10px;
}

.show-overlay .main-content {
  position: fixed;
  left: 0;
  right: 0;
  overflow-y: scroll;
}

.app-ext__apps-info {
  font-size: 1.6rem;
  padding-left: 15px;
  margin-top: 35px;
  margin-bottom: 35px;
}

.owl-item .field-video,
.app-ext__content-body-video{
  .youtube-player{
    width: 100%;
    height: 100%;
    padding-bottom: 56.23%;

    &.maxres{
      .youtube-thumb {
        height: 100%;
      }
    }
  }
}

.owl-item .field-video{
  .youtube-player{
    .youtube-player__cookie-consent{
      margin-top: -75px;
      line-height: 16px;
      span, a{
        font-size: 1.2rem;
      }
    }
  }
}

.owl-item .field-video .youtube-player{
  height: auto;
  max-height: 100%;
  box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.2);
}

.app-ext__icon-tag--tooltip:hover .app-ext__icon-tag__text {
  width: 332px;
}

.app-ext__icon-tag--visma:hover .app-ext__icon-tag__text{
  left: -6.5px;
}

.app-ext__icon-tag--dealer:hover .app-ext__icon-tag__text{
  left: -39px;
}

.app-ext__icon-tag--partner:hover .app-ext__icon-tag__text{
  left: -68px;
}

.app-ext__icon-tag--market:hover .app-ext__icon-tag__text{
  left: -97.5px;
}

.app-ext__content-icon-box{
  margin-right: 10px;
}

.app-ext__icon-tags--content{
  margin-top: 12px;
  height: 22px;
  gap: 10px
}
