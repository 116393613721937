$client-form-filed-height: 35px;
$client-form-font-size: 1.4rem;

.client-form {
  font-size: $client-form-font-size;

  span {
    font-size: $client-form-font-size;
  }

  div {
    font-size: $client-form-font-size;
  }

  .client-form__fieldset {
    margin-top: 15px;
    width: 100%;
    padding: 20px 11px 20px 11px;

    .form-type-radio label,
    select,
    .form-item--select,
    input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
    textarea {
      width: 100%;
    }

    select,
    .form-item--select,
    input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]) {
      height: $client-form-filed-height;
    }

    input[type="checkbox"] {
      margin-right: 8px;
    }

    .form-item--select,
    select {
      background-position: calc(100% - 10px) center;
    }

    .tooltip-question-icon {
      top: -1px;

      &::before {
        top: 2px;
        left: 6px;
      }
    }

    .tooltip {
      &:hover {
        &::before {
          content: "";

          bottom: 3px;
          right: -14px;
        }

        &::after {
          bottom: -110px;
          right: -177px;
          padding: 10px;
        }
      }
    }

    .form-submit {
      padding-top: 0;
      padding-bottom: 0;
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .client-form__calendar {
      .client-form__calendar__text {
        width: 300px;
        height: $client-form-filed-height;
        padding-top: 7px;
      }
    }

    .form-item-wrapper--description-enclosed {
      .form-item__description {
        font-size: 1.2rem;

        span,
        a {
          font-size: 1.2rem;
        }
      }
    }
  }

  .form-item--select {
    line-height: $client-form-filed-height;
  }
}

.ui-datepicker-form {

  .ui-datepicker-next,
  .ui-datepicker-prev {
    top: 0;
  }
}

.ui-datepicker-form-small {
  width: 278px;

  table {
    width: 231px;
  }

  td {
    height: 33px;
    width: 31px;
  }
}