/*
Webinar for landingpages with course signup.
*/

// Logo on landingpage
#navigation > .region-navigation > .block-lpd_logo {
  @extend %header-menu-shared-prop;

  ul {
    margin: 12px 0 0;

    li {
      padding-top: 2px;

      a {
        @extend %header-menu-logo-shared-prop;
      }
    }
  }
}

.node-course {

  & > .content > div {
    display: inline-block;
    width: 50%;
    padding-right: 20px;
  }

  .field-name-field-course-agenda {

    & > h3 {
      color: $blue;
      cursor: pointer;

      &:before {
        content: '+ ';
      }

      &.open:before {
        content: '– ';
      }
    }
    .course_agenda_summary {
      display: none;
    }
  }
}

.course-booking-form {
  display: inline-block;
  width: 50%;
  float: right;

  .form-item-date {
    padding: 0;
    margin: 0;
  }
  input[type=text], select {
    padding: 0 10px 0;
    width: 100%;
    margin: 0 0 10px;
    height: 40px;
  }
  select {
    -webkit-appearance: menulist-button;

    &.error {
      margin-bottom: 30px;
    }
  }

  .error {
    background-color: transparent;
    margin: -5px 0 20px;
    width: 100%;
  }

  input[type="checkbox"].error {
    width: 5%;

    & + label {
      color: #c00;
    }
  }

  input[type=submit] {
    margin-top: 20px;
    width: 100%;
    @extend .btn-orange-shadow-l;
  }
}
