.job-list {
  .job {
    padding: 13px 0;
    display: block;

    .job-category,
    .job-location {
      display: block;
      margin-top: 10px;
      font-size: 1rem;
    }
    .job-location {
      text-align: left;
      margin-left: 0;
    }
  }
}

.job-share-top {
  max-width: 624px;

  .share-buttons {
    margin-bottom: 0;
  }
}

article.node-job ul {
  margin-left: 13px;
}