.box {
  .bottom-link {
    .external {
      &::after {
        background-image: url(../images/external-link.svg);
        content: '';
        display: inline-block;
        height: 10px;
        margin-left: 10px;
        width: 10px;
      }
    }
  }
}

.hover {
  .box {
    .external {
      &::after {
        background-image: url(../images/external-link-blue.svg);
      }
    }
  }
}

i {
  &.icon {
    display: block;
    height: 50px;
    width: 50px;
    background-position: center center;
    background-repeat: no-repeat;
    margin: 0 auto;
  }
  
  $icon-colors: black, orange, white;
  /* Icon filename: bar-chart-black.svg */
  $icons:
  'administrative-tools',
  'administrator',
  'adventures',
  'article-help',
  'automatic-scanning',
  'bar-chart',
  'bill',
  'boxes',
  'calendar',
  'calendar-recurring',
  'cardboard-box',
  'change-user',
  'chat',
  'chat-support',
  'checked-user',
  'checklist',
  'checkmark',
  'christmas-star',
  'class',
  'classroom',
  'clock',
  'clone',
  'clouds',
  'combo-chart',
  'copy',
  'department',
  'deployment',
  'directions',
  'down4',
  'edit-file',
  'electronic-invoice',
  'enter',
  'external-link',
  'engros',
  'fairytale',
  'find-user',
  'fire-element',
  'gift',
  'globus',
  'groups-jobs',
  'hammer',
  'hammer-2',  
  'help',
  'idea',
  'info',
  'inventory',
  'invoice',
  'iphone-copyrighted',
  'key',
  'leave',
  'left-click',
  'literature',
  'lock',
  'medal',
  'meeting',
  'message',
  'mind-map',
  'minus',
  'money-128',
  'money-bag',
  'money-box',
  'online-course',
  'open-in-browser',
  'org-unit',
  'outline',
  'overtime',
  'paint-brush',
  'parliament',
  'phone2',
  'phone-support',
  'pie-chart',
  'plus',
  'poll-topic',
  'positive-dynamic',
  'presentation',
  'prepayment-fixed-assets',
  'purchase-order',
  'production',
  'question-shield',
  'ratings',
  'register-editor',
  'restaurant',
  'right',
  'right2',
  'rocket',
  'rocket3', 
  'romance',
  'running-rabbit',
  'scanning',
  'scissors',
  'search',
  'security-checked',
  'settings3',
  'settings-bars',
  'settings-wheel-pen',
  'smartpay-arbejdsgange',
  'smartpay-kom-godt-i-gang',
  'smartpay-leverandoerfaktura',
  'smartpay-logo',
  'shop',
  'shopping-basket',
  'slr-back-side',
  'smartbank',
  'smart-inbox-logo',
  'smart-inbox-wand',
  'sproud',
  'stopwatch',
  'straksindebtaling',
  'student',
  'system-information',
  'system-report',
  'talk',
  'thumb-up',
  'to-do',
  'today',
  'topic',
  'totrinsbekraeftelse',
  'trolley',
  'trophy',
  'truck',
  'tv-show',
  'unlock',
  'upload-to-cloud',
  'user-male3',
  'virtual-machine2',
  'webshop',
  'webshop2',
  'webshopplus',
  'wifi',
  'wallet-checkmark',
  'workers',
  'workflow',
  'workflow-2',
  'workflow-3';

  @each $icon-color in $icon-colors {
    @each $icon in $icons {
      &.#{$icon}-#{$icon-color} {
        background-image: url(../images/#{$icon}-#{$icon-color}.svg);
      }
    }
  }

  $icon-colors: gray;
  /** Icon filename: bar-chart-black.svg */
  $icons: 'administrator',
  'adventures',
  'bill',
  'group',
  'groups-jobs',
  'link',
  'lock',
  'money',
  'money-bag',
  'parliament',
  'student',
  'thumb-up';

  $icon-colors: dark;
  /** Icon filename: bar-chart-black.svg */
  $icons: 'workflows',
  'invoice-send',
  'phone-checkmark',
  'inbox',
  'coins-money',
  'smart-pay-wallet';

  @each $icon-color in $icon-colors {
    @each $icon in $icons {
      &.#{$icon}-#{$icon-color} {
        background-image: url(../images/#{$icon}-#{$icon-color}.svg);
      }
    }
  }
}

.orange-fill {
  background-color: #f29100;
  border-radius: 50%;
  content: '';
  height: 100px;
  margin: 0 auto;
  padding-top: 10px;
  width: 100px;
}

// span with +/- expand/collapse circle
.expand-collapse-icon {
  background: url('../images/show-more-price-page.svg') 2px 2px no-repeat;
  background-size: 14px 14px;
  display: block;
  float: right;
  height: 20px;
  margin-right: 20px;
  right: 2px;
  text-align: center;
  top: 12px;
  width: 20px;

  &.expanded {
    background: url('../images/show-more-price-page.svg') 2px 2px no-repeat;
    background-size: 14px 14px;
    transform: rotate(180deg);
    right: 4px;
  }
}
