[class^="btn-"] {
  border-radius: 33px;
  font-size: 1.6rem;
}

a[class^="btn"] {
  margin-bottom: 13px;
  border-radius: 33px;
  font-size: 1.6rem;
  width: 100%;
}

.form-submit {
  border-radius: 33px;
}

%btn-xl-base {
  font-size: 1.2rem !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

// Large is default
%btn-blue-shadow-base {
  @include button($blue, null, 1px, $blue-darker, 8px, 7px 26px, null, $blue-light);
}

.btn-blue-shadow-s {
  font-size: 8px;
  padding: 7px 13px;
}

%btn-blue-base {
  @include button($blue, null, null, null, 8px, 7px 26px, null, $blue-light);
}

%btn-blue-dark-base {
  @include button($blue-dark-newid, null, null, null, 8px, 7px 26px, null, $blue-dark-newid);
}

%btn-green-base {
  @include button($green, null, null, null, 8px, 7px 26px, null, $green-light);
}

%btn-green-shadow-base {
  @include button($green, null, 1px, darken($green, 10%), 8px, 7px 26px, null, $green-light);
}

%btn-gray-lightest-base {
  @include button($gray-lightest, null, 1px, lighten($gray-lightest, 10%), 8px, 7px 26px, null, darken($gray-lightest, 5%));
}

%btn-gray-lightest-shadow-base {
  @include button($gray-lightest, null, 1px, lighten($gray-lightest, 10%), 8px, 7px 26px, null, darken($gray-lightest, 5%));
}

%btn-white-light-base {
  @include button($white, null, 1px, $gray-lightest, 8px, 7px 26px, null, $white, $hover-txt-color: $blue-darker);
}

.btn-white-orange-no-border-s {
  font-size: 0.9rem;
}

%btn-white-light-pseudo-base {
  height: 8px;
  top: 1px;
  width: 8px;
}

.btn-white-light-arrow-left-s {
  &::before {
    height: 10px;
    left: -3px;
    top: 2px;
  }
}

.btn-white-light-arrow-right-s {

  &::after {
    right: -3px;
  }
}

.btn-white-light-external-link-right-s {

  &::after {
    right: -3px;
  }
}

.btn-white-light-arrow-right-no-border-blue-txt-s {
  &::after {
    right: -3px;
  }
}

.btn-white-light-arrow-right-no-border-red-txt-s {
  &::after {
    right: -3px;
  }
}

.btn-white-light-shadow-s {
  font-size: 8px;
  padding: 3px 10px;
}

%btn-opaque-base {
  @include button($black, 0.2, null, null, 8px, 7px 20px, null, $white);
}

.btn-opaque-play-icon-l {
  &::before {
    height: 13px;
    margin-left: -7px;
    margin-right: 7px;
    top: 3px;
    width: 13px;
  }
}

%btn-orange-shadow-base {
  @include button($orange, null, 1px, darken($orange, 1%), 2rem, 7px 0px, null, lighten($orange, 10%));
}

.btn-orange-shadow-s {
  font-size: 8px;
  padding: 3px 13px;
}

%btn-orange-base {
  @include button($orange, null, null, null, 8px, 7px 26px, null, lighten($orange, 10%));
}

.btn-orange-s {
  padding: 4px 23px;
}

#back-top {
  margin: 10px;
}

#back-top .arrow-up {
  background-size: 143px 143px;
  height: 33px;
  width: 33px;
}

.btn-play-video-section {
  padding-left: 16px;
  font-size: 1rem;
}

@keyframes bounce {
  40% {
    transform: translateY(-13px);
  }

  60% {
    transform: translateY(-7px);
  }
}

ul.share-buttons {
  margin-top: -26px;
  padding: 13px 0;

  li {
    margin-left: 10px !important;

    &:first-child {
      margin-top: 3px;
    }
  }
}

.share-buttons-top .share-buttons {
  display: none;
}

.btn-ve {
  padding: 13px 26px;
  font-size: 16px !important;
}
