#cludo-search-results {

  .search-result-count,
  .search-did-you-mean.full {
    padding-bottom: 5px;
  }

  .cludo-page-navigation-top {
    .cludo-page-navigation {
      width: 260px;
    }
  }

  .search-results {
    .search-no-results {
      padding-top: 10px;

      color: $gray-darkest;

      .info {
        margin-top: 33px;
      }

      .headline {
        margin-top: 16px;
      }

      ul {
        margin: 20px 0;

        li {
          padding-left: 21px;
          background-size: 16px 16px;
          margin-bottom: 5px;
        }
      }

      .text {
        margin: 5px 0;
      }
    }
  }

  .loading {
    margin-left: 88px
  }
}

.cludo-banner-smartinbox {
  height: 98px;
  margin: 20px 0;

  .left-column {
    width: 111px;
  }

  .right-column {
    .line1 {
      font-size: 14px;
      line-height: 1.7;
    }

    .line2 {
      font-size: 39px;
    }

    .line3 {
      font-size: 13px;
      line-height: 3.4;
    }
  }
}

.page-accountants-search {
  #cludo-search-results {
    .cludo-search-modal-body {
      .search-result-count {
        padding-right: 13px;
      }
    }
  }

  #cludo-search-form-revisorlist {
    .search_autocomplete {
      font-size: 1.3rem;
      width: 272px;
      line-height: 1.8rem;

      li {
        padding: 7px 3px;
      }
    }
  }
}


.section-soegeresultater {
  header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
  }

  #cludo-search-results {
    .cludo-page-navigation-top {
      position: fixed;
      left: 0;
      z-index: 90;
      background: white;
      padding: 13px 23px 13px 23px;
      width: 100%;

      &.stick_bottom {
        position: absolute;
        bottom: 0;
      }
    }

    .search-results>ul {
      padding-top: 78px;
    }
  }

}

#content .search-form {
  padding: 13px;
  width: 100%;
}

.h-medium .fsc.search-results-section {
  padding-top: 20px;
}

.region-navigation {

  .navigation-search .form-item,
  .app-hero .form-item,
  .app-hero-overview .form-item,
  .ecopedia-search .form-item,
  .wiki-search .form-item {
    margin-top: 2.6em;
    margin-bottom: 0;
    padding: 1em;
  }
}

#cludo-search-results {
  .cludo-r {
    flex-direction: column;

    .cludo-c-3 {
      display: none
    }

    .cludo-c-9 {
      flex: auto;
      max-width: 100%;
      margin-top: 0;
      font-size: 1.3em;

      a,
      div {
        font-size: 1.3rem;
      }
    }

    .search-results {
      margin-top: 103px;
      padding: 0 13px;

      .search-results-item h2 {
        margin-bottom: 6px;

        a {
          color: $blue-dodger;
          font-family: "Open Sans Bold", "Open Sans", sans-serif !important;
          font-weight: bold;
        }

        b {
          font-family: "Open Sans Bold", "Open Sans", sans-serif !important;
        }
      }

      .cludo-page-navigation {
        width: 50%;
        margin-top: initial;

        li {
          width: auto;
        }
      }

      .search-result-count {
        width: 50%;
        font-size: 1.3rem;
        padding: 0;
      }

      .search-results-item {
        margin-top: 40px;
        margin-bottom: 40px;
        padding-bottom: 40px;
        border-bottom: 1px $gray-light solid;

        p {
          b:before {
            content: "\A";
          }
        }
      }
    }
  }

  .search_page_list {
    border-top: 0;

    .active{
      color: $gray-darkest;
    }
  }

}

.headline {
  padding: 0 13px;
}

.cludo-banner-smartinbox {
  .left-column {
    width: 98px;
  }
}

/** Accountant search **/
.page-accountants-search {

  .pane-content .search-form .form-actions {
    top: 10px;
  }

  .pane-content .search-form .form-text {
    font-size: 1.3rem;
    height: 36px;
    padding-left: 7px;
    width: 272px;
  }

  .pane-content .search-form .form-submit {
    background-size: 13px 13px;
    height: 36px;
    width: 53px;
  }
}