/**
 * @file
 * SMACSS Modules
 *
 * Adds modular sets of styles.
 *
 * Additional useful selectors can be found in Zen's online documentation.
 * https://drupal.org/node/1707736
 */
/**
 * Accessibility features.
 */

/* element-invisible as defined by http://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
.element-invisible,
%element-invisible {
  @include element-invisible;
}

/* Turns off the element-invisible effect. */
%element-invisible-off {
  @include element-invisible-off;
}

.element-focusable,
%element-focusable {
  @extend %element-invisible;

  &:active,
  &:focus {
    @extend %element-invisible-off;
  }
}

/*
 * The skip-link link will be completely hidden until a user tabs to the link.
 */
#skip-link {
  margin: 0;

  a,
  a:visited {
    display: block;
    width: 100%;
    padding: 2px 0 3px 0;
    text-align: center;
    background-color: #666;
    color: #fff;
  }
}

/**
 * Branding header.
 */

/* Wrapping link for logo. */
.header__logo {
  float: left; /* LTR */
  margin: 0;
  padding: 0;
}

/* Logo image. */
.header__logo-image {
  vertical-align: bottom;
}

/* Wrapper for website name and slogan. */
.header__name-and-slogan {
  float: left;
}

/* The name of the website. */
.header__site-name {
  margin: 0;
  // @include adjust-font-size-to($h1-font-size);
}

/* The link around the name of the website. */
.header__site-link {
  &:link,
  &:visited {
    color: #000;
    text-decoration: none;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

/* The slogan (or tagline) of a website. */
.header__site-slogan {
  margin: 0;
}

/* The secondary menu (login, etc.) */
.header__secondary-menu {
  float: right; /* LTR */
}

/* Wrapper for any blocks placed in the header region. */
.header__region {
  /* Clear the logo. */
  clear: both;
}

/**
 * Breadcrumb navigation.
 */
.breadcrumb {
  font-size: 1.2rem;
  margin: 0 auto;
  padding: 15px;
  width: $wrap-page-width; // 960px (_init.scss)
  @include linkcolor($gray-darker, $gray-darkest, none);

  .crumbs-separator {
    display: inline-block;
    color: $gray-darker;
    padding: 0 10px;
  }
}

.mobile .breadcrumb {
  float: left;
  font-size: 2rem !important;
  padding: 0 20px;
  width: 100%;

  .crumbs-separator {
    display: inline-block;
    color: $gray-darker;
    padding: 0 5px;
  }

  a, span {
    display: inline-block;
    font-size: 2rem !important;
  }
}

/**
 * Titles.
 */
.page__title, /* The title of the page. */
.node__title, /* Title of a piece of content when it is given in a list of content. */
.block__title, /* Block title. */
.comments__title, /* Comment section heading. */
.comments__form-title, /* Comment form heading. */
.comment__title {
  margin: 0;
}

/**
 * Messages.
 */
.messages {
  margin: 1.5em 0;
  padding: 10px 10px 10px 50px; /* LTR */
  // background-image: inline-image("message-24-ok.png");
  background-image: url('../images/status.png');
  background-position: 8px 8px; /* LTR */
  background-repeat: no-repeat;
  border: 1px solid #be7;
}
.messages--status {
  @extend .messages;
  @extend %ok;
}
.messages--warning {
  @extend .messages;
  @extend %warning;
  // background-image: inline-image("message-24-warning.png");
  background-image: url('../images/warning.png');
  border-color: #ed5;
}
.messages--error {
  @extend .messages;
  @extend %error;
  // background-image: inline-image("message-24-error.png");
  background-image: url('../images/error.png');
  border-color: #ed541d;
}
.messages__list {
  margin: 0;

  li {
    list-style-type: none;
  }
}
.messages__item {
  list-style-image: none;
}

/* Core/module installation error messages. */
.messages--error p.error {
  color: #333;
}

/* System status report. */
.ok,
%ok {
  background-color: #f8fff0;
  color: #234600;
}
.warning,
%warning {
  background-color: #fffce5;
  color: #840;
}

%error {
  background-color: $baige-lightest;
  color: #8c2e0b;
  width: 940px;
  margin: 20px auto;
}

/**
 * Tabs.
 */

/* Basic positioning styles shared by primary and secondary tabs. */
%tabs {
  @include clearfix;
  background-image: linear-gradient(to top,#bbbbbb 1px,rgba(0,0,0,0) 1px);  /* IE 9 and earlier don't understand gradients. */
  list-style: none;
  border-bottom: 1px solid $tabs-border \0/ ie;
  margin: 1.5em 0;
  padding: 0 2px;
  white-space: nowrap;
}
%tabs__tab {
  float: left; /* LTR */
  margin: 0 3px;
}
%tabs__tab-link {
  border: 1px solid #e9e9e9;
  border-right: 0;
  border-bottom: 0;
  display: block;
  // @include adjust-leading-to(1);
  text-decoration: none;
}

/* Primary tabs. */
.tabs-primary {
  @extend %tabs;
}
.tabs-primary__tab {
  @extend %tabs__tab;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  // @include single-text-shadow(#fff, 1px, 1px, 0);
  border: 1px solid $tabs-border;
  border-bottom-color: transparent;
  /* IE 9 and earlier don't understand gradients. */
  border-bottom: 0 \0/ ie;
}
.tabs-primary__tab.is-active {
  @extend .tabs-primary__tab;
  border-bottom-color: $tabs-container-bg;
}

// We use 3 placeholder styles to prevent @extend from going selector crazy.
%tabs-primary__tab-link {
  @extend %tabs__tab-link;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  @include transition(background-color 0.3s);
  color: #333;
  background-color: #dedede;
  letter-spacing: 1px;
  padding: 0 1em;
  text-align: center;
}
%tabs-primary__tab-link-is-hover {
  background-color: #e9e9e9;
  border-color: #f2f2f2;
}
%tabs-primary__tab-link-is-active {
  background-color: transparent;
  // @include filter-gradient(rgba(#e9e9e9, 1), rgba(#e9e9e9, 0));
  // @include background-image(linear-gradient(rgba(#e9e9e9, 1), rgba(#e9e9e9, 0)));
  border-color: #fff;
}

a.tabs-primary__tab-link {
  @extend %tabs-primary__tab-link;

  &:hover,
  &:focus {
    @extend %tabs-primary__tab-link-is-hover;
  }
  &:active {
    @extend %tabs-primary__tab-link-is-active;
  }
}
a.tabs-primary__tab-link.is-active {
  @extend %tabs-primary__tab-link;
  @extend %tabs-primary__tab-link-is-active;
}

/* Secondary tabs. */
.tabs-secondary {
  @extend %tabs;
  font-size: .9em;
  /* Collapse bottom margin of ul.primary. */
  margin-top: -(1.5em);
}
.tabs-secondary__tab,
.tabs-secondary__tab.is-active {
  @extend %tabs__tab;
  margin: 1.5em/2 3px;
}

// We use 3 placeholder styles to prevent @extend from going selector crazy.
%tabs-secondary__tab-link {
  @extend %tabs__tab-link;
  // @include border-radius(.75em);
  // @include transition(background-color 0.3s);
  // @include single-text-shadow(#fff, 1px, 1px, 0);
  background-color: #f2f2f2;
  color: #666;
  padding: 0 .5em;
}
%tabs-secondary__tab-link-is-focus {
  background-color: #dedede;
  border-color: #999;
  color: #333;
}
%tabs-secondary__tab-link-is-active {
  // @include single-text-shadow(#333, 1px, 1px, 0);
  background-color: #666;
  border-color: #000;
  color: #fff;
}

a.tabs-secondary__tab-link {
  @extend %tabs-secondary__tab-link;

  &:hover,
  &:focus {
    @extend %tabs-secondary__tab-link-is-focus;
  }
  &:active {
    @extend %tabs-secondary__tab-link-is-active;
  }
}
a.tabs-secondary__tab-link.is-active {
  @extend %tabs-secondary__tab-link;
  @extend %tabs-secondary__tab-link-is-active;
}

/**
 * Inline styles.
 */

/* List of links generated by theme_links(). */
.inline {
  display: inline;
  padding: 0;

  li {
    display: inline;
    list-style-type: none;
    padding: 0 1em 0 0; /* LTR */
  }
}

/* The inline field label used by the Fences module. */
span.field-label {
  padding: 0 1em 0 0; /* LTR */
}

/**
 * "More" links.
 */
.more-link {
  text-align: right; /* LTR */
}
.more-help-link {
  text-align: right; /* LTR */
}
.more-help-link a {
  // background-image: inline-image("help.png");
  // background-position: 0 50%; /* LTR */
  // background-repeat: no-repeat;
  padding: 1px 0 1px 20px; /* LTR */
}

/**
 * Pager.
 */

/* A list of page numbers when more than 1 page of content is available. */
.pager {
  clear: both;
  padding: 0;
  text-align: center;
}
%pager__item {
  display: inline;
  padding: 0 0.5em;
  list-style-type: none;
  background-image: none;
}

.pager-item, /* A list item containing a page number in the list of pages. */
.pager-first, /* The first page's list item. */
.pager-previous, /* The previous page's list item. */
.pager-next, /* The next page's list item. */
.pager-last, /* The last page's list item. */
.pager-ellipsis {
  /* A concatenation of several list items using an ellipsis. */
  @extend %pager__item;
}

/* The current page's list item. */
.pager-current {
  @extend %pager__item;
  font-weight: 700;
}

/**
 * Blocks.
 */

/* Block wrapper. */
.block {
  margin-bottom: 1.5em;
}

/**
 * Marker.
 */

/* The "new" or "updated" marker. */
.new,
.update {
  color: #c00;
  /* Remove background highlighting from <mark> in normalize. */
  background-color: transparent;
}

/**
 * Unpublished note.
 */

/* The word "Unpublished" displayed underneath the content. */
.unpublished {
  height: 0;
  overflow: visible;
  /* Remove background highlighting from <mark> in normalize. */
  background-color: transparent;
  color: #d8d8d8;
  font-size: 75px;
  line-height: 1;
  font-family: Impact, "Arial Narrow", Helvetica, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  /* A very nice CSS3 property. */
  word-wrap: break-word;
}
@if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
  .lt-ie8 .node-unpublished > *,
  .lt-ie8 .comment-unpublished > * {
    /* Otherwise these elements will appear below the "Unpublished" text. */
    position: relative;
  }
}

/**
 * Comments.
 */

/* Wrapper for the list of comments and its title. */
.comments {
  margin: 1.5em 0;
}

/* Preview of the comment before submitting new or updated comment. */
.comment-preview {
  /* Drupal core will use a #ffffea background. See #1110842. */
  background-color: #ffffea;
}

/* Wrapper for a single comment. */
.comment {

  /* Comment's permalink wrapper. */
  .permalink {
    text-transform: uppercase;
    font-size: 75%;
  }
}

/* Nested comments are indented. */
.indented {
  /* Drupal core uses a 25px left margin. */
  margin-left: $indent-amount; /* LTR */
}

/**
 * Forms.
 */

/* Wrapper for a form element (or group of form elements) and its label. */
#user-login,
#user-pass {
  width: 500px;
  margin: 0 auto;
  padding-bottom: 30px;

  .form-submit {
    @extend .btn-blue-shadow-l;
  }
}

.form-item {
  margin: 1.5em 0;

  /* Pack groups of checkboxes and radio buttons closer together. */
  .form-checkboxes &,
  .form-radios & {
    /* Drupal core uses "0.4em 0". */
    margin: 0;
  }

  /* Form items in a table. */
  tr.odd &,
  tr.even & {
    margin: 0;
  }

  /* Highlight the form elements that caused a form submission error. */
  input.error,
  textarea.error,
  select.error {
    border: 1px solid #c00;
  }

  /* The descriptive help text (separate from the label). */
  .description {
    font-size: 0.85em;
  }
}

.form-type-radio,
.form-type-checkbox {
  .description {
    margin-left: 2.4em;
  }
}

/* The part of the label that indicates a required field. */
.form-required {
  color: #c00;
}

/* Labels for radios and checkboxes. */
label.option {
  display: inline;
  font-weight: normal;
}

/* Buttons used by contrib modules like Media. */
a.button {
  // @include appearance(button);
}

/* Password confirmation. */
.password-parent,
.confirm-parent {
  margin: 0;
}

/* Drupal's default login form block. */
#user-login-form {
  text-align: left; /* LTR */
}

// Disable iphone submit button rendering.
input[type="submit"] {
  -webkit-appearance: none;
}

/**
 * OpenID
 *
 * The default styling for the OpenID login link seems to assume Garland's
 * styling of list items.
 */

/* OpenID creates a new ul above the login form's links. */
.openid-links {
  /* Position OpenID's ul next to the rest of the links. */
  margin-bottom: 0;
}

/* The "Log in using OpenID" and "Cancel OpenID login" links. */
.openid-link,
.user-link {
  margin-top: 1.5em;
}
html.js #user-login-form li.openid-link,
#user-login-form li.openid-link {
  /* Un-do some of the padding on the ul list. */
  margin-left: -20px; /* LTR */
}
#user-login ul {
  margin: 1.5em 0;
}

/**
 * Drupal admin tables.
 */
form {
  th {
    text-align: left; /* LTR */
    padding-right: 1em; /* LTR */
    border-bottom: 3px solid #ccc;
  }
  tbody {
    border-top: 1px solid #ccc;
  }
  table ul {
    margin: 0;
  }
}
tr.even,
tr.odd {
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  padding: 0.1em 0.6em;
}
tr.even {
  background-color: #fff;
}
@if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
  .lt-ie8 tr.even,
  .lt-ie8 tr.odd {
    th,
    td {
      /* IE doesn't display borders on table rows. */
      border-bottom: 1px solid #ccc;
    }
  }
}

/* Markup generated by theme_tablesort_indicator(). */
td.active {
  background-color: #ddd;
}

/* Center checkboxes inside table cell. */
td.checkbox,
th.checkbox {
  text-align: center;
}

/* Drupal core wrongly puts this in system.menus.css. Since we override that, add it back. */
td.menu-disabled {
  background: #ccc;
}

/**
 * Autocomplete.
 *
 * @see autocomplete.js
 */

/* Suggestion list. */
#autocomplete .selected {
  background: #0072b9;
  color: #fff;
}

/**
 * Collapsible fieldsets.
 *
 * @see collapse.js
 */
.fieldset-legend {
  html.js .collapsible & {
    background-image: inline-image("menu-expanded.png");
    @if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
      *background-image: image-url("menu-expanded.png");
    }
    background-position: 5px 65%; /* LTR */
    background-repeat: no-repeat;
    padding-left: 15px; /* LTR */
  }
  html.js .collapsed & {
    background-image: inline-image("menu-collapsed.png"); /* LTR */
    @if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
      *background-image: image-url("menu-collapsed.png"); /* LTR */
    }
    background-position: 5px 50%; /* LTR */
  }
  .summary {
    color: #999;
    font-size: 0.9em;
    margin-left: 0.5em;
  }
}

/**
 * TableDrag behavior.
 *
 * @see tabledrag.js
 */
tr.drag {
  background-color: #fffff0;
}
tr.drag-previous {
  background-color: #ffd;
}
.tabledrag-toggle-weight {
  font-size: 0.9em;
}

/**
 * TableSelect behavior.
 *
 * @see tableselect.js
 */
tr.selected td {
  background: #ffc;
}

/**
 * Progress bar.
 *
 * @see progress.js
 */
.progress {
  font-weight: 700;

  .bar {
    background: #ccc;
    border-color: #666;
    margin: 0 0.2em;
    border-radius: 3px;
  }
  .filled {
    background-color: #0072b9;
    // Genereates random ressource URI - background-image: url('../images/progress.gif?1396860078');
    // background-image: image-url("progress.gif");
    background-image: url("../images/progress.gif");
  }
}

.tooltip {
  display: inline;
  position: relative;
  font-weight: normal;
  cursor: help;
  z-index: 10;

  &:hover::after {
    background: rgba(0, 0, 0, .9);
    border-radius: 2px;
    bottom: 26px;
    color: #fff;
    content: attr(data-tooltip);
    left: -105px;
    padding: 5px 15px;
    position: absolute;
    z-index: 98;
    width: 220px;
  }

  &:hover::before {
    border: solid;
    border-color: #333 transparent;
    border-width: 6px 6px 0 6px;
    bottom: 20px;
    content: "";
    left: 8px;
    position: absolute;
    z-index: 99;
  }
}

.tooltip-question-icon {
  border-radius: 50%;
  border: 1px solid $gray-more-dark;
  color: $gray-more-dark;
  display: block;
  float: right;
  font-size: 1rem;
  height: 20px;
  line-height: 18px;
  margin-right: 20px;
  margin-top: 8px;
  text-align: center;
  padding-right: 3px;
  width: 20px;
}

// Popup wrapper for a.popup links (more info in /js/script.js).
.popupwrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 990;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.75);

  iframe {
    position: absolute;
    width: 50%;
    height: 50%;
    border: 0;
    left: 25%;
    top: 25%;
    min-width: 560px;
    min-height: 315px;
  }

  &.chat {
    iframe {
      min-width: 20%;
      width: 20%;
      left: 40%;
    }

    &:before {
      right: 60%;
    }
  }

    &:before {
      content: '×';
      position: absolute;
      background: black;
      width: 25px;
      height: 25px;
      top: 25%;
      right: 75%;
      margin: -15px -10px 0 0;
      color: white;
      border: 2px solid white;
      z-index: 999;
      font-weight: 700;
      font-size: 25px !important;
      line-height: 25px;
      text-align: center;
      cursor: pointer;
      border-radius: 25px;
    }
}

/* Template for ckeditor. */
.icon-block:hover a {
  color: $blue-light !important;
  text-decoration: none;
}
.icon-block a {
  color: black !important;
}

// Chat tab
.region-chat-tab {
  right: 20px;
  bottom: -10px;

  display: none;
  position: fixed;
  z-index: 100;

  .chat-bubble-website {
    width: 90px;
    height: 90px;
    transition: transform .2s;
  
    &:hover {
      transform: scale(1.1);
    }
  }
}

.transition-all-linear {
  transition: all 500ms linear;
}

// Hide google iframe
[name="google_conversion_frame"] {
    display: none;
}

// Media embed oembed
.media_embed {
  margin: 0 20px;

  iframe {
    width: 100%;
  }
}

//overlay
.overlay {
    position: fixed;
    width: 100%;
    height:100%;
    display: flex;
    align-items: center;
    background: $black-90;
    top: 0;
    left: 0;
    z-index: 1000;
}

.overlay_body {
    display: flex;
    margin: 0 auto;
}

/*
 * Normal spinner / heavily inspired from whirl.css - http://jh3y.github.io/-cs-spinner
**/
.css-spinner {
  opacity: 0.5;
  position: relative;
}
.css-spinner:before {
  background-color: #f39200;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.css-spinner:after {
  animation-name: standard;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform-origin: center;
  border-right: 2px solid #ffbf5e;
  border-top: 2px solid #ffad33;
  border-left: 2px solid #f39200;
  border-bottom: 2px solid #bf7300;
  border-radius: 100%;
  content: "";
  height: 20px;
  left: 488px;
  margin: 5px 0 0 -5px;
  position: absolute;
  width: 20px;
  z-index: 2;
}

@keyframes standard {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*
 * UI fix for dropdown arrow in firefox
 */
html.js div.ctools-dropdown a.ctools-dropdown-text-link {
  // From dropdown.css
  // background: url(../images/collapsible-expanded.png) 3px 5px no-repeat;
  background: url(../images/collapsible-expanded.png) 55px 5px no-repeat;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

.fade-in-from-left {
  opacity: 0;
	animation: fade-in-from-left 0.8s cubic-bezier(0.0, 0.2, 0.5, 1.0) 0s both;
	// animation: fade-in-from-left 0.8s linear both;
}

@keyframes fade-in-from-left {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.hide-desktop{
  display: none;
}