// Layout
// page definitions
.fci {
  width: 100%;
}

.fcic {
  width: 100%;
  margin: 0;
}

.l2 {
  .fciw {
    width: 75%;
  }
}

.l3 {
  .fciw {
    margin-right: 50%;
  }
}

.l5 {
  .fcic {
    background-image: none !important;
  }

  .fciw {
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 0;
    margin: 0 auto;
    padding-bottom: 90%;
    width: 100%;
  }

  .fsc {
    height: 0;
    padding-top: 0;
    width: 100%;
  }
}

.l5-r {
  .fcic {
    background-image: none !important;
  }

  .fciw {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100% auto !important;
    height: 0;
    margin: 0 auto;
    padding-top: 90%;
    width: 100%;
  }

  .fsc {
    height: 0;
    padding-top: 0;
    width: 100%;
  }
}

// youtube video player. Hide div.
.field-collection-container {
  div.player {
    display: none;
  }
}

.mobile-alt {
  height: 293px;

  &>.visma-econ-cut {
    display: none;
  }

  +.fsc {
    padding: 26px 13px;
  }
}

.section-5 iframe {
  width: 100%;
}