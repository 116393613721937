.order-block-screen {
  margin: 0 auto;
  color: black;

  .invisible {
    color: transparent;
  }
}

.order-block-step {
  margin: 60px auto 0;
  width: 1200px;
  display: flex;
  justify-content: space-between;
}

.order-block-package {
  z-index: 1;
  padding-top: 46px;

  &.active {
    padding-top: 0;
    font-weight: 300;
  }
}

.order-block-package__content--ribbon {
  padding-top: 0;
  padding-top: 23px;
}

.order-block-package__content {
  width: 282px;
  margin: 0;
  padding: 17px 14px 12px;
  border-radius: 12px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.order-block-package__ribbon {
  width: 116px;
  height: 26px;
  text-align: center;

  margin: 0 21px 20px 13px;
  padding: 3px 13px;
  border-radius: 4px;
  background-color: #CDF0E7;

  color: #14493A;
  font-family: 'Helvetica';
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: 0.55px;
  text-transform: uppercase;
}

.order-block-package__content-header {
  margin-left: 12px;

  h2 {
    font-size: 22px;
    font-weight: 600;
    color: #2f2f2f;
    white-space: nowrap;
  }
}

.order-block-package__description {
  margin: 21px 18px 23px 0;
  font-size: 14px;
  line-height: 1.4;
  color: #000;
  height: 80px;
}

.order-block-rp__package__offer,
.order-block-package__offer {
  background-color: #FAE0C0;
  height: 22px;
  border-radius: 4px;

  font-family: 'Helvetica';
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #4A2912;

}

.order-block-rp__package__offer {
  padding: 0 10px;
}

.order-block-package__content-footer {
  margin-top: 36px;
  margin-bottom: 0;
  margin-left: 12px;
  margin-right: 0;
  text-align: left;
}

.order-block-package__price-line {
  margin-top: 11px;
  height: 54px;
  display: flex;
}

.order-block-package__price {
  margin: 0 12px 0 0;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #2f2f2f;
  display: flex;
  align-items: center;
}

.order-block-package__price-suffix {
  font-size: 14px;
  line-height: 1.29;
  color: #000;
  display: flex;
  align-items: center;
}

.order-block-package__postings {
  height: 97px;
  flex-grow: 0;
  margin: 25px 0 28px;
  padding: 8px 20px 10px 19px;
  border-radius: 5px;
  border: solid 1px #c7c7c7;
  cursor: pointer;
}

.order-block-package__postings-number {
  position: relative;
  margin: 0 18px 3px 11px;
  font-size: 14px;
  line-height: 1.4;

  &:before {
    content: " ";
    position: absolute;
    width: 11px;
    height: 8px;
    left: -22px;
    top: 7px;
    background: transparent url('../images/priceblock/checkmark-orange.svg') 0 0 no-repeat;
    background-size: 11px 8px;
  }
}

.order-block-package__postings-subline {
  margin: 3px 0px 20px 11px;
  opacity: 0.5;
  font-size: 12px;
  line-height: 1.4;
}

.order-block-package__postings-price {
  position: relative;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;

  &:after {
    content: " ";
    position: absolute;
    width: 9px;
    height: 13px;
    right: -15px;
    top: 2px;
    background: transparent url('../images/priceblock/rightarrow.svg') 0 0 no-repeat;
    background-size: 9px 13px;
  }
}

.order-block-package__modules {
  margin-top: 28px;
  margin-bottom: 19px;
  margin-left: 12px;
  min-height: 270px;
}

.order-block-package__modules-header {
  margin: 0 16px 0 0;
  font-size: 14px;
  line-height: 1.4;
}

.order-block-module a.order-block-module__action,
.order-block-package__modules a.order-block-package__modules-action {
  font-size: 14px;
  line-height: 1.4;
  text-decoration-line: underline;
  text-decoration-style: dashed;
  text-underline-offset: 3px;
  color: #000;
}

.order-block-module {
  position: relative;
  margin-top: 16px;
  font-size: 14px;
  margin-left: 23px;

  div {
    font-size: 14px;
  }

  &:before {
    content: " ";
    position: absolute;
    width: 11px;
    height: 8px;
    left: -22px;
    top: 7px;
    background: transparent url('../images/priceblock/checkmark-orange.svg') 0 0 no-repeat;
    background-size: 11px 8px;
  }
}

.order-block-module--section {
  margin-left: 0;

  &:before {
    display: none;
  }
}

.order-block-module--disabled {
  color: #CACACA;

  a.order-block-module__action {
    color: #CACACA;
    text-decoration: unset;
    cursor: unset;
  }

  &:before {
    content: " ";
    position: absolute;
    width: 10px;
    height: 10px;
    left: -22px;
    top: 6px;
    background: transparent url('../images/priceblock/x.svg') 0 0 no-repeat;
    background-size: 10px 10px;
  }
}

.order-block-module--branch {
  &:before {
    left: -20px;
    background-size: 7px 6px;
  }
}

.order-block-package__button {
  min-height: 40px;
  text-align: left;

  .marked {
    background-color: #fff !important;
    color: #4573D2 !important;
    border-radius: 4px;
  }

  a {
    padding: 11px 0;
    width: 100%;
    min-width: 224px;
    background-color: #4573D2;
    border: 1px solid #4573D2;
    margin-top: 25px;
    margin-bottom: 7px;
    letter-spacing: 0.8px;
    border-radius: 4px;
  }
}

body article .order-block-package__button--simple {
  position: relative;
  margin-top: 40px;
  margin-left: 12px;

  a {
    position: relative;
    padding: 0;
    border: none;
    line-height: 1.14;
    font-size: 14px;
    font-weight: bold;
    padding-right: 15px;
    color: #000;

    &:hover {
      text-decoration-line: underline;
      text-underline-offset: 3px;
    }

    &:after {
      content: " ";
      position: absolute;
      width: 9px;
      height: 13px;
      right: 0;
      top: 4px;
      background: transparent url('../images/priceblock/rightarrow.svg') 0 0 no-repeat;
      background-size: 9px 13px;
    }
  }
}


body div.order-block-package__button-more {
  a {
    color: #000;
  }
}

.order-block-package__button-more {
  width: 224px;
  margin-top: 47px;
  margin-bottom: 19px;
  text-align: left;

  a {
    position: relative;
    padding: 0;
    border: none;
    line-height: 1.14;
    font-size: 14px;
    font-weight: bold;
    padding-right: 15px;

    &:hover {
      text-decoration-line: underline;
      text-underline-offset: 3px;
    }

    &:after {
      content: " ";
      position: absolute;
      width: 9px;
      height: 13px;
      right: 0;
      top: 4px;
      background: transparent url('../images/priceblock/rightarrow.svg') 0 0 no-repeat;
      background-size: 9px 13px;
    }
  }
}

.order-block--richtext-wrapper {
  >p {
    padding: 0;
  }
}

// MODAL START
.order-block-dialog__modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  align-items: center;
  align-content: center;
  //transition: opacity .3s ease;
}

.order-block-dialog__modal-wrapper {
  margin: 0 auto;
}

.order-block-dialog__modal-container {
  position: relative;
  width: 640px;
  height: 625px;
  margin: 0px auto;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  text-align: left;
  border-radius: 9px;
}

.order-block-dialog__modal-body {
  overflow: auto;
  width: 100%;
  height: 100%;
  border-radius: 9px;

  .order-block-dialog__modal-body--frame {
    margin: 20px 0;
    padding: 0px 30px;

    ul {
      list-style-position: outside;
      margin-left: 15px;
    }

    .function-action {
      position: relative;
      display: inline-block;
      font-size: 16px;
      line-height: 14px;
      font-weight: 600;
      color: #000;

      &:after {
        content: " ";
        position: absolute;
        width: 9px;
        height: 13px;
        right: -15px;
        top: 1px;
        background: transparent url('../images/priceblock/rightarrow.svg') 0 0 no-repeat;
        background-size: 9px 13px;
      }
    }
  }
}


.order-block-dialog__modal-close-button {
  background-position: center;
  background-repeat: no-repeat;
  background: url("/sites/all/themes/eco2014/images/icon-close-blue.svg");
  background-size: 20px 20px;
  border: 0;
  cursor: pointer;
  display: block;
  height: 20px;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 15px;
  text-indent: -9999px;
  top: 20px;
  width: 20px;
  z-index: 100;
}

// MODAL transition
.order-block-dialog__modal-enter-active,
.order-block-dialog__modal-leave-active {
  transition: opacity .25s ease;
  position: absolute;
  z-index: 9998;
}

.order-block-dialog__modal-enter,
.order-block-dialog__modal-leave-to {
  opacity: 0;
  position: absolute;
  z-index: 9998;
}

.order-block-dialog__modal-enter .modal-container,
.order-block-dialog__modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

// MODAL END
.order-block-text-step2-step3 {
  margin-left: 140px;
}

.order-block-step--two {
  margin-top: 50px;
  width: 1010px;
  flex-direction: column;
}

.order-block__price-page-link {
  margin-top: 88px;
  margin-left: 13px;
  margin-right: 13px;
}

.order-block-fd,
.order-block-rp {
  margin-bottom: 24px;
}

.order-block-cp__opened,
.order-block-fd__opened,
.order-block-rp__opened {
  width: 1010px;
  margin: 0 auto;
  padding: 37px;
  border-radius: 12px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  @include transition(all 0.3s ease-in-out);
}

.order-block-rp__opened {
  height: 433px;
}

.order-block-cp__closed,
.order-block-fd__closed,
.order-block-rp__closed {
  width: 1010px;
  height: 106px;
  padding: 37px;
  border-radius: 12px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.1);
  background-color: #f6f6f6;
  @include transition(all 0.3s ease-in-out);
}

.order-block-box-active {
  cursor: pointer;
}

.order-block-box__header-img {
  width: 25px;
  height: 27px;
  margin: 3px 19px 0 auto;
  background: transparent url('../images/priceblock/pen.svg') 0 0 no-repeat;
  background-size: 25px 27px;
}

.order-block-back {
  margin-bottom: 40px;
}

.order-block-back a {
  display: block;
  width: 32px;
  height: 16px;
  background: transparent url('../images/priceblock/arrow-left-orange.svg') 0 0 no-repeat;
  background-size: 32px 16px;
}

.order-block-box__header {
  display: flex;

  h2 {
    margin-left: 16px;
    font-size: 26px;
    font-weight: 600;
    line-height: 1.4;
    color: #2f2f2f;
  }
}

.order-block-box__header-info {
  font-size: 16px;
  line-height: 1.6;
  margin-top: auto;
  margin-bottom: 4px;
  margin-left: 16px;
  color: #898989;
}

.order-block-package__content-notice {
  font-size: 14px;
  line-height: 1.4;
  margin: 20px 10px 0 10px;
  font-weight: 400;
}

.order-block-rp__package {
  display: flex;
  flex-wrap: wrap;
  width: 936px;
  margin-top: 44px;
  margin-bottom: 32px;
  padding: 25px 40px 25px 28px;
  border-radius: 12px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.1);
  background-color: #fff;

  .order-block-package__content-notice {
    margin: 10px 10px 0 38px;
  }
}

.order-block-rp__package-name {
  margin-left: 38px;

  h3 {
    position: relative;
    font-size: 22px;
    font-weight: 600;
    color: #2f2f2f;

    &:before {
      content: " ";
      position: absolute;
      width: 16px;
      height: 15px;
      left: -37px;
      top: 7px;
      background: transparent url('../images/priceblock/checkmark-circle-orange.svg') 0 0 no-repeat;
      background-size: 16px 15px;
    }
  }
}

.order-block-rp__package-price {
  display: flex;
  flex-direction: column;
  margin-left: 275px;
}

.order-block-rp__package-price-unit {
  display: flex;
  align-items: center;
}

.order-block-rp__package-name-info {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
  color: #000;
}

.order-block-rp__package-price-number {
  margin-right: 12px;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #2f2f2f;
}

.order-block-rp__package-suffix {
  font-size: 14px;
  line-height: 1.29;
  color: #000;
}

.order-block-rp__package-switch {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.client-form--price-block a:not([class^="btn-"]):link,
.order-block a.order-block__link {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-decoration: underline;
  line-height: 1.29;
  letter-spacing: normal;
  color: #0043ef;
}

.order-block-cp__body,
.order-block-fd__body,
.order-block-rp__body {
  display: flex;
}

.order-block-cp__body {
  margin-left: 16px;
  margin-top: 32px;
  flex-direction: column;
}

.order-block-cp__info-header,
.order-block-rp__info-header {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.4;
  color: #29283E;
}

.order-block-cp__info ul.order-block-cp__info-elements,
.order-block-rp__info ul.order-block-rp__info-elements {
  margin-left: 25px;
  margin-bottom: 6px;
}

.order-block-cp__info-elements,
.order-block-rp__info-elements {
  font-size: 16px;
  line-height: 1.6;
  color: #29283E;
  list-style-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 100 100'><circle cx='50%' cy='50%' r='20' /></svg>");
}

.order-block-cp__body-actions,
.order-block-fd__body-actions,
.order-block-rp__body-actions {
  margin-top: auto;
  margin-left: auto;

  a {
    padding: 15px 30px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.8px;
    background-color: #4573D2;
    color: #FFFFFF;
    border-radius: 4px;
  }
}

.order-block-cp__body-actions {
  margin: 40px auto 0;
}

a.order-block-fd__back:visited,
a.order-block-fd__back:link,
.order-block-fd__back {
  margin-right: 18px;
  background-color: unset;
  color: #4573D2;
}

.client-form--price-block {
  .client-form__fieldset {
    width: 593px;
    box-shadow: unset;
    margin: 0;

    legend {
      margin-bottom: 18px;
      font-weight: bold;
      line-height: 1.4;
      padding: 0;
      color: #29283E;
    }

    .privacy-text,
    .privacy-text a:link,
    input[type=checkbox]+label span,
    label a:link,
    label {
      font-size: 16px;
      line-height: 1.4;
      color: #2f2f2f;
    }

    .form-item {
      margin-bottom: 16px;
    }
  }

  .client-form__fieldframe {
    overflow: unset;

    select,
    .form-item--select,
    input[type="checkbox"],
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="tel"],
    input[type="image"],
    textarea {
      width: 543px;
    }
  }

  .privacy-text {
    margin-top: 18px;
  }
}

.client-form--price-block__mannual-link {
  margin-top: 27px;
  margin-bottom: 38px;
}

.price-block--modal-list {
  display: flex;
  flex-direction: column;
  margin: 0 40px;

  .price-block--modal-item {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin-top: 10px;

    .posts {
      width: 100%;
      margin-right: 0;
    }

    .price {
      white-space: nowrap;
    }
  }

  .price-block--modal-item__simple {
    font-weight: normal;
    margin-bottom: 10px;
  }
}

.price-block--modal-link,
.price-block--modal-link:visited,
.price-block--modal-link:link {
  color: #2188F4;
  text-decoration: underline;
  text-underline-offset: 3px;
}

// SPINNER START
.spinner--big-white {
  margin: 100px auto;
  font-size: 2.5rem;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

@-webkit-keyframes load5 {

  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #fff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #fff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #fff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #fff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #fff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #fff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #fff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #fff;
  }
}

@keyframes load5 {

  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #fff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }

  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #fff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }

  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #fff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #fff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #fff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #fff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #fff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }

  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #fff;
  }
}

// SPINNER END
