/**
 * General page.
 */

.fcic {
  width: 100%;
}

.h-micro {
  .fsc {
    height: 46px;
  }
}

.h-tiny {
  .fsc {
    height: 65px;
  }
}

.l2 {
  .fsc {
    width: 377px;
  }
}

.l3 {
  .fcic {
    background-size: 312px;
  }

  .fsc {
    width: 312px;
  }
}

.l3-r {
  .fcic {
    background-size: 312px;
  }

  .fsc {
    width: 312px;
  }
}

.l4 {
  &.align-side {
    .fcic {
      background-position: 325px 100%;
      background-size: 293px;
      width: 100%;
    }

    // Align image middle
    &.valign-middle {
      .fcic {
        background-position: 325px 50%;
      }
    }
  }

  &.align-center {
    .fcic {
      background-position: 442px 100%;
      background-size: 260px;
      width: 100%;
    }

    &.valign-middle {
      .fcic {
        background-position: 338px 50%;
      }
    }
  }

  .fsc {
    width: 312px;
  }
}

.l4-r {
  &.align-side {
    .fcic {
      background-position: -20px 100%;
      background-size: 293px;
      width: 100%;
    }
  }

  &.align-center {
    .fcic {
      background-position: 130px 100%;
      background-size: 260px;
      width: 100%;
    }

    &.valign-middle {
      .fcic {
        background-position: 29px 50%;
      }
    }
  }

  .fsc {
    width: 312px;
  }
}

.l5 {
  .fciw {
    width: 377px;
  }
}

.l5-r {
  .fciw {
    width: 377px;
  }
}

// Global
$fsc-padding-horizontal: 10px;
.h-micro,
.h-tiny {
  .fsc {
    padding: 13px $fsc-padding-horizontal;
  }
}

.h-small {
  .fsc {
    height: 130px;
    padding: 26px $fsc-padding-horizontal;
  }
}

.h-medium-3 {
  .fsc {
    height: 195px;
    padding: 26px $fsc-padding-horizontal;
  }
}

.h-medium-4 {
  .fsc {
    height: 260px;
    padding: 26px $fsc-padding-horizontal;
  }
}

.h-medium {
  .fsc {
    height: 325px;
    padding: 39px $fsc-padding-horizontal;
  }
}

.h-large {
  .fsc {
    height: 455px;
    padding: 52px $fsc-padding-horizontal;
  }
}

.h-auto {
  .fsc {
    padding: 0 $fsc-padding-horizontal;
  }
}

.no-padding {
  .fsc {
    padding: 0 $fsc-padding-horizontal;
  }
}

.arr-t-s1 {
  &::before {
    height: 46px;
    left: calc(50% - (38px/2));
    width: 46px;
    top: -23px;
    background-size: 12px 18px;
  }
}

.arr-t-s2 {
  &::before {
    height: 25px;
    left: calc(50% - (38px/2));
    width: 25px;
    top: -20px;
    border: 7px solid white;
  }
}

.arr-b-s1 {
  &::after {
    height: 46px;
    left: calc(50% - (46px/2));
    width: 46px;
    bottom: -23px;
    background-size: 12px 18px;
  }
}

.arr-b-s2 {
  &::after {
    height: 25px;
    left: calc(50% - (38px/2));
    width: 25px;
    bottom: -20px;
    border-width: 7px;
  }
}

.hide-section-link {
  right: 7px;
}

.hide-section {
  margin-right: 13px;
  width: 20px;
}