.job-list {
  list-style: none;

  .job {
    display: grid;
    margin-left: 0;
    border-bottom: 1px solid $gray;
    padding: 20px 0;
    position: relative;

    &:last-child {
      border-bottom: 0;
    }

    .job-category,
    .job-location {
      grid-row-start: 2;
      font-size: 1.6rem;
    }
    .job-location {
        text-align: right;
        margin-left: 10px;
    }
  }
}

.job-share-top {
  margin: 0 auto;
  max-width: $wrap-page-width;

  .share-buttons {
    margin-bottom: 0;
  }
}

article.node-job ul {
  margin-left:20px;
}