.wiki-brief {
  .client-form__fieldset {
    margin: 0;
    padding: 0;
    box-shadow: none;

    .form-item {
      margin-bottom: 0;
    }
  }
}

.wiki-brief-title {
  font-size: 20px;
  border-top: 1px solid $gray-light;
  font-weight: 700;
  margin-bottom: 30px;
  margin-top: 40px;
  padding-top: 40px;
}

.wiki-brief-link {
  a {
    color: #000;
    text-decoration: underline;
    
    &:visited,
    &:link {
      color: #000; 
    }
  }
}

.wiki-brief-link,
.wiki-brief-category,
.wiki-brief-date {
  font-size: 1.4rem;
}

.wiki-brief-paginator {
  margin-top: 50px;

  .wiki-brief-paginator-page {
    margin-right: 25px;
  }
}