.order-block{
  width: 100%;
}

.order-block-step {
  width: 100%;
  display: block;
  margin-top: 40px;
}

.order-block-step.order-block-step--one {
  width: 100vw;

  .owl-nav {
    display: none;
  }

  .owl-item {
    display: inline-block;
    font-weight: normal;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .owl-dots {
    text-align: center;
    margin-top: 5px;

    .owl-dot {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin: 0 5px;
      padding: 0;
      background-color: #c4c4c4;

      &.active {
        background-color: #ef7d00;
      }

      &:focus {
        outline: 0;
      }
    }
  }
}

.order-block-package {
  width: 100%;
  padding-left: 7px;
  padding-right: 7px;
}

.order-block-package__content{
  width: 100%;
  padding: 17px 28px 12px;
}

.order-block-package__content-footer{
  margin-right: 17px;
}

.order-block-package__button{
  width: 100%;
  margin: auto;

  a {
    padding-top: 13px;
    padding-bottom: 13px;
    border-radius: 4px;
  }
}

.order-block-package__button-more{
  a{
    padding-top: 0;
    padding-bottom: 0;
  }
}

.order-block-dialog__modal-container {
  width: 95%;
  height: 560px;

  .price-modal{
    p{
      padding-left: 10px;
      padding-right: 10px;
    }

    .dots{
      background: unset;
    }
  }

}

.order-block-text-step3 {
  width: 150%;
}

.order-block-dialog__modal-wrapper{
  min-width: 100%;
}

.order-block-step--two{
  margin-top: 30px;
}

.order-block-back{
  margin-left: 22px;
  margin-bottom: 26px;
}

.order-block-box__header{
  justify-content: center;

  h2 {
    margin-left: 0;
    font-size: 20px;
  }
}

.order-block-rp__package{
  width: 100%;
  flex-direction: column;
  margin-top: 30px;
  padding: 25px 30px;

  h3{
    &:before{
      right: -29px;
      top: 5px;
      left: unset;
    }
  }

  .order-block-package__content-notice{
    order: 2;
    margin-top: 20px;
  }

}

.order-block-rp__package-name-info{
  margin-top: 8px;
}


.order-block-rp__package-name{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0;
  order: 1;
}

.order-block-rp__package-price{
  justify-content: center;
  margin-top: 16px;
  margin-left: 0;
  order: 2;
}

.order-block-rp__package-price-unit{
  justify-content: center;
}

.order-block-rp__package__offer{
  margin-bottom: 15px;
}

.order-block-rp__package-switch{
  margin: 24px auto 0;
  order: 3;
}

.order-block-fd__body,
.order-block-rp__body{
  flex-direction: column;
}

.order-block-cp__body{
  margin-top: 48px;
}

.order-block-cp__info-header{
  margin-bottom: 14px;
}

.order-block-rp__info-header{
  margin-bottom: 8px;
}

.order-block-rp__info ul.order-block-rp__info-elements{
  margin-bottom: 8px;
}

.order-block-rp__info{
  margin-left: 12px;
}

.order-block-rp__body-actions{
  margin: 24px 0 0 0;

  a{
    margin-bottom: 0;
    border-radius: 4px;
  }
}

.order-block-box__header-info{
  margin-left: 8px;
  font-size: 14px;
}

.order-block-cp__opened,
.order-block-fd__opened,
.order-block-rp__opened{
  width: 100%;
  padding: 24px 20px;
  height: auto;
}

.order-block-cp__closed,
.order-block-fd__closed,
.order-block-rp__closed{
  width: 100%;
  padding: 24px 22px;
  height: 75px;
}

.order-block-box__header-img{
  display: none;
}

.client-form--price-block{
  .client-form__fieldset{
    margin-top: 28px;
  }
}

.client-form--price-block__mannual-link{
  margin-top: 31px;
  margin-bottom: 34px;
}

.price-block--modal-list{
  margin: 0 10px;
}

.order-block-fd__body-actions{
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-top: 12px;
  margin-left: 0;

  a{
    margin-bottom: 0;
    border-radius: 4px;
  }

  .order-block-fd__back{
    margin: 15px 0 0 0;
    padding-bottom: 0;
  }

}

.order-block-cp__body-actions{
  a{
    border-radius: 4px;
  }
}

.order-block-package__description {
  height: 75px;
}

.order-block-package__price-line{
  margin-top: 18px;
}
