// %btn-base,
[class^="btn-"] {
  border-radius: 4px;
  display:inline-block;
  text-align:center;
  text-decoration: none;
}

.form-submit {
  border-radius: 4px;
}

// The button mixin takes following arguments
// @mixin button($bg-color, $bg-opacity, $border, $border-color, $font-size, $padding, $min-width, $hover-bg-color, $hover-txt-color)

// Default extends
%box-shadow-black-30,
[class*="-shadow-"] {
  box-shadow: 0px 4px 4px rgba(60, 60, 60, 0.25), 0px 1px 3px rgba(60, 60, 60, 0.3);
}

%a-linkcolor-white {
  @include a-linkcolor($white, $white, none);
}

%a-linkcolor-gray-darker {
  @include a-linkcolor($gray-darkest, $gray-darkest, none);
}

%a-linkcolor-blue {
  @include a-linkcolor($blue, $blue-dark, none);
}

%btn-xl-base {
  font-size: 1.8rem !important;
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

//@mixin button($background-color, $bg-opacity, $border, $border-color, $font-size, $padding, $width, $hover-bg-color, $hover-txt-color: null) {
%btn-white-light-base {
  &:link,
  &:visited {
    color: $blue;
  };
  @include button($white, null, 1px, $gray-lightest, 13px, 10px 40px, null, $white, $hover-txt-color: $blue-darker);
  &:hover {
    border-color: $gray-lighter;
  }
}

.btn-white-orange-no-border-s {
  color: $orange;
  font-size: 1.4rem;
  font-weight: 700;

  &:visited,
  &:link {
    color: $orange;
  }
}

%btn-white-light-pseudo-base {
  content: "";
  display: inline-block;
  height: 12px;
  position: relative;
  top: 2px;
  width: 12px;
}

.btn-white-light-external-link-right-s {
  @extend %btn-white-light-base;
  color: $red-light !important;

  &:hover {
    color: $red-dark !important;

    &::after {
      background: url("../images/external-link-red-dark.svg") center center no-repeat;
    }
  }

  &::after {
    @extend %btn-white-light-pseudo-base;
    background: url("../images/external-link-red-light.svg") center center no-repeat;
    right: -5px;
    top: 1px;
  }
}

.btn-white-light-shadow-s {
  @extend %btn-white-light-base;
  box-shadow: $black-10 0 1px 0;
  font-size: 13px;
  padding: 5px 15px;
}

%btn-opaque-base {
  &:link,
  &:visited {
    color: $white
  };
  @include button($black, 0.2, null, null, 13px, 10px 30px, null, $white);
  border: 1px solid hsla(360, 100%, 100%, 0.60); // white
}

.btn-opaque-play-icon-l {
  @extend %btn-opaque-base;
  &:hover {
    background-color: hsla(360, 0%, 0%, 0.20); // black
    border: 1px solid hsla(360, 100%, 100%, 0.80); // white
  }
  &::before {
    background: url("../images/play-video-white.svg") center center no-repeat;
    content: "";
    display: inline-block;
    height: 20px;
    margin-left: -10px;
    margin-right: 10px;
    position: relative;
    top: 5px;
    width: 20px;
  }
}

%btn-orange-base {
  color: $white;
  &:link,
  &:visited {
    color: $white
  };
  @include button($orange, null, null, null, 13px, 10px 40px, null, lighten($orange, 10%));
}

.btn-orange-l,
.btn-orange-xl,
.btn-orange-shadow-s,
.btn-orange-shadow-l {
  @extend %btn-ve;
  background-color: #ef7d00 !important;
}

.btn-orange-s {
  @extend %btn-orange-base;
  padding: 6px 35px;
}

/*
Back to top button
*/
#back-top {
  bottom: 75px;
  cursor: pointer;
  margin: 15px;
  position: fixed;
  right: 75px;
  transition-duration: 1s;
  transition-property: all;
  transition-timing-function: ease-in-out;
}
#back-top .arrow-up {
  background: url("../images/arrow-up-dark.svg") center center no-repeat;
  display: block;
  height: 40px;
  width: 40px;
}
// video button.
// This class name is the anchor for the javascript action.
// Synchronize this name with the "domButtonSelector" variable in the you change it.
// Look in to ".../video_section/js/script.js" for more details.
.btn-play-video-section{
  // @extend %btn-base;
  @include a-linkcolor($blue, $blue, none);
  background: url("../images/play-button.png") 0px 2px no-repeat;
  padding-left:25px;
  font-size: 1.6rem;
}
// Useful for the containers that contains button;
.btn-wrap-full-width{
  a{
    width: 100%;
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.bounce {
  animation: bounce 2s infinite;
}

ul.share-buttons {
  float: right;
  list-style: none;
  margin-top: -40px;
  padding: 20px 0;

  li {
    float:left;
    margin-left: 15px !important;

    &:first-child {
      margin-top: 5px;
    }
  }
}

.share-buttons-top .share-buttons {
  margin-top: -40px;
}

.btn-ve,
%btn-ve {
  font-family: $open-sans-bold;
  background-color: #fff;
  color: #fff !important;
  border: 0;
  padding: 20px 40px;

  &.btn-orange-solid {
    background-color: #ef7d00;

  }
  
  &.btn-darkblue-solid {
    background-color: #2C3650;
    
  }
  
  &.btn-white-orange-border {
    border: 1px solid #ef7d00;
    color: #ef7d00 !important;
  }
  
  &.btn-white-darkblue-border {
    border: 1px solid #2C3650;
    color: #000 !important;
  }
}

.btn-tp,
%btn-tp {
  padding: 16px 32px;
  border-radius: 4px !important;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  &.btn-primary-1 {
    background: #4573D2;
    color: #fff;
  }
  
  &.btn-secondary-1 {
    border: 1px solid #29283E;
    color: #29283E;
  }

  &.btn-tertiary-1 {
    color: #29283E;
    text-decoration-line: underline;
    text-decoration-color: lightgrey;
    text-decoration-thickness: 1px;
  }
}

.btn-white-light-s,
.btn-white-light-arrow-right-no-border-red-txt-s,
.btn-opaque-shadow-l,
.btn-opaque-l,
.btn-opaque-xl {
  @extend %btn-ve;
  border: 1px solid #ef7d00 !important;
  color: #ef7d00 !important;
}

.btn-blue-l,
.btn-blue-xl,
.btn-blue-shadow-l,
.btn-blue-shadow-xl,
.btn-blue-light-l,
.btn-blue-light-shadow-l,
.btn-blue-shadow-s,
.btn-green-l,
.btn-green-xl,
.btn-green-shadow-l {
  @extend %btn-ve;
  background-color: #323e48;
}

.btn-white-light-arrow-left-s,
.btn-white-light-arrow-right-s,
.btn-white-light-arrow-right-no-border-blue-txt-s,
.btn-gray-lightest-l,
.btn-gray-lightest-xl,
.btn-gray-lightest-shadow-l {
  @extend %btn-ve;
  border: 1px solid #323e48;
  color: #000 !important;
}

.btn-blue-dark-l {
  font-size: 1.4rem;
  font-weight: 700;
  color: white;
  background-color: #2c3650;
  padding: 10px 40px;
  border: 1px solid #2c3650;

  &:hover{
    cursor: pointer;
  }
}

.btn-blue-dark-l:link, .btn-blue-dark-l:visited {
  color: #fff;
}

.btn-white-orange-no-border-s {
  color: #ef7d00;
  font-size: 1.4rem;
  font-weight: 700;
}

.btn-white-orange-no-border-s:visited, .btn-white-orange-no-border-s:link {
  color: #ef7d00;
}

.node-app-full .hero-market a {
  text-align: left;
}

.btn-shadow-1,
%btn-shadow-1 {
  box-shadow: 0px 6px 20px #2D2B3433;
}

.btn-shadow-hover-1,
%btn-shadow-hover-1 {
  box-shadow: 0px 6px 20px #2D2B341A;
}

.btn-pri-sec-base,
%btn-pri-sec-base {
  @extend %btn-shadow-1;
  padding: 17px 20px;
  transition: all .5s;
}

// Primary
.btn-pri-dark-1,
.btn-pri-dark-1:visited,
.btn-pri-dark-1:link {
  @extend %btn-pri-sec-base;
  color: #fff;
  background-color: #2D2B34;

  &:hover {
    @extend %btn-shadow-hover-1;
    background-color: #403D48;
  }
}

// Secondary
.btn-sec-light-1,
.btn-sec-light-1:visited,
.btn-sec-light-1:link {
  @extend %btn-pri-sec-base;
  color: #2D2B34;
  background-color: #fff;

  &:hover {
    @extend %btn-shadow-hover-1;
  }
}

// Discreet
.btn-dis-dark-1,
.btn-dis-dark-1:visited,
.btn-dis-dark-1:link {
  color: #2D2B34;
  text-decoration: underline;
}