/*
Formats
===========================
*/

/*
basefont size & lineheight
----------------------
 */
$fontsize: 1.6em;

html {
  line-height: 25x;
}

body {
  color: $gray-primary;
  font-size: $fontsize;
  font-weight: 300;
  article {
    @include linkcolor($orange, $orange-dark, $decorationhover:none);
  }
}

/*
Headings with links
==========
*/
h1, h2, h3, h4, h5, h6,
h1 *, h2 *, h3 *, h4 *, h5 *, h6 *, {
  @include linkcolor($titlecolor, $hover:$titlecolor, $decorationhover:none);
  font-family: $open-sans;
  font-style: normal;
  font-weight: 300;
  line-height: 1;
  margin: 0;
}

h1 {
  font-size: 6.4rem;
  line-height: 6.4rem;
  margin-bottom: 10px;
  a:hover { font-weight:300; }
}

h2 {
  font-size: 3.6rem;
  line-height: 3.6rem;
  a:hover { font-weight:300; }
}

h3 {
  font-size: 2.8rem;
  line-height: 2.8rem;
}

h4 {
  font-size: 2rem;
  line-height: 2rem;
}

h3.ff1,
.ff1 h3 {
  font-family: $open-sans;
}

.ff2 {
  font-family: $open-sans;
}

h4 {
 font-size: 1.8rem;
}

h5 {
 font-size: 1.5rem;
}

blockquote.single-quote {
  color: $white;
  font-size: 4rem;
  margin: 0;
  quotes: "\201C" "\201D" "\2018" "\2019";

  &::before,
  &::after {
    color: $orange;
    content: open-quote;
    font-family: $verdana;
    font-size: 5rem;
  }

  &::after {
    content: close-quote;
  }

  & p {
    display: inline;
  }
}

.single-quote-author {
  padding-top: 20px;
  font-size: 24px;
  font-style: italic;
}

// For preview of testimonial
.node-testimonial {
  margin: 0 auto;
  width: $wrap-page-width / 2;
  padding: 20px 0;
}

.views-field-field-testimonial-video-url {
  float: left;
  width: 480px;
  padding-right: 20%;
}

.views-field-field-testimonial-video-title {
  font-size: 2.4rem;
  font-weight: 300;
  margin-bottom: 25px;
}

//
/**
* UL/OL List Styles
*/
ul {
  // UL w. checkmarks in place of bullets for LI
  &.checkmark {
    list-style: none;
    li {
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: 24px 24px;
      margin-left: 0 !important;
      padding-left: 32px;
    }
    &.checkmark-black {
      li {
        background-image: url('../images/checkmark-black-fixedsize.svg');
      }
    }

    &.checkmark-white {
      li {
        background-image: url('../images/checkmark-white.svg');
      }
    }

    &.checkmark-orange {
      li {
        background-image: url('../images/checkmark-orange.svg');
      }
    }

    &.checkmark-m-s1 { // mobile style 1
      font-size: 2.4rem;
    }
  }
}

// Add line-height for ul checkmark outside of template
.field-section-content:not([class*="template"]) {
  ul.checkmark li {
    margin-bottom: 20px;
  }
}

/**
 * Testimonials
 */
.view-testimonials-carousel {
  width: $wrap-page-width;
  margin: 0 auto;
  padding: 30px 25px 0 25px;

  @include respond-to(medium-screen) {
    width: $wrap-page-width/2;
  }

  @include respond-to(small-screen) {
    width: $wrap-page-width/2;
  }

  @include respond-to(extra-small-screen) {
    width: 100%;
  }

  .owl-item {
    .profile-image {
      img {
        border-radius:50%;
        width: 100%;
        height: auto;
      }
    }

    .information {
      @include respond-to(small-screen) {
        text-align: center;
      }

      @include respond-to(extra-small-screen) {
        text-align: center;
      }

      .title {
        float: left;
        font-weight: 700;

        @include respond-to(small-screen) {
          float: none;
          display: inline-block;
        }

        @include respond-to(extra-small-screen) {
          float: none;
          display: inline-block;
        }
      }

      .position {
        float: left;
        font-weight: 300;

        @include respond-to(small-screen) {
          float: none;
          display: inline-block;
        }

        @include respond-to(extra-small-screen) {
          float: none;
          display: inline-block;
        }

        &::before {
          content: ', ';
        }
      }
    }

    .summary {
      padding: 20px 0;
      font-size: 2.5rem;
      font-weight: 300;
      font-style: italic;
      line-height: 2.8rem;
      color: $gray-darker;

      &::before,
      &::after {
        content: '"';
      }
    }
  }

  .owl-nav {
    margin-top: 25px;

    .owl-pagination {
      .owl-page {
        border: 1px solid $gray-darker;
        border-radius: 50%;
        height: 24px;
        width: 24px;
        margin: 0 4px;
        padding: 3px;

        span {
          background-color: $gray-darker;
          height: 16px;
          width: 16px;
          margin: 0;
          padding: 0;
          transition-property: opacity;
          transition-duration: 0.3s;
          transition-timing-function: ease-in-out;
        }
      }
    }
  }
}