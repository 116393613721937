.page-site-trial {
  #content {
    margin: 0 auto;
    width: $wrap-page-width;
  }
}


// Short version of the demo form.
.client-form--demo-1 {
  @extend .client-form--left;

  // Pre-form text is left centered
  text-align: left;

  .client-form__fieldset {
    // Shrink distance to the pre-form text
    margin-top: 20px;
    padding: 0;

    // No background and borders
    background: transparent;
    box-shadow: none;

    .form-submit {
      // Correct margins in the new position
      margin-top: 21px;
      margin-left: 30px;
    }

    .form-item--checkbox {
      // Shrink distance to the form fields
      margin-top: 18px;
      width: $client-form-filed-width;
    }
  }

  .client-form__longitem {
    // special item that holds filed & submit button
    display: flex;

    .form-item {
      margin-bottom: 0;
    }
  }

}

// submit - left
.client-form--demo-3 {
  @extend .client-form--slim-left;
}

// submit - center
.client-form--demo-4 {
  @extend .client-form--slim-line;

  .form-item--center {
    width: $client-form-filed-width;
  }

  input.form-submit {
    margin: 0 auto !important;
  }
}

// Default demo form.
.client-form--demo-2 {
  @extend .client-form--dark;

  .client-form__fieldset {
    background-color: $blue-dark-newid;
  }
}
