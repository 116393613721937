// Mobile global settings
body {
  font-size: 1.6rem;
  line-height: 2rem;

  a,
  div,
  span {
    font-size: 1.6rem;
  }

  // Class to hide elements on desktop/mobile in case editors add text only for desktop
  // Overwrite hide-on-desktop on mobile.
  .hide-on-desktop {
    display: block;
  }

  .hide-on-mobile {
    display: none;
  }

  .hide-p-on-mobile {
    display: none;
  }

  .md-blk-m {
    display: block;
  }
}

.fs1-m {
  font-size: 7px;
}

.fs2-m {
  font-size: 13px;
}

.fs3-m {
  font-size: 20px;
}

.fl {
  float: none;
  width: 100% !important;
  position: initial !important;
}

/* Mobile-size 3 of 3 - full width. */
.ms3of3,
.s1of1-m {
  width: 100% !important;
}

.md_txtc_mobile {
  text-align: center
}

.ptt,
.pvt,
.pat {
  padding-top: 3px;
}

.pts,
.pvs,
.pas {
  padding-top: 7px;
}

.ptm,
.pvm,
.pam {
  padding-top: 13px;
}

.ptl,
.pvl,
.pal {
  padding-top: 20px;
}

.pte,
.pve,
.pae {
  padding-top: 33px;
}

.prn,
.phn,
.pan {
  padding-right: 0;
}

.prt,
.pht,
.pat {
  padding-right: 3px;
}

.prs,
.phs,
.pas {
  padding-right: 7px;
}

.prm,
.phm,
.pam {
  padding-right: 13px;
}

.prl,
.phl,
.pal {
  padding-right: 20px;
}

.pre,
.phe,
.pae {
  padding-right: 33px;
}

.pbn,
.pvn,
.pan {
  padding-bottom: 0;
}

.pbt,
.pvt,
.pat {
  padding-bottom: 3px;
}

.pbs,
.pvs,
.pas {
  padding-bottom: 7px;
}

.pbm,
.pvm,
.pam {
  padding-bottom: 13px;
}

.pbl,
.pvl,
.pal {
  padding-bottom: 20px;
}

.pblh,
.pvlh,
.palh {
  padding-bottom: 23px;
}

.pbe,
.pve,
.pae {
  padding-bottom: 33px;
}

.pln,
.phn,
.pan {
  padding-left: 0;
}

.plt,
.pht,
.pat {
  padding-left: 3px;
}

.pls,
.phs,
.pas {
  padding-left: 7px;
}

.plm,
.phm,
.pam {
  padding-left: 13px;
}

.pll,
.phl,
.pal {
  padding-left: 20px;
}

.ple,
.phe,
.pae {
  padding-left: 33px;
}

.mttn,
.mvtn,
.matn {
  margin-top: -3px;
}

.mtsn,
.mvsn,
.masn {
  margin-top: -7px;
}

.mtln,
.mvln,
.maln {
  margin-top: -20px;
}

.mtn,
.mvn,
.man {
  margin-top: 0;
}

.mtt,
.mvt,
.mat {
  margin-top: 3px;
}

.mts,
.mvs,
.mas {
  margin-top: 7px;
}

.mtm,
.mvm,
.mam {
  margin-top: 13px;
}

.mtl,
.mvl,
.mal {
  margin-top: 20px;
}

.mtu,
.mvu,
.mau {
  margin-top: 26px;
}

.mte,
.mve,
.mae {
  margin-top: 39px;
}

.mtes,
.mves,
.maes {
  margin-top: 46px;
}

.mrtn,
.mhtn,
.matn {
  margin-right: -3px;
}

.mrsn,
.mhsn,
.masn {
  margin-right: -7px;
}

.mrln,
.mhln,
.maln {
  margin-right: -20px;
}

.mrn,
.mhn,
.man {
  margin-right: 0;
}

.mrt,
.mht,
.mat {
  margin-right: 3px;
}

.mrs,
.mhs,
.mas {
  margin-right: 7px;
}

.mrm,
.mhm,
.mam {
  margin-right: 13px;
}

.mrl,
.mhl,
.mal {
  margin-right: 20px;
}

.mre,
.mhe,
.mae {
  margin-right: 39px;
}

.mbtn,
.mvtn,
.matn {
  margin-bottom: -3px;
}

.mbsn,
.mvsn,
.masn {
  margin-bottom: -7px;
}

.mbln,
.mvln,
.maln {
  margin-bottom: -20px;
}

.mbn,
.mvn,
.man {
  margin-bottom: 0;
}

.mbt,
.mvt,
.mat {
  margin-bottom: 3px;
}

.mbs,
.mvs,
.mas {
  margin-bottom: 7px;
}

.mbm,
.mvm,
.mam {
  margin-bottom: 13px;
}

.mbl,
.mvl,
.mal {
  margin-bottom: 20px;
}

div.mbl-m, .mbl-m{
  margin-bottom: 20px;
}

.mbe,
.mve,
.mae {
  margin-bottom: 39px;
}

.mltn,
.mhtn,
.matn {
  margin-left: -3px;
}

.mlsn,
.mhsn,
.masn {
  margin-left: -7px;
}

.mlln,
.mhln,
.maln {
  margin-left: -20px;
}

.mln,
.mhn,
.man {
  margin-left: 0;
}

.mlt,
.mht,
.mat {
  margin-left: 3px;
}

.mls,
.mhs,
.mas {
  margin-left: 7px;
}

.mlm,
.mhm,
.mam {
  margin-left: 13px;
}

.mll,
.mhl,
.mal {
  margin-left: 20px;
}

.mle,
.mhe,
.mae {
  margin-left: 39px;
}

/* Font size */
.fs1 {
  font-size: 33px;
}

.fs2 {
  font-size: 26px;
}

.fs3 {
  font-size: 20px;
}

.fs4 {
  font-size: 16px;
}

.fs5 {
  font-size: 12px;
}

.fs6 {
  font-size: 10px;
}

.fs7 {
  font-size: 10px;
}

.fs8 {
  font-size: 9px;
}

.fs9 {
  font-size: 8px;
}

.spinner-small:before {
  content: '';
  width: 13px;
  height: 13px;
  margin-top: -7px;
  margin-left: -7px;
}

.flex-row-mobile {
  flex-direction: row;
}
.flex-row-reverse-mobile {
  flex-direction: row-reverse;
}
.flex-column-mobile {
  flex-direction: column;
}
.flex-column-reverse-mobile {
  flex-direction: column-reverse;
}
