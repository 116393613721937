.html {
  .event-calendar {
    grid-template-columns: repeat(2, 1fr) 130px 215px 98px .5fr !important;
  }

  .item {
    padding: 7px;
  }

  .item-3,
  .item-4 {
    grid-column: span 2
  }

  .view-id-event_>.event-calendar,
  .event-calendar [class*="item-4"] {
    display: none
  }
}