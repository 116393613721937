.view-dictionary-overview .word a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.node-full.node-dictionary {
  max-width: $wrap-page-width;
  margin: 0 auto;
}
.node-dictionary {
  // hide inline style border on tables
  table,
  td {
    border: none;
  }

  a {
    color: #0085CC;
    text-decoration: none;
  }

  ul {
    margin: .5rem 1.5rem;
  }

  // Reset .row margin for dictionary
  .row {
    margin-left: 0;
    margin-right: 0;
    padding-top: 15px;
    padding-bottom: 15px;

    figure {
      text-align: center;
    }
  }

  .field-name-field-dictionary-definition {
    border: 1px solid #ddd;
    font-size: 1.8rem;
    font-family: inherit !important; 
    padding: 1.5rem 1.5rem 1rem 6rem;
    margin: 0 0 3rem 0;
    position: relative;
    min-height: 55px;
    line-height: inherit;

    &:before {
      background: url('../images/info.svg');
      background-size: cover;
      content: "";
      display: block;
      height: 3rem;
      left: 1.5rem;
      position: absolute;
      top: 1.2rem;
      width: 3rem;
    }
  }

  .field-name-field-dictionary-upsale {
    background: #EEF8FE;
    color: #25241F;
    padding: 1rem 1.5rem;
    margin-bottom: 30px;
  }

  .panel-pane {
    margin-bottom: 2rem;
  }

  h1 {
    color: $black;
    font-size: 4rem;
    line-height: 4.5rem;
    padding-bottom: 10px;
  }

  .col-sm-3 {
    [class*="-related-words"] {
      h3 {
        font-size: 1.8rem;
        border: none;
        padding-bottom: 0;
      }
    }
  }

  .nav-button a, .demo-button a {
    border: 1px solid #ddd;
    font-size: 1.2rem;
    line-height: 1.4rem;
    padding: 1rem 1.5rem;
    text-decoration: none;
    text-transform: uppercase;
  }

  .demo-button a {
    color: #f39200;
  }
}
