h1,
h1 span {
  font-size: 3.6rem;
  line-height: 3.6rem;
  margin-bottom: 7px;
}

h2,
h2 span {
  font-size: 3.2rem;
  line-height: 3.2rem;
}

h3,
h3 span {
  font-size: 2.4rem;
  line-height: 2.4rem;
}

h4,
h4 span {
  font-size: 2rem;
  line-height: 2rem;
}

h5 {
  font-size: 1rem;
}

.wrap {
  width: 100% !important;
}

.block {
  margin-bottom: 0 !important;
}

ul {
  &.checkmark {
    &.checkmark-m-s1 {

      // mobile style 1
      li {
        background-position: 0 5px;
        margin-bottom: 23px;
      }

      span {
        font-size: 1.6rem;
      }
    }

    li {
      padding-left: 21px;
    }
  }
}

ul.checkmark.checkmark-orange li {
  background-size: 20px 26px;
}