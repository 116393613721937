.fivestar-summary {
  /* Hiding the votes count text, in any case, both unvoted and with number of votes. */
  .empty {
    display: none;
  }
}

/* For fivestar ratings being displayed as viewing without editing. */
.fivestar-static-item {
  .form-item {
    padding: 0 16px;
    margin: 0;
  }
}
