.instagram-social-feed {
  margin-top: 40px;

  ul {
    display: initial;

    li {
      margin-top: 20px;
    }
  }

  .social-feed-link {
    margin-top: 100px;
    width: 100%;
  }
}