.view-dictionary-overview,
.view-newspress-overview {
  nav.alphabet {
    display: none;
  }

  .view-content {
    column-gap:33px;
    padding-bottom: 20px;
  }

  .item-list {
    margin-bottom: 33px;

    h3 {
      font-size: 1.3rem;
      padding: 10px 0 10px 0;
      margin-bottom: 10px;
    }

    ul {
      li {
        font-size: 1rem;
        margin-bottom: 3px;
      }
    }
  }
}

.html {

  &.page-dictionary,
  &.page-newspress {
    .view-content {
      column-count:1;
    }

    .item-list {
      h3 {
        padding-left: 7px;
      }

      ul {
        margin-left: 7px;
      }

      &::before {
        color: $blue-darker;
        content: '+';
        display: block;
        float: right;
        font-size: 20px;
        font-weight: 700;
        height: 7px;
        line-height: 20px;
        padding: 13px 20px 0;
        width: 7px;
      }

      &.expanded {
        &::before {
          content: "\2013";
        }
      }
    }

    h3 {
      cursor: pointer;
    }

    ul {
      display: none;
    }
  }
}

.dictionary-overview__header{
  font-size:2.9rem; 
  line-height: 2rem;
}

.pane-google-cse-search-box {
  padding: 7px 0;
}