@media (max-width: $responsive-mobile) {
  @import "mobile/init";
  @import "mobile/base";
  @import "mobile/formatting";
  @import "mobile/fixed";
  @import "mobile/misc";
  @import "mobile/icons";
  @import "mobile/buttons";
  @import "mobile/layout";
  @import "mobile/forms";
  @import "mobile/wysiwyg_templates";
  @import "mobile/navigation";
  @import "mobile/colorbox.module";
  @import "mobile/addon_modules.view";
  @import "mobile/client_form";
  @import "mobile/site_app_ext";
  @import "mobile/gdpr_form";
  @import "mobile/order_flow.module";
  @import "mobile/order_block";
  @import "mobile/webform";
  @import "mobile/site_app_login.module";  
  @import "mobile/site_trial.module";
  @import "mobile/site_presentation.module";
  @import "mobile/carousel";
  @import "mobile/ctools.module";
  @import "mobile/dictionary_list";
  @import "mobile/ratings.module";
  @import "mobile/site_accountant_map_popup.module";
  @import "mobile/site_accountant.module";
  @import "mobile/site_dictionary.module";
  @import "mobile/site_event_calendar";
  @import "mobile/site_job";
  @import "mobile/site_newsmail.module";
  @import "mobile/site_search.module";
  @import "mobile/video_section";
  @import "mobile/webinar";
  @import "mobile/instagram";
  @import "mobile/wiki";
}

@media (max-width: $responsive-tablet) {
  @import "tablet/navigation";
}