.wiki-left-menu {
  display: none;
}

.wiki-left-menu-container{
  display: none;
}

.wiki-launch{
  margin-left: 10px;
  margin-right: 10px;
}

.wiki-info-article, .wiki-flow-article, .wiki-button-article {
  margin-right: 10px;
}

.wiki-categories {
  padding-left: 0;

  .wiki-category {
    width: 50%;
    padding: 10px 10px;

    &:nth-child(odd) .wiki-tags-in-category {
      margin-left: -10px;
      padding-top: 20px;
    }
    
    &:nth-child(even) .wiki-tags-in-category {
      margin-left: -217px;
      padding-top: 20px;
    }

    li.wiki-btn-lnk {
      width: 100%;
    }

    .field-content > a {
      height: 110px;
      font-size: 16px;
      line-height: 1.3;
      padding-left: 5px;
      padding-right: 5px;

      img {
        height: 30px;        
        width: 30px;
      }
    }
  }
}

.wiki-btn-cat{
  margin: 0 15px;
}

.s4of5 .panel-pane.pane-custom.pane-2 > div{
  width: 100%;
}

.pane-site-wiki-api-show-tags-for-category {
  margin-right: 14px;
  margin-left: 2px;

  .wiki-tags-in-category li {
    padding-left: 0px;

    &:nth-child(odd) {
      padding-right: 8px;
    }
    &:nth-child(even) {
      padding-left: 8px;
      padding-right: 0px;
    }
  }
}

.view-wiki-categories {
  margin: 0;
}

.wiki-tags-in-category li {
  width: 50%;
  padding: 10px 8px;

  a {
    width: unset !important;
  }
}

.view-wiki-articles {
  padding: 0 10px 0 10px;
  margin-left: 0;

  .views-field.views-field-type {
    height: 25px;
    margin-right:10px;
  }
}

.wiki-faqs-wrapper {
  margin: 0 20px;
}

.wiki-article-list-tags-header,
.wiki-article-list-category-header {
  margin-left: 20px;
}

.wiki-faqs-bookmarks {
  .wiki-bookmark {
    width: 50%;
  }
}

.node-wiki {
  padding-top: 20px;

  .field-name-title-field {
    padding-bottom: 20px;
  }
}

.wiki-content-container {
  padding-left: 0;

  #cludo-search-results{
    .search-results{
      margin-left:0;
      margin-top: 35px;
    }
  }
}

.wiki-search, .ecopedia-search {
  .form-actions {
    display: none;
  }
} 

.nc-cards-t1 > .card {
  margin-left: auto;
  margin-right: auto;
}

.wiki-button-image, .wiki-flow-image, .wiki-info-image {
  img {
    width: 100%;
    height: auto;
  }
}

.wiki-button-article {
  .intro {
    width: 100%;
  }

  .wiki-bookmarks{
    margin-left: -10px;
    margin-right: -10px;

    .wiki-bookmark{
      width: 50%;
    }
  }
}

.node-type-wiki-button-article{
  .wiki-page__header h1{
    &:before{
        width: 45px;
        height: 25px;
    }
  }
}

.wiki-button-article-section {
  .wiki-button-element {
    margin-left: 0px;
  }
  .wiki-button-element-left img {
    padding-left: 10px;
    height: auto;
  }
  .wiki-button-element-right {
    padding-left: 5px;
    padding-right: 20px;
  }
}

.pane-site-wiki-api-show-tags-for-category {
  margin-left: 15px;
}

#cludo-search-form-wiki .search_autocomplete {
  width: 100% !important;
}

.wiki-type-icon {
  width:100%;
}

.wiki-page__header,
.wiki-page__subheader{
  width:100%;
  margin-left: 0;
}

.wiki-footer {
  width: 100%;

  & .nc-cards-t1 > li.card {
    width: 90%
  }
}

.wiki-content-container {
  .wiki-footer {
    .panel-pane div.panel-inner-wrapper{
      width: 100%;
    }
  }
}

.pane-site-wiki-api-show-tags-for-category .wiki-tags-in-category {
  margin-left: 0;
}

.wiki-button-element .row{
  margin-left: 0;
  margin-right: 0;
}

.wiki-button-element-left, .wiki-button-element-right{
  vertical-align: middle;
}