.view-dictionary-overview,
.view-newspress-overview {
  nav.alphabet {
    margin: 20px -13px;

    ul {
      display: table;
      width: 100%;

      li {
        a {
          color: $gray-darker;
          text-decoration: none;
          &:hover {
            color: $gray;
          }
        }
        display: table-cell;
        text-align: center;
        margin: 0;
      }
    }
  }

  .view-content {
    column-count:2;
    column-gap:50px;
    padding-bottom: 30px;
  }

  .item-list {
    display: inline-block;
    margin-bottom: 50px;
    width: 100%;

    h3 {
      border-bottom: 1px solid;
      border-color: $gray-lighter;
      font-size: 2rem;
      font-weight: 300;
      padding: 15px 0 15px 0;
      margin-bottom: 15px;
    }

    ul {
      margin-top: 0;
      margin-bottom: 0;
      width: 100%;

      li {
        clear: both;
        display: block;
        float: left;
        font-size: 1.6rem;
        margin-bottom: 5px;
        margin-left: 0;
      }

      a {
        &:link, &:visited {
          color: $blue;
        }
      }
    }
  }
}

.view-newspress-overview {
  .view-content {
    column-count:2;
  }
}

.dictionary-overview__header{
  text-align: center;
  color:#FFFFFF; 
  font-size:45px; 
  font-weight:bold;
  padding: 0.75em 0; 
  margin:0;
  line-height: normal;
}

/* Sticky menu */
.pane-google-cse-search-box {
  background-color: $white;
  width: 100%;
  padding: 10px 0;
  position: relative;

  &.fixed {
    position: fixed;
    top: 0;
    z-index: 100;
  }
}
