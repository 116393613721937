//
// Initialization partial
//
// To make it easier to use all variables and mixins in any Sass file in this
// theme, each .scss file has a @import "init" declaration. And this _init.scss
// file is in charge of importing all the other partials needed for the theme.
//
// This initialization partial is organized in this way:
// - First we set any shared Sass variables.
// - Next we import Compass plug-ins (Sass mixin libraries).
// - Last we define our custom mixins for this theme.
//


// =============================================================================
// Variables
// =============================================================================

//
// Legacy IE support
//
// These variables are used by many mixins to add additional CSS to support
// specific versions of IE or specific vendor prefixes.
//
// IE6-7 don't support box-sizing: border-box. We can fix this in 1 of 3 ways:
// - Drop support for IE 6/7. :-) Set $legacy-support-for-ie6
//   and $legacy-support-for-ie7 to false.
// - (Preferred) Install the box-sizing polyfill and set the variable below to
//   the absolute path URL to the boxsizing.htc file.
//   @see https://github.com/Schepp/box-sizing-polyfill
//   $box-sizing-polyfill-path: "/path/to/boxsizing.htc";
// - Use the same CSS unit for grid and gutter width in resonsive-sidebars.scss
//   (use px for both or use % for both) and set the box-sizing variable to content-box.
//
// Zen does not require special handling for IE8 or later. But Compass uses that
// variable for a couple edge cases. We include it for completeness sake. See
// the documentation at http://compass-style.org/reference/compass/support/
$legacy-support-for-ie6: false;
$legacy-support-for-ie7: false;
$legacy-support-for-ie8: false;


//
// Font faces, stacks and sizes.
//

// Compass' vertical_rhythm extension is a powerful tool to set up a vertical
// rhythm for your entire page. You can see some of its mixins and functions in
// use in the normalize.scss file.
// @see http://compass-style.org/reference/compass/typography/vertical_rhythm/

$base-font-size:   16px; // The font size set on the root html element.
$base-line-height: 24px; // This line-height determines the basic unit of vertical rhythm.

$h1-font-size: 2 * $base-font-size;
$h2-font-size: 1.5  * $base-font-size;
$h3-font-size: 1.17 * $base-font-size;
$h4-font-size: 1    * $base-font-size;
$h5-font-size: 0.83 * $base-font-size;
$h6-font-size: 0.67 * $base-font-size;

// The following font family declarations are based on the Microsoft core web
// fonts which are common fonts available on most computer systems. The DejaVu
// and Nimbus Sans fonts are commonly available on Linux systems where the MS
// fonts are less common. Tahoma and Helvetica are also widely available.
//
// A user's web browser will look at the comma-separated list and will
// attempt to use each font in turn until it finds one that is available
// on the user's computer. The final "generic" font (sans-serif, serif or
// monospace) hints at what type of font to use if the web browser doesn't
// find any of the fonts in the list.

// First, let's create some font stacks.
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');
// @font-face {
//   font-family: 'PFBeauSansProXThin';
//   src: url('../font/PFBeauSansPro-XThin.otf');
// }
// @font-face {
//   font-family: 'PFBeauSansProThin';
//   src: url('../font/PFBeauSansPro-Thin.otf');
// }
// @font-face {
//   font-family: 'PFBeauSansProLight';
//   src: url('../font/PFBeauSansPro-Light.otf');
// }

$visma-thin:      "PFBeauSansProThin",Tahoma,Arial !important;
$open-sans:       "Open Sans", sans-serif;
$open-sans-bold:  "Open Sans Bold", "Open Sans", sans-serif;
$times-new-roman: "Times New Roman", Times;
$verdana:         Verdana, Tahoma;
$tahoma:          Tahoma, Verdana;
$helvetica:       Helvetica, Arial;
$arial:           Arial, Helvetica, sans-serif;
$sans-serif:      "Helvetica Neue", Helvetica;
$serif:           "Droid Serif", Georgia, serif;
$monospace:       "Monaco", "Courier New", monospace;
$cambria:         Cambria, sans-serif;

$fontbody:        $open-sans;
$fontitalic:      $open-sans;

// For an explanation of why "sans-serif" is at the end of this list, see
// http://meyerweb.com/eric/thoughts/2010/02/12/fixed-monospace-sizing/
$courier:         "Courier New", "DejaVu Sans Mono";

// Now create some variables for the font stacks we want to use on this site.
$base-font-family: $fontbody; // The font family set on the html element.
$font-body:        $fontbody;
$font-monospace:   $courier;

// *Old (deleted) = Replace with this*
// https://jira.e-conomic.net/browse/WD-2871
//                    | [old]                 -> [changed to]
// #024BFF = #0085cc  | $blue-dark            -> $blue
// #0B85B8 = #0085cc  | $blue-darker          -> $blue
// #0888CD = #0085cc  | $blue-pacific         -> $blue
// #aaa = #999        | $gray-dark            -> $gray-darker
// #e2e2e2 = #ddd     | $gray                 -> $gray-lighter
// #ed6d6d = #d00200  | $red                  -> $red-dark
// #d24a00 = #d00200  | $red-light            -> $red-dark
// #333745 = #323E48  | $steel-blue-dark      -> $arsenic
// #f2fbff = #eef8fa  | $steel-blue-lightest  -> $gray-background
// #fafafa = #fcfcfc  | $white-darker         -> $baige-lightest
// #fefefe = #fcfcfc  | $white-dark           -> $baige-lightest
// #f6f6f6 = #eee     | $white-gray           -> $baige-lightest
// #efe900 = #f39200  | $yellow               -> $orange
// #faffbd = #f39200  | $yellow-light         -> $orange

// CMS colors
$baige-lightest:        #fcfcfc;
$black-darkest:         #222;
$black:                 #000;
$blue-dark:             #0085cc; // obsolete
$blue-darker:           #0085cc; // obsolete
$blue-light:            #4BB9F3;
$blue:                  #0096FD;
$blue-pacific:          #0085cc; // obsolete
$blue-dodger-original:  #008FFF;
$blue-dodger:           #2188F4;
$blue-zodiac:           #454A5F;
$blue-havelock:         #4573D2;
$gray-darker:           #999;
$gray-more-dark:        #777;
$gray-darkest:          #333;
$gray-darkestale:       #4F4F4F;
$gray-primary:          #29283E;
$gray-dark-original:    #A1A1A1;
$gray-dark-originaler:  #A9A9A9;
$gray-dark:             #999;    // obsolete
$gray-dark-ligth:       #BFC2CA;
$gray-light:            #D5D5D5;
$gray-lights:           #CACACA;
$gray-lighter:          #ddd;
$gray-lightest:         #eee;
$gray-menu:             #f9f9f9;
$gray-app:              #f6f6f6;
$gray:                  #ddd;    // obsolete
$gray-background:       #eef8fa;
$green:                 #29B095;
$green-light:           #5BD9BF;
$emerald-light:         #00A473;
$medium-teal:           #08AE87;
$mango-tango:           #E86807;
$orange-darker:         #e96807;
$orange-dark:           #CB5E08;
$orange:                #ef7d00;
$orange-light:          #e78a1a;
$orange-lighter:        #f2a900;
$red:                   #d00200; // obsolete
$red-light:             #d00200; // obsolete
$red-dark:              #d00200;
$steel-blue-dark:       #323E48; // obsolete
$steel-blue-light:      #545e67;
$nobel-light:           #D5D5D5;
$nobel:                 #9A9A9A;
$steel-blue-lightest:   #eef8fa; // obsolete
$white-light:           #fafafa;
$white:                 #fff;
$white-dark:            #fcfcfc; // obsolete
$white-gray:            #eee;    // obsolete
$white-darker:          #fcfcfc; // obsolete
$yellow:                #f39200; // obsolete
$yellow-light:          #f39200; // obsolete
$arsenic:               #323E48;
$black-russian:         #232630;
$licorice:              #333745;
$suva-grey:             #8a8a8a;
$dark-haiti:            #201f24;

// New identity colors
$gray-newid:            #e6eae9;
$blue-dark-newid:       #2d2b34;

// Opacities
$black-10: hsla(0, 0%, 0%, 0.1);
$black-20: hsla(0, 0%, 0%, 0.2);
$black-30: hsla(0, 0%, 0%, 0.3);
$black-40: hsla(0, 0%, 0%, 0.4);
$black-50: hsla(0, 0%, 0%, 0.5);
$black-60: hsla(0, 0%, 0%, 0.6);
$black-70: hsla(0, 0%, 0%, 0.7);
$black-80: hsla(0, 0%, 0%, 0.8);
$black-90: hsla(0, 0%, 0%, 0.9);

$ink-95: hsla(260, 43%, 19%, 0.95);


$fontcolor     : $gray-darkest;
$titlecolor    : $gray-darkest;
$linkcolor     : $orange;
$linkcolorhover: $orange-dark;

// The amount lists, blockquotes and comments are indented.
$indent-amount: 30px;

// The height of the navigation container.
$nav-height: 3em;

// Tab styling.
$tabs-container-bg: #fff;
$tabs-border: #bbb;

// Wrapper
$wrap-width: 1200px;
$wrap-page-width: 1200px;
$wrap-page-wide-width: 1015px;
$wrap-page-max-width: 1015px;

// Trial, presentation
$form-width-default: 680px;

$default-border-radius: 3px;

// =============================================================================
// Media Queries.
// =============================================================================

// Large desktop
$large-width: 1200px;
// Portrait tablet to landscape and desktop
$standard-width: 980px;
$medium-width: 768px;
// Landscape phones and down
$small-width: 480px;
// . ancestor of the ".responsive-mobile" class
$responsive-mobile: 875px;
$responsive-tablet: 1199px;
// Sprite
$sprite: '../images/sprite/sprite-211116m.png';


// =============================================================================
// Partials to be shared with all .scss files.
// =============================================================================

// Add Compass' IE and vendor prefix support variables.
// @import "compass/support";
// Better than Drupal's clearfix.
// @import "compass/utilities/general/clearfix";

// See http://compass-style.org/help/tutorials/spriting/
// @import "compass/utilities/sprites";
// Use one CSS3 mixin instead of multiple vendor prefixes.
// @import "compass/css3";

// Helps set up a vertical rhythm.
// @import "compass/typography/vertical_rhythm";
// Add the Zen Grids responsive layout mixins.
// @import "zen";

// Now we add our custom helper mixins.
@import "mixins";
