.template1 {
  .profile-image  {
    margin-right: 30px;
    width: 170px;
    img {
      @extend %circle;
    }
  }
}

.template2 {
  .icon-block  {
    height: 295px;
    padding: 10px 2px;
    margin-right: 10px;
    margin-bottom: 60px;
    box-shadow: $black-30 0 3px 6px;
    text-align: center;
    background-color: #fff;
    .top-block {
      height: 250px;
      h3 {
        font-style: italic;
      }

    }
    .bottom-block {
      border-top: 1px solid $gray-lightest;
      padding-top: 5px;
      a {
        padding-top: 260px;
        width: 100%;
        display: block;
        top: -260px;
        position: relative;
        &:hover {
          background: transparent url(../images/circle-orange.svg) 70px 0 no-repeat;
        }
      }
    }
  }
}

.template2,
.template3,
.template4,
.template15 {
  [class*="s1of"] h2 {
    font-size: 2rem;
    font-weight: 700;

    a {
      color: $black;
    }
  }

  [class*="s1of"] {
    &.hover {
      cursor: pointer;

      h2 a {
        color: $blue;

        &:hover {
          font-weight: 700;
        }
      }
    }
  }

  .s1of2 {
    h2 + p {
      padding-top: 5px;
    }

    p {
      padding-left: 90px;
      padding-right: 90px;
    }

    .icon {
      margin-bottom: 18px;
    }
  }

  a:hover {
    text-decoration: none;
  }

}

.template2,
.template3 {
  .box {
    background-color: #fff;
    border: 1px solid $gray-lightest;
    box-shadow: rgba(0, 0, 0, 0.05) 0 3px 6px;
    font-size: 1.6rem;
    margin-right: 20px;
    padding: 10px 20px;
    position: relative;

    h3 {
      font-style: italic;
      font-size: 1.6rem;
    }

    p {
      min-height: 150px;
    }
  }

  .icon-wrapper {
    margin: 30px;
  }


  & .hover {

    .box:before {
      border-radius: 50%;
      border: 1px solid $orange;
      content: '';
      left: 106px;
      top: 26px;
      width: 75px;
      height: 75px;
      position: absolute;
      z-index: 100;
    }

    .bottom-link > a {
      color: $blue;
    }
  }
}

.template3 {

  .bottom-link {
    border-top: 1px solid $black-10;
    padding-top: 10px;
    padding-bottom: 10px;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-left: -20px;

    a {
      color: $gray;

      &:hover {
        color: $blue;
      }
    }
  }
}

.template4,
.template6 {

  // Left and right box breaks the content area to get more space between them still having normal font size.
  .s1of3 {
    width: 32.79%;

    &:first-child {
      margin-left: -25px;
      margin-right: 25px;
    }

    &:nth-child(3) {
      position: relative;
      right: -45px;
    }

    .icon {
      margin-bottom: 20px;
    }

    h2 + p {
      padding-top: 0;
    }
  }
}

.template7 {
  .content-top,
  .content-bottom {
    padding: 20px 0;
  }

  .content-top {
    border-bottom: 1px solid $gray-lighter;
  }

  .icon {
    height: 50px;
  }

  .col {
    display: inline-block;
    width: 300px;
    border: 1px solid $gray-lighter;
    background-color: $white;
    margin: 0 3px;
    font-size: 1.4rem;
    vertical-align: top;

    .headline {
      font-style: italic;
      padding: 10px 0 20px 0;
    }

    .price {
      position: relative;
      padding-top: 10px;

      .currency {
        position: relative;
        top: -23px;
      }

      .actual-price {
        font-size: 4.8rem;
      }
    }

    .per-month {
      position: relative;
      top: -5px;
    }

    .call-us {
      color: $orange;
      font-weight: 700;
    }

    .text-top,
    .text-bottom {
      padding: 20px;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      height: 100px;
      width: 100%;
    }

    .text-top {
      height: 150px;
    }

    .usp-bullets {
      text-align: center;
      margin: 0px auto;
      width: 100%;
    }

    .bullet,
    .bullet-text {
      display: inline-block;
    }

    .bullet {
      background: url("../images/checkmark-black.svg") no-repeat left top;
      height: 20px;
      width: 20px;
    }

    .content-bottom p {
      clear: both;
    }
  }
}

.template8 {
  &:first-child {
    border-top: 1px solid;
  }

  &:first-child,
  .table {
    border-color: $gray;

  }

  .name,
  .address,
  .zip,
  .phone {
    display: inline-block;
    padding: 5px;
  }

  .name {
    padding-left: 10px;
    width: 25%
  }

  .address {
    width: 35%;
  }
  .zip,
  .phone {
    width: 19%;
  }
}

.template9 {
  border-top: 1px solid $gray-lightest;
  width:100%;

  .headline {
    display: inline-block;
    font-size: 2.5rem;
    padding-right: 10px;
    position: relative;
    top: -20px;
  }
}

.template10 { // grid 3 x 3
  .row {
    display: table-row;
    margin: 0;
  }

  [class^="col"].clickable {
    border-right: 1px solid $gray-lightest;
    border-bottom: 1px solid $gray-lightest;
    color: $gray-more-dark;
    display: table-cell;
    height: 220px;
    text-align: center;
    vertical-align: middle;
    width: 33.33%;

    p {
      padding: 0 40px;
      position: relative;
      z-index: 1;
    }

    .read-more {
      color: $blue;
      font-size: 1.4rem;
      margin-top: 5px;
    }

    &.hover {
      background-color: $baige-lightest;

      a {
        color: $blue;
      }
    }

    i.icon {
      height: 40px;
      width: 40px;
    }

    h2 {
      font-size: 2rem;
      padding-top: 10px;

      a {
        color: $black;
        position: relative;
        z-index: 2;
      }
    }
  }

  .col3 {
    border-right: none !important;
  }

  .row:last-child [class^="col"] {
    border-bottom: none;
  }
}

.template11 { // Add on modules template
  // .node-app.node-teaser; // defined in _site_app.module.scss
  .col-md-3.col-sm-4.col-xs-6 {
    padding: 0;
    margin-right: 20px;
    width: 275px;

    &:nth-child(4n+4) {
      padding-right: 0;
      margin-right: 0;
    }
  }

  .node-app.node-teaser {
    background-color: $white;
    border-radius: 6px;
    height: auto;
    min-height: 270px;
  }

  .field-name-field-app-icon {
    height: 110px;
    width: 100%;

    .icon-wrapper {
      padding-top: 35px;
    }
  }

  .field-name-field-app-name {
    h3 {
      font-size: 1.8rem;
      margin: 15px 0;
    }
  }

  .field-name-field-app-abstract {
    line-height: 2rem;
  }

  .read-more {
    padding: 0 1.3rem;
    margin: 10px 0;
  }

  .new-lable {
    background-color: $orange;
    border-radius: 50%;
    color: $white;
    font-size: 1.6rem;
    height: 40px;
    left: -10px;
    padding-top: 12px;
    position: absolute;
    top: -15px;
    width: 40px;
  }
}

.template12 { // 3 x 3 - cases
  .row {
    display: table-row;
    margin: 0;
  }

  [class^="col"].clickable {
    border: 1px solid $gray-lightest;
    border-bottom: none;
    border-right: none;
    color: $gray-more-dark;
    display: table-cell;
    height: 380px;
    text-align: center;
    vertical-align: middle;
    width: (100%/3);

    p {
      padding: 0 40px;
      position: relative;
      z-index: 1;
    }

    .placeholder-hover {
      position: relative;
    }

    &.hover .placeholder-hover {
      outline: 2px solid $orange;

      a {
        color: $blue;
      }
    }

    i.icon {
      height: 40px;
      width: 40px;
    }

    h2 {
      font-size: 1.8rem;
      line-height: 3rem;

      a {
        color: $black;
        position: relative;
        z-index: 2;
      }
    }
  }

  .col3 {
    border-right: 1px solid $gray-lightest !important;
  }

  .row:last-child [class^="col"],
  .quote {
    border-bottom: 1px solid $gray-lightest !important;
  }

  .author,
  .date {
    font-size: 1.4rem;
  }

  .date {
    bottom: 20px;
    position: absolute;
    width: 265px;
  }

  ul {
    list-style: none;

    li {
      background-color: $white;
      display: inline-block;
      margin-left: 0 !important;
      margin-right: 6px;
      margin-top: 5px;
      width: 385px;

      [class^="col"].clickable {
        border: 1px solid $gray-lightest;
      }
    }
  }

  #filter {
    li {
      background-color: initial;
      width: inherit;
      margin-right: 20px;
      margin-bottom: 15px;
    }
  }
	/* grid */
	#grid {
    clear: both;
    position: relative
  }
}

.template13 {
  .icon-wrapper {
      width: 100px;
  }

  h2 {
    font-size: 2rem;
    line-height: 2rem;
  }

  .row:last-child {
    margin-bottom: 0;
  }
}

.template14 {
  // grid 3 x 3
  .row {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }

  .md_inlb {
    h3 {
      font-size: 2.0rem;
      font-weight: 700;
      line-height: 4.5rem;
    }
    .block {
      margin-bottom: 0;
    }
    &.last {
      .link_row {
        width: 100%;
      }
    }
  }
  .link_row {
    border-top: 1px solid #dddddd;
    padding: 14px 0;
    min-height: 58px;
    width: 90%;
    h4 {
      font-size: 1.7rem;
      font-style: normal;
      font-weight: normal;
      line-height: 1.7rem;
    }

    h4 {
      &.title_linked {
        a {
          color: #0085cc;
          text-decoration: none;
        }
        &.footer {
          display: inline;
          font-size: 1.4rem;
        }
      }
    }
    .pubdate, .field-type-datestamp {
      color: #aaaaaa;
      font-size: 1.4rem;
    }
    .footer {
      a {        
        &:after {
          background: url("../images/arrow-right-light-blue.svg") center center no-repeat;
          content: '';
          display: inline-block;
          height: 1.4rem;
          left: 2px;
          position: relative;
          top: 2px;
          width: 1.4rem;
        }
      }
    }
  }
}

/* Template used for new vertical project */
.template15 {
  .fl {
    float: none;
    display: inline-block;
  }
  .clickable {
    width: 300px;

    &:first-child {
      margin-left: -15px;
      margin-right: 30px;
    }

    &:last-child {
      margin-left: 30px;
      margin-right: -15px;
    }

    &.hover {
      background-color: $white-darker;

      h2 a {
        color: $orange;
        font-weight: 200 !important;
      }
    }
  }

  .box {
    border: 1px solid $gray-lighter;
    padding: 0;

    .image-wrapper img {
      border-bottom: 1px solid $gray-lighter;
      height: 200px;
      width: 100%;
    }

    h2,
    h2 a {
      color: $orange;
      font-size: 1.9rem;
      font-weight: 200;
      margin-top: 5px;
    }

    p {
      font-size: 1.4rem;
      color: $gray-more-dark;
      line-height: 1.8rem;
    }

    h2, p {
       padding: 0 15px;
    }
  }
}

.template16 {
  .template-tooltip {
    background-color: $white;
    border: 1px solid $gray;
    box-shadow: 2px 2px 20px $gray;
    color: $gray-darker;
    display: none;
    font-size: 1.6rem;
    padding: 10px;
    position: absolute;
    left: 50%;
    top: 100%;
    width: 320px;
    z-index: 1;

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      border-left: 20px solid $gray;
      position: absolute;
      left: -1px;
      top: -15px;
    }

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-top: 14px solid transparent;
      border-bottom: 14px solid transparent;
      border-left: 19px solid $white;
      position: absolute;
      left: 0;
      top: -13px;
    }
  }

  [class^="col"] {
    background-color: $white;
  }

  .col3 {
    .template-tooltip {
      left: auto;
      right: 50%;

      &:before {
        border-left: 0;
        border-right: 20px solid $gray;
        left: auto;
        right: -1px;
      }
      &:after {
        border-left: 0;
        border-right: 19px solid $white;
        left: auto;
        right: 0;
        top: -13px;
      }
    }
  }

  ul {
    display: table;
    margin: 0 auto !important;
  }

  li {
    border-bottom: 1px solid $gray;
    border-top: 1px solid $gray;
    display: inline-block;
    margin-left: 0 !important;
    margin-right: 40px;
    min-height: 85px;
    position: relative;
    vertical-align: middle;
    width: 280px;

    &.has-tooltip {
      &:before {
        background-color: $white;
        border-radius: 50%;
        border: 1px solid $gray;
        content: '';
        height: 15px;
        line-height: 1.8rem;
        position: absolute;
        right: 3px;
        text-align: center;
        top: 5px;
        width: 15px;
      }

      &:after {
        color: #e2e2e2;
        content: 'i';
        font-size: 1.2rem;
        position: absolute;
        right: 10px;
        top: 2px;
      }
    }

    &:nth-child(3n+3) {
      margin-right: 0 !important;
      width: 279px;
    }

    > div,
    > div + p {
      display: table-cell;
      line-height: 1.8rem;
      position: relative;
      top: 4px;
      vertical-align: middle;
    }

    > div > i {
      height: 40px;
      margin: 20px;
      width: 40px;
    }

    > div + p {
      color: $orange;
      font-size: 1.6rem;
    }

    &:hover {
      background-color: $white-darker;
      .template-tooltip {
        display: block;
      }
    }
  }
}

// Timeline
.template17 {
  list-style: none;
  padding: 0;
  position: relative;

  &:first-child {
    padding: 20px 0 0 0;
  }

  &:before {
    background-color: $gray-lighter;
    bottom: 0;
    content: " ";
    left: 50%;
    margin-left: -1.5px;
    position: absolute;
    top: 0;
    width: 1px;
  }

  > li {
    margin-left: 0 !important;
    padding-bottom: 10px;
    position: relative;

    &:before,
    &:after {
      content: " ";
      display: table;
    }

    &:after {
      clear: both;
    }

    > .circle {
      background-color: $white;
      border-radius: 50%;
      border: 1px solid $orange;
      height: 20px;
      left: 50%;
      line-height: 20px;
      margin-left: -11px;
      position: absolute;
      top: 16px;
      width: 20px;
      z-index: 100;
    }

    .panel {
      background-color: $white;
      border-radius: 2px;
      border: 1px solid $gray-lighter;
      float: left;
      font-size: 1.6rem;
      margin-top: -15px;
      padding: 15px 15px 0 15px;
      position: relative;
      width: 45%;

      h3 {
        font-size: 1.8rem;
      }

      p {
        padding-top: 5px;
      }

      &:before {
        border-bottom: 15px solid transparent;
        border-left: 15px solid $gray-lighter;
        border-right: 0 solid $gray-lighter;
        border-top: 15px solid transparent;
        content: " ";
        display: inline-block;
        position: absolute;
        right: -15px;
        top: 26px;
      }

      &:after {
        border-bottom: 14px solid transparent;
        border-left: 14px solid #fff;
        border-right: 0 solid #fff;
        border-top: 14px solid transparent;
        content: " ";
        display: inline-block;
        position: absolute;
        right: -14px;
        top: 27px;
      }
    }

    &.inverted > .panel {
      float: right;

      &:before {
        border-left-width: 0;
        border-right-width: 15px;
        left: -15px;
        right: auto;
      }

      &:after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto;
      }
    }
  }
}

// Press download row.
.template18 {
  border-bottom: 1px solid $gray-lightest;
  padding: 15px 0;

  .text {
    font-weight: 500;
    padding-left: 30px;
  }

  .link {
    padding-right: 30px;
  }
}

.template19 {
  img {
    height: 110px !important;
    width: 85px !important;
  }

  h2 {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.8rem;
  }

  p {
    color: $gray-darker;
    font-size: 1.6rem;
    padding: 0;
  }
}

.template20 {
  clear: both;
  margin-bottom: -40px;

  .box {
    background-color: $white;
  }

  .title {
    border-bottom: 1px solid $gray;
    cursor: pointer;
    position: relative;

    h2 {
      color: $blue;
    }

    .arrow-box {
      background-color: $white;
      border: 1px solid $gray;
      height: 50px;
      position: absolute;
      right: 0;
      top: -3px;
      width: 50px;

      .arrow {
        background: url('../images/arrow-down-light-blue.svg') center center no-repeat;
        margin: 10px auto;
        width: 25px;
      }
    }

    &.open {
      .arrow {
        background: url('../images/arrow-up-light-blue.svg') center center no-repeat;
      }
    }
  }

  .content {
    display: none;
  }

  .box {
    background-color: $white;
    border: 1px solid $gray;
    display: table;
    height: 130px;
    padding: 20px;
    width: 460px;

    &.right-box {
      margin-left: 5px;
      margin-right: 0px;
    }

    .text,
    .logo {
      display: table-cell;
    }

    .text {
      text-align: left;
    }

    .logo {
      text-align: center;
      vertical-align: middle;
    }
  }
}

.template20-b {
  background-color: #fff;
  list-style: none;

  li {
    padding: 10px 0;

    &:nth-child(even) {
      background-color: $gray-lightest;
    }
  }

  .title {
    &:before {
      background: url('../images/arrow-down-orange.svg') center center no-repeat;
      content: '';
      display: inline-block;
      height: 20px;
      margin: 0 10px 0 20px;
      width: 20px;
      transition: transform .25s;
    }

    &.open {
      &:before {
        transform: rotate(-90deg)
      }
    }
  }

  li > p.content,
  li > div.content {
    display: none;
    margin-left: 50px;

    & .youtube-player {
      margin-left: 15px;
    }
  }
}

// Edit for template20 in a view block
.view-content .template20 {
  margin-bottom: 50px;

  .content {
    .box {
      &:nth-child(odd) {
        margin-right: 10px;
      }

      float: left;
      margin-bottom: 15px;

      .logo img {
        height: 80px;
        width: 180px;
      }
    }
  }
}

.template21 {
  position: relative;

  &:before,
  &:after {
    content: '';
    height: 70px;
    margin: 0;
    position: absolute;
    top: 15px;
    width: 70px;
  }

  &:before {
    background: url('../images/right-gray.svg') center center no-repeat;
    left: 350px;
  }

  &:after {
    background: url('../images/right-gray.svg') center center no-repeat;
    right: 350px;
  }

  .icon-wrapper {
    background-color: $orange;
    border-radius: 50%;
    height: 100px;
    margin: 0 auto;
    padding-top: 30px;
    width: 100px;

    .icon {
      height: 40px;
    }

    .number {
      font-size: 4.5rem;
      font-family: $tahoma;
      line-height: 3.6rem;
      color: $white;
    }
  }
}

.template21-b {
  position: relative;

  &:before,
  &:after {
    content: '';
    height: 70px;
    margin: 0;
    position: absolute;
    top: 40px;
    width: 70px;
  }

  &:before {
    background: url('../images/right-gray.svg') center center no-repeat;
    left: 270px;
  }

  &:after {
    background: url('../images/right-gray.svg') center center no-repeat;
    right: 270px;
  }

  .img-wrapper img {
    background-color: $white;
    border-radius: 50%;
    height: 145px !important;
    margin: 0 auto;
    width: 145px !important;
  }
}

.template22 {
  .col1 {
    .wrapper {
      float: left;
    }

    .wrapper-text {
      left: 215px;
    }
  }

  .wrapper {
    float: right;
    height: 200px;
    outline: 1px solid $white;
    position: relative;
    width: 450px;

    .wrapper-text {
      background-color: rgba(255, 255, 255, 0.8);
      display: table-cell;
      height: 200px;
      text-align: center;
      position: relative;
      vertical-align: middle;
      width: 235px;
      z-index: 1;

      a {
        color: $black;
      }
    }

    .wrapper-bg-img {
      height: 100%;
      overflow: hidden;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;

      img {
        width: 100%;
      }
    }
  }
}

.template23 {
  position: relative;
  overflow: hidden;
  margin: 0 auto;

  ul {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      display: block;
      float: left;
      margin: 0 !important;
      padding: 0;
      width: 1170px;
      height: 380px;
      text-align: center;

      .txt,
      .img {
        display: table-cell;
        width: 465px;
      }

      .txt {
        color: $white;
        padding: 40px 30px;
        vertical-align: middle;

        .quote {
          font-size: 2.2rem;
          line-height: 3rem;
        }

        .splitter {
          background: $orange;
          height: 1px;
          margin: 30px auto;
          width: 50%;
        }

        .author {
          font-size: 1.6rem;
          font-style: italic;
          margin-bottom: 30px;
        }

        .read-more {
          a {
            color: $white;
            border-bottom: 1px solid $white;
            padding-bottom: 3px;
          }
        }
      }

      .img {
        border-left: 1px solid $white;
        border-right: 1px solid $white;

        img {
          height: 100% !important;
          margin-bottom: -8px;
          width: 100% !important;
        }
      }
    }
  }

  + ul#sliderCircleNav {
    list-style-position: outside;
    list-style-type: none;
    margin-top: 30px;
    text-align: center;

    li {
      background: #fff;
      border-radius: 50%;
      border: 2px solid $white;
      display: inline-block;
      height: 20px;
      margin: 10px;
      text-indent: -9999px;
      transition-duration: 0.15s;
      transition-property: background-color;
      transition-timing-function: ease-in;
      width: 20px;

      &:hover {
        cursor: pointer;
      }

      a {
        display: block;

        &:focus { /** remove blue line in firefox **/
          outline: none;
        }
      }

      &.active {
        background-color: $orange;
      }
    }
  }
}
// Attached to template 23, but added via js autside of element scope.
.sliderNavContainerTemplate23 {
  text-indent: -9999px;
  z-index: 100;

  [id^='sliderArrowNav'] {
    border-radius: 50%;
    cursor: pointer;
    height: 65px;
    left: 50%;
    position: absolute;
    top: 40%;
    width: 65px;
  }

  #sliderArrowNav-prev {
    background: $gray-darkest url("../images/arrow-prev-grey.png") 45% 50% no-repeat;
    margin-left: -55%;
  }

  #sliderArrowNav-next {
    background: $gray-darkest url("../images/arrow-next-grey.png") 55% 50% no-repeat;
    margin-left: 50%;
  }
}

.template25 {
  & > ul {
    & > li {
      float: left;
      list-style: none;
      margin-bottom: 30px;
      margin-left: 0 !important;
      margin-left: 0;
      width: 450px;

      &:nth-child(odd) {
        margin-right: 30px;
      }

      & > .title {
        background: repeating-linear-gradient(35deg, #2d313d, #2d313d 100px, #333745 100px, #333745 400px);
        color: white;
        display: table;
        height: 120px;
        width: 100%;

        & > .icon-cell,
        & > .headline-cell {
          display: table-cell;
          vertical-align: middle;

          .icon {
            height: 65px;
            width: 65px;
          }
        }

        & > .headline-cell h2 {
          color: #fff;
          font-size: 2.2rem;
          font-weight: 200;
          line-height: 2.8rem;
          margin-right: 40px;
        }
      }

      & > .content {
        background-color: #fff;
        font-size: 1.6rem;
        line-height: 2rem;
        margin: 0 auto;
        padding: 0 40px;
        position: relative;

        & > .content-wrapper {
          padding-top: 10px;

          & > .read-more {
            border-top: 1px solid $gray-lightest;
            padding-bottom: 15px;
            padding-top: 10px;
            text-align: left;
            width: 365px;
          }
        }

        li {
          margin-bottom: 20px;
        }
      }
    }
  }
}

.template26 {
  .internal {
    background-color: $white;
    border: 1px solid $gray-more-dark;
    display: table-cell;
    font-size: 1.5rem;
    height: 180px;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 930px;

    .content {
      margin: 0 auto;
      width: 490px;

      p {
        line-height: 2rem;
      }
    }

    &:before {
      content: '+';
      bottom: -8px;
      font-size: 5rem;
      left: 50%;
      margin-left: -5px;
      position: absolute;
      z-index: 1;
    }

    // Added via js, because it needs the bg color of the sectioin.
    .half-circle {
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      border: 1px solid $gray-more-dark;
      border-bottom: 0;
      height: 24px;
      left: 50%;
      margin-left: -10px;
      position: absolute;
      bottom: -1px;
      width: 45px;
    }
  }

  .add-module-info {
    padding: 20px 0;
    text-align: center;
  }

  ul {
    list-style: none;

    li {
      display: inline-block;
      margin-left: 0 !important;
      margin-right: 3px;
      width: 225px;
    }
  }

  .top {
    border-radius: 50%;
    border: 10px solid;
    display: table-cell;
    font-size: 2rem;
    height: 225px;
    line-height: 2.8rem;
    position: relative;
    text-align: center;
    vertical-align: middle;
    vertical-align: middle;
    width: 225px;
    z-index: 1;

    h3 {
      font-size: 2.4rem;
      font-weight: 700;
    }

    .top-text {
      position: relative;
      z-index: 1;
    }

    .bg-img img {
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
    }
  }

  li {
    &:nth-child(1) {
      .top {
        border-color: #1cb195;
      }
    }

    &:nth-child(2) {
      .top {
        border-color: #00b2f3;
      }
    }

    &:nth-child(3) {
      .top {
        border-color: #dc0000;
      }
    }

    &:nth-child(4) {
      .top {
        border-color: #f59300;
      }
    }
  }

  .text {
    background-color: $white;
    font-size: 1.5rem;
    line-height: 1.8rem;
    margin-top: -115px;
    padding: 130px 15px 15px 15px;
    position: relative;
    z-index: 0;

    .read-more {
      a {
        color: $white;
      }

      &:after {
        content: ' \232A';
        font-size: 1.5rem;
        left: 5px;
        position: relative;
        top: 1px;
      }
    }
  }
}

.template27 {
  ul {
    list-style: none;

    li {
      margin-left: 0 !important;
      margin-top: 40px;
      position: relative;
      width: 450px;

      &:nth-child(odd) {
        float: right;
      }

      &:nth-child(even) {
        float: left;
      }

      &:nth-child(1) {
        margin-top: 0;
      }

      &:nth-child(2) {
        margin-top: 250px;
      }

      .img {
        height: 300px;

        img {
          height: 300px;
          margin-bottom: -8px;
          width: 450px;
        }
      }

      .text-wrapper {
        height: 170px;
        overflow: hidden;

        p {
          background-color: $white-gray;
          font-size: 1.5rem;
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      .counter {
        background-color: $white;
        border-radius: 50%;
        border: 2px solid $orange;
        font-size: 3rem;
        height: 55px;
        margin-left: 410px;
        margin-top: -10px;
        padding-top: 14px;
        position: absolute;
        text-align: center;
        width: 55px;
      }

      .arrow-left {
        margin-left: 430px;
        margin-top: -30px;
        position: absolute;
        width: 61px;
        z-index: 1;
      }

      .arrow-right {
        margin-left: -40px;
        margin-top: -30px;
        position: absolute;
        width: 61px;
        z-index: 1;
      }
    }
  }
}

.template29 {
  background-color: $blue-dark-newid;
  border: 1px solid white;
  border-bottom: 0;
  bottom: 0;
  display: none;
  left: 50%;
  margin-left: -240px;
  padding: 20px;
  position: fixed;
  width: 480px;
  z-index: 10;

  .profile-image  {
    margin: 20px;
    padding-right: 30px;
    img {
      border: 2px solid $white;
      border-radius: 50%;
      width: 135px;
    }
  }

  .content,
  h3 {
    color: $white;
  }

  h3 {
    font-size: 1.8rem;
  }

  .text {
    color: $gray-dark;
    font-size: 1.5rem;
  }

  .phone-number {
    font-size: 2.5rem;
  }

  .close {
    color: #000;
    margin-right: 12px;
    position: absolute;
    right: 0;
    top: 5px;

    &:hover {
      cursor: pointer;
    }
  }
}

.template30 {
  display: none;
  position: absolute;
  width: 185px;

  .content {
    background: url("../images/t30-background-grey-pencil-lines.png") 50% 50% no-repeat;
    display: table-cell;
    height: 130px;
    text-align: center;
    padding: 20px 20px 0 20px;
    vertical-align: middle;
    width: 185px;

    h3 {
      font-size: 2rem;
    }

    .text {
      font-family: $cambria;
    }
  }

  .arrow {
    background: url("../images/t30-arrow-orange-cureved.png") 50% 50% no-repeat;
    height: 100px;
    right: 0;
    position: absolute;
    width: 95px;
  }
}

.template32 {
  text-align: center;
  
  ul {
    li.content {
      display: inline-block;
      height: 380px;
      margin: 0 8px;
      position: relative;
      width: 445px;
      overflow:hidden;

      &:nth-child(3n+3) {
        margin-top: 15px;
      }

      figure,
      img {
        height: 380px;
        width: 445px;
        position: relative;
      }

      &:hover .hover {
        display: table;
        top:0;
        transition: top;
        transition-timing-function: ease-out;
        transition-duration: 500ms;
      }

      .hover {
        background-color: $orange;
        display: table;
        height: 380px;
        position: absolute;
        top: -380px;
        width: 445px;
        z-index: 1;
        transition: top;
        transition-timing-function: ease-out;
        transition-duration: 500ms;

        .content {
          color: $white;
          display: table-cell;
          text-align: center;
          padding: 0 60px;
          vertical-align: middle;

          h2 {
            border-bottom: 1px solid white;
            color: $white;
            padding-bottom: 15px;
          }

          .text {
            font-size: 1.8rem;
          }
        }
      }
    }
  }
}

.template33 {
  font-size: 1.8rem;
  position: relative;
  text-align: center;

  .icon-wrapper {
    background-color: $white;
    border-radius: 50%;
    border: 1px solid $orange;
    height: 120px;
    margin: 0 auto;
    padding-top: 30px;
    width: 120px;

    .icon {
      height: 60px;
    }
  }

  h2 {
    padding-bottom: 10px;
    padding-top: 30px;
  }

  .line {
    border-bottom: 1px solid $gray;
    margin: 0 auto;
    width: 80%;
    height: 15px;
  }
}

.template34-wrapper {
  bottom: -40px;
  position: absolute;
  right: 0px;
  z-index: 9;
}

.template34 {
  background-color: $orange;
  border-radius: 50%;
  color: #fff;
  display: table-cell;
  height: 200px;
  padding: 20px;
  text-align: center;
  vertical-align: middle;
  width: 200px;
}

// added possibility to disable auto-collapse
// moved collapse code outside of this block
.template35, .template35-static {
  position: relative;

  ul {
    li {
      float: left;
      list-style: none;
      margin-left: 0 !important;
      width: 50%;
    }
  }

  .title {
    display: table;
    height: 40px;
    width: 100%;

    .icon-cell {
      width: 40px;
    }
  }

  .icon {
    height: 40px;
    width: 40px;
  }

  .icon-cell,
  .headline-cell {
    display: table-cell;
    vertical-align: middle;
  }

  .headline-cell h3 {
    font-family: $open-sans;
    font-size: 1.8rem;
    font-weight: 200;
    line-height: 2.2rem;
  }

  .content {
    font-size: 1.4rem;
    line-height: 2rem;
    margin: 0 auto;
    position: relative;

    .content-wrapper {
      padding-top: 10px;
      padding-right: 20px;
    }
  }
}

// auto-hide
.template35 {
  ul {
    li {
      &:nth-child(5) {
        max-height: 100px;
        overflow: hidden;

        &:before {
          content: '';
          background: linear-gradient(to bottom, rgba(255,255,255,0.4) 0%,rgba(255,255,255,1) 100%);
          display: block;
          height: 100px;
          position: absolute;
          width: 100%;
          z-index: 1;
        }
      }

      // Hide elements from 7th and up. Show/hide.
      &:nth-child(n + 7) {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s linear;
      }
    }
  }

  .bounce {
    cursor: pointer;
    background: transparent url(../images/arrow-down-double.png) center center no-repeat;
    height: 20px;
    left: calc(50% - 28px);
    position: absolute;
    width: 19px;
    z-index: 99;
  }

  &.expanded {
    ul {
      li {
        &:before {
          background: none;
        }
        &:nth-child(n + 7) {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .bounce {
      animation: inherit;
      background: transparent url(../images/arrow-up-double.png) center center no-repeat;
    }
  }
}

.template36 {
  ul {
    margin: 0 auto !important;
  }
}

// Only spin icons in footer.
footer .template36 li {
  transition: 1s;
  transform-style: preserve-3d;
  transition-timing-function: cubic-bezier(0.785, 0.135, 0.150, 0.860);
}

footer .template36 li:hover {
  transform: rotate3d(45, 45, 0, 360deg);
}

.template37 {
  ul {
    list-style: none;
    padding: 0;
    position: relative;
  }

  li > div:first-child {
    border-right: 2px solid $gray;
  }

  h3 {
    font-size: 1.8rem;
  }

  .price {
    font-size: 4.5rem;
  }
}

.template38 {
  list-style: none;
  padding: 0;
  position: relative;

  &:before {
    background-color: $gray-lighter;
    bottom: 0;
    content: " ";
    left: calc(50% - 5px);
    position: absolute;
    top: 0;
    width: 1px;
  }

  > li {
    display: inline-block;
    margin-right: -5px;
    padding: 0;
    position: relative;
    vertical-align: middle;

    &:nth-child(1) {
      vertical-align: top;
      width: 45%;
    }

    &:nth-child(2) {
      background-color: $white;
      height: 45px;
      padding-top: 8px;
      text-align: center;
      width: 10%;
    }

    &:nth-child(3) {
      width: 45%;
    }

    &:before,
    &:after {
      content: " ";
      display: table;
    }

    &:after {
      clear: both;
    }
  }
}

.template39 {
  &.container {
    width: 100%
  }

  .row {
    height: 50px;
    padding-top: 13px;
    padding-bottom: 15px;
  }

  .row div:not(:first-child) {
    text-align: center;
  }

  .tbl-header {
    font-size: 2.2rem;
    color: $white;
  }

  .tbl-sub-header {
    background-color: $gray-newid;
    font-size: 2rem;
  }

  .even {
  }

  .odd {
    background-color: $gray-menu;
  }

  .checkmark {
    background-image: url(../images/checkmark-price-page-m.png);
    background-position: center center;
    background-repeat: no-repeat;
    display: block;
    height: 50px; 
    margin-top: -15px;   
  }

  .btn-container {
    height: 73px;
  }

  .btn-orange-l {
    font-size: 1.6rem;
  }

  .link-buy,
  .link-more {
    font-size: 1.6rem;
  }

  .link-buy {
    color: $orange;
    font-size: 1.7rem;
  }
}

.template40 {
  border: 1px solid $orange;
  border-radius: 10px;
  padding: 5px 15px;
}

.template41 {
  table {
    td {
      padding: 12px 0;
      border-bottom: 1px solid $gray-lighter;
    }
  }
}

.template45 {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  .card {
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 0 1px 0 rgba(0,0,0,0.1), 0 4px 12px 0 rgba(47,51,68,0.15);
    list-style: none;
    margin: 0 25px 55px;
    position: relative;
    width: 300px;
    min-height: 214px;

    &:hover {
      box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1), 0 4px 18px 0 rgba(47, 51, 68, 0.15);
    }

    a {
      color: #000;
      min-height: 214px;
      display: block;
      padding: 30px;
      text-decoration: none;
    }

    .card-text {
      display: block;
      text-align: center;

      strong {
        box-shadow: unset;
      }

    }

    .card-text,
    .icon {
      box-shadow: unset;
    }
  }
}

// NC (Nordic Cool)
.nc-cards-t1 {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  i.icon {
    margin: 0 auto 8px auto;
  }

  & > .card {
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 0 1px 0 rgba(0,0,0,0.1), 0 4px 12px 0 rgba(47,51,68,0.15);
    list-style: none;
    margin: 0 25px 55px;
    position: relative;
    height: 300px;
    width: 240px;
    list-style:none;
    position: relative;

    &:hover {
      box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1), 0 4px 18px 0 rgba(47, 51, 68, 0.15);
    }

    .card-text {
      strong {
        box-shadow: unset;
      }

    }

    & > a:hover,
    .headline,
    .icon {
      box-shadow: unset;
    }
    
  }

  .headline {
    margin-top:10px;
    padding: 0 10px;
    font-size:2rem;
    font-family: $open-sans;
  }

  .cta {
    min-height: 100px;
    padding-top: 32px;

    [class*="btn-"] {
      font-family: "Open Sans Bold";
      font-size:1.6rem;
    }

    a:not([class*="btn-"]) {
      font-size:2.4rem;
      color: #ef7d00;
      position: relative;
      top:8px;
    }    
  }

  .text {
    padding: 10px
  }

  &.nc-cards-v2 > .card {
    height: 360px;
  }

  &.nc-cards-v2 .cta {
    min-height: unset;
    padding: 10px 0;
  }

  &.nc-cards-v2,
  &.nc-cards-v3 {
    .text {
      min-height: 150px;
      padding-top: 10px;
    }

    & [class*="btn-"] {
      background-color: #fff !important;
      color: #ef7d00 !important;
      border:1px solid #ef7d00;
      padding: 10px 40px;
    }
  }

  &.nc-cards-v3 > .card {
    height: 400px;
    margin: 0 15px 25px;
  }

  &.nc-cards-v3 .cta {
    min-height: unset;
    padding: 0;

    [class*="btn-"] {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  &.nc-cards-v4 > .card {
    height: 135px;

    a {
      display: block;
      margin-top: -10px;
      padding-bottom: 20px;
      padding-top: 15px;
      text-decoration: none;
    }

    .headline {
      line-height: 1;
    }
  }
}
// Horizontal line faded in both ends.
.nc-line1 {
  background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.75) 25%,rgba(0,0,0,1) 50%,rgba(0,0,0,0.75) 75%,rgba(0,0,0,0) 100%);    
  height: 1px;
  margin: 30px 0;
}

// Support tabs
.nc-tabs-content {
  .support-list-tabs {
    list-style: none;
    text-align: center;

    li {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      margin: 0 2px;
    }  
  }
  .support-tab {
    font-family: "Open Sans Bold";
    text-align: center;
    color: #ef7d00;
    width: 270px;
    height: 38px;
    box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.16);
    background-image: linear-gradient(to bottom, #fff, #fff 46%, #ccc);
    transition: all .1s;
    cursor: pointer;
  }
  .support-tab-active {
    color: #fff;
    box-shadow: 0 -3px 6px 0 rgba(51, 51, 51, 0.4);
    background-image: linear-gradient(to bottom, #f2a900, #ed7e23);
  }
  .support-content {
    display: none;
  }
}

// Fade in from left
.template-hero1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;  
}

// Make sure that we can assign a class to a section, and make it full-width, flush left-to-right with other non-section content.
.full-width {
  margin-left: -15px;
  margin-right: -15px;
}

.round-image {
  border-radius: 50%;
}

.clickable {
  cursor: pointer;
}

.lol-trust li quote:before {
  top: 140px !important;
}

.youtube-player {
  position: relative;
  padding-bottom: 56.23%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #F6F6F6;
  margin: 5px;
  box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.2);

  & iframe,
  & object,
  & embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: transparent;
  }

  .youtube-thumb {
    bottom: 0;
    display: block;
    left: 0;
    margin: auto;
    max-width: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    height: 135%;
    -webkit-transition: .4s all;
    -moz-transition: .4s all;
    transition: .4s all;
  }



  &.playable{
    background-color: #000;
    .youtube-thumb {
      cursor: pointer;
      &:hover {
        -webkit-filter: brightness(75%);
      }
    }
  }

  .play {
    height: 72px;
    width: 72px;
    left: 50%;
    top: 50%;
    margin-left: -36px;
    margin-top: -36px;
    position: absolute;
    background: url("../images/video-play.svg") no-repeat;
    cursor: pointer;

    transition: all .1s ease-in-out;

    &:hover {
      transform: scale(0.9);
    }
  }

  .youtube-player__cookie-wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  
    .youtube-player__cookie-consent {
      height: 166px;
      width: 280px;
      margin-top: -83px;
      margin-left: -140px;    
      left: 50%;
      top: 50%;
      padding: 10px;
      text-align: center;
      position: absolute;
      font-weight: normal;
      font-size: 1.6rem;
      line-height: 22px;
    
      a{
        font-weight: 600;
        color: #2467F6;
        cursor: pointer;
      }
  
      .cookie-consent__img{
        display: inline-block;
        height: 32px;
        width: 42px; 
        box-shadow: none;   
      }
    }
  }
}

.youtube-player.maxres{
  .youtube-thumb {
    height: 100%;
  }
}

.template-x1 {
  h2, p {
    margin: 0 auto;
    margin-bottom: 18px;
  }
  h2 {
    width: 820px;
  }
  p {
    margin-bottom: 28px;
    width: 700px;
  }
  img {
    width:100%
  }
}

.template-modal-a {
  .price-modal {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      background-color: #fff;
      text-align:left;
      border-radius: 9px;
      width: 552px;
    }

    picture img {
      height: 215px;
    }

    .content h2 {
      font-size: 30px;
      font-weight: 600;
      padding: 10px 40px;
    }

    p {
      padding-left: 40px;
      padding-right: 40px;
    }

    .dots { 
      background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='15' height='10'><rect style='fill:%23ced3df' x='8' y='1' width='2' height='1'/></svg>") repeat-x;
      background-position-y: 10px;
    }
  
    .posts {
      width: 400px;
      display: inline-block;
      margin-right: 30px;
    }

    .txt-bg {
      background-color: #fff;
      display: block;
      width: 50px;
    }  

    .footnote--header{
      font-weight: bold;
      margin-top: 30px;
      padding-bottom: 0;
    }

    .footnote--heade__info{
      margin-top: 0;
    }

    .footnote--marknote{
      margin-bottom:5px; 
      padding-bottom:0;
    }

    .footnote {
      text-align: left;
      padding-top: 0;
    }

    .footer {
      min-height: 125px;
      display: flex;
      align-items: center;
      background-color:#F6F6F6;
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
      padding: 0 60px;
      position: absolute;
      bottom: 0;

      a {
        color: #30A1FF;
        display: contents;
        font-weight: 700;
      }
    }    
  }
  
  .price-modal-list {
    margin-bottom: 30px;
    
    ul {
      list-style: none;
    }

    li {
      font-weight: 600;
      margin: 10px 0;

      &:nth-child(1) .txt-bg {
        width: 60px;
      }

      &:nth-child(2) .txt-bg,
      &:nth-child(3) .txt-bg,
      &:nth-child(4) .txt-bg {
        width: 80px;
      }

      &:nth-child(5) .txt-bg {
        width: 100px;
      }
    }
  } 
}
.order-block-dialog__modal-body{
  .template-modal-a .price-modal .content {
    width: 100%
  }
}


.video_420{
  width: 420px;
}

.video_500{
  width: 500px;
}

.video_560{
  width: 560px;
}

.video_640{
  width: 640px;
}

.video_700{
  width: 700px;
}

.video_900{
  width: 900px;
}

.pricing-table{
  display: flex;
  flex-direction: column;
  align-items: center;

  table{
    border: 0;

    tr:first-child{
      background-color: #F9F9F9;
    }

    td{
      height: 73px;
      width: 169px;
      text-align: center;
      padding: 8px;
      font-size: 14px;
      border: 1px solid #DDD;

      &:first-child{
        width: 128px;
      }
    }

  }
}

.info-dialog__content{
  display: none;
}

.cke_editable{
  .info-dialog__content{
    display: block;
  }
}

.info-dialog{
  display: inline-block;
}

.info-dialog__action{
    line-height: 1.4rem;
    display: inline-block;
    cursor: pointer;
    height: 14px;
    width: 14px;
    background-position: center center;
    background-repeat: no-repeat;
    margin: 0 auto; 
    background-image: url(../images/info-ital.svg); 
}

.cke_show_blocks div:not([contenteditable=false]):not(.cke_show_blocks_off){
  .info-dialog__action{
    background-image: url(../images/info-ital.svg);
  }
}

// Timeline
.timeline1{
  font-family: 'Open Sans', sans-serif;
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 1fr);
  margin: auto;
  height: 250px;
  padding: 0 5vw;
}

.timeline1::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  margin-top: -1px;
  top: 50%;
  background: #EF7D00;
}

.timeline1::after {
  content: '';
  position: absolute;
  right: 2px;
  top: 120px;
  border: solid #EF7D00;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.timeline1__point::before{
  content: '';
  position: absolute;
  width: 1px;
  height: 15px;
  background-color: #EF7D00;
  margin-top:  110px;
}

.timeline1__point:nth-child(2n){
  margin-top: 165px;
}

.timeline1__point:nth-child(2n)::before{
  margin-top: -40px;
}

.timeline1__point-header{
  overflow: visible;
  white-space: nowrap;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
}

.timeline1__point-content{
  margin-top: 8px;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
}

.timeline1__download{
  cursor: pointer;
}

@mixin timeline1-email() {  
  .timeline1{
    padding: 0 1vw;
  }
  .timeline1__point-header,
  .timeline1__point-content{
    width: 230px;
  }
  .timeline1__point-header{
    white-space: unset;
  }
  .timeline1__point:nth-child(2n){
    .timeline1__point-header,
    .timeline1__point-content{
      margin-left: -80px;
    }
  }
}

@media (max-width: 1000px) {
  @include timeline1-email();
}

.timeline1__wrapper--email{
  @include timeline1-email();
    width: 600px;
    height: 500px;
    display: grid;

  .timeline1{
    align-self: center;
  }
}

@media (max-width: 599px){
  .timeline1{
    grid-auto-flow: row;
    height: unset;
    padding: 10px 0;
  }
  
  .timeline1::before {
    content: '';
    width: 2px;
    height: 100%;
    margin-left: -1px;
    top: 0;
    left: 50%;
  }
  
  .timeline1::after {
    content: '';
    position: absolute;
    right: 50%;
    bottom: 0;
    top: unset;
    margin-right: -5px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  
  .timeline1__point{
    position: relative;
    margin-left: calc(50% + 18px);
  }

  .timeline1__point .timeline1__point-header,
  .timeline1__point .timeline1__point-content{
    white-space: unset;
    width: 100%;
  }
  
  .timeline1__point::before{
    content: '';
    position: absolute;
    left: -18px;
    top: 5px;
    width: 8px;
    height: 1px;
    margin-top:  unset;
  }
    
  .timeline1__point:nth-child(2n){
    margin-top: 0;
    margin-left: 0;
    padding-left: 0;
    text-align: right;
  }

  .timeline1__point:nth-child(2n)::before{
    right: 50%;
    left: unset; 
    margin-top: 0;
  }

  .timeline1__point:nth-child(2n) .timeline1__point-header,
  .timeline1__point:nth-child(2n) .timeline1__point-content{
    width: calc(50% - 18px);
  }

  .timeline1__download{
    display: none;
  }
}
