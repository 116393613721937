/*
base
================

Documentation
-----------------------
base styles &
[smacss: base](https://smacss.com/book/type-base)

Reset html & body
-------------------------------
Body is set to 62.5 to force the font sizing to be easy to be calculated with
1em = 10px - body margin & padding is resetted
*/
html {
  -ms-text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: #f3f5f6;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  font-size: 62.5%; // 1em = 10px
  overflow-x: hidden;
}

body {
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  width: 100%;

  // Class to hide section on desktop
  .hide-on-desktop {
    display: none;
  }

}

.wrap,
%wrap {
  margin: 0 auto;
  max-width: $wrap-page-width;
}
/*
boxsizing
-------------
*/

* {
  box-sizing: border-box;
  word-wrap: break-word;
}

/*
rulers
===================
*/

hr {
  border-top: 1px solid $gray-lightest;
}

#main {
  clear: both
}


/*
List styles
===================
*/
ul, ol {
  list-style-position: outside;
}


/*
General definitions
===================
*/

.fl {
  float: left;
}

.fr {
  float: right;
}

/* Font weight */
.fw3 {
  font-weight: 300;
}
.fw4 {
  font-weight: normal;
}

.fw7,
%fw7 {
  font-weight: 700;
}

/* Text align */
.md_txtl,
.txtl,
%md_txtl {
  text-align: left
}
.md_txtc,
.txtc,
%md_txtc {
  text-align: center
}
.md_txtr,
.txtr,
%md_txtr {
  text-align: right
}
.txtu {
  text-decoration: underline;
}
.md_elmvt {
  vertical-align: top;
}
.md_elmvm {
  vertical-align: middle;
}
.md_elmvb {
  vertical-align: bottom;
}

.md_txtdn, .md_txtdn:hover {
  text-decoration: none
}
.md_txtlc {
  text-transform: lowercase;
}
.nowrap {
  white-space: nowrap;
}

/* Display */
.md_blk {
  display: block !important;
}
.md_blkn {
  display: none
}
.md_inl, .md_inl li {
  display: inline
}
.md_inlb, .md_inlb li {
  display: inline-block
}
.md_inl, .md_inl li {
  display: inline
}
.md_tbl {
  display: table;
}
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-column {
  flex-direction: column;
}
.flex-column-reverse {
  flex-direction: column-reverse;
}
.md_vis {
  visibility: visible
}
.md_vish {
  visibility: hidden
}

.md_tbl_cell_middle {
  display: table-cell;
  vertical-align: middle;
}

/* Miscellaneous */
.md_center {
  margin: 0 auto;
}
.md_overflow {
  overflow: hidden;
}
.md_cb {
  clear: both;
}

.md_opacity_1 {opacity:0.1}
.md_opacity_2 {opacity:0.2}
.md_opacity_3 {opacity:0.3}
.md_opacity_4 {opacity:0.4}
.md_opacity_5 {opacity:0.5}
.md_opacity_6 {opacity:0.6}
.md_opacity_7 {opacity:0.7}
.md_opacity_8 {opacity:0.8}
.md_opacity_9 {opacity:0.9}

/* Spacing */
/* Spacing helpers
p,m = padding,margin
a,t,r,b,l,h,v = all,top,right,bottom,left,horizontal,vertical
t,s,m,l,n = tiny(5px),small(10px),medium(20px),large(30px),extra(60px),none(0px)
*/
.ptn, .pvn, .pan {
  padding-top: 0;
}
.ptt, .pvt, .pat {
  padding-top: 5px;
}
.pts, .pvs, .pas {
  padding-top: 10px;
}
.ptm, .pvm, .pam {
  padding-top: 20px;
}
.ptl, .pvl, .pal {
  padding-top: 30px;
}
.pte, .pve, .pae {
  padding-top: 50px;
}
.prn, .phn, .pan {
  padding-right: 0;
}
.prt, .pht, .pat {
  padding-right: 5px;
}
.prs, .phs, .pas {
  padding-right: 10px;
}
.prm, .phm, .pam {
  padding-right: 20px;
}
.prl, .phl, .pal {
  padding-right: 30px;
}
.pre, .phe, .pae {
  padding-right: 50px;
}
.pbn, .pvn, .pan {
  padding-bottom: 0;
}
.pbt, .pvt, .pat {
  padding-bottom: 5px;
}
.pbs, .pvs, .pas {
  padding-bottom: 10px;
}
.pbm, .pvm, .pam {
  padding-bottom: 20px;
}
.pbl, .pvl, .pal {
  padding-bottom: 30px;
}
.pblh, .pvlh, .palh {
  padding-bottom: 35px;
}
.pbe, .pve, .pae {
  padding-bottom: 50px;
}
.pln, .phn, .pan {
  padding-left: 0;
}
.plt, .pht, .pat {
  padding-left: 5px;
}
.pls, .phs, .pas {
  padding-left: 10px;
}
.plm, .phm, .pam {
  padding-left: 20px;
}
.pll, .phl, .pal {
  padding-left: 30px;
}
.ple, .phe, .pae {
  padding-left: 50px;
}
.mttn, .mvtn, .matn {
  margin-top: -5px;
}
.mtsn, .mvsn, .masn {
  margin-top: -10px;
}
.mtln, .mvln, .maln {
  margin-top: -30px;
}
.mtn, .mvn, .man {
  margin-top: 0;
}
.mtt, .mvt, .mat {
  margin-top: 5px;
}
.mts, .mvs, .mas {
  margin-top: 10px;
}
.mtm, .mvm, .mam {
  margin-top: 20px;
}
.mtl, .mvl, .mal {
  margin-top: 30px;
}
.mtu, .mvu, .mau {
  margin-top: 40px;
}
.mte, .mve, .mae {
  margin-top: 60px;
}
.mtes, .mves, .maes {
  margin-top: 70px;
}
.mrtn, .mhtn, .matn {
  margin-right: -5px;
}
.mrsn, .mhsn, .masn {
  margin-right: -10px;
}
.mrln, .mhln, .maln {
  margin-right: -30px;
}
.mrn, .mhn, .man {
  margin-right: 0;
}
.mrt, .mht, .mat {
  margin-right: 5px;
}
.mrs, .mhs, .mas {
  margin-right: 10px;
}
.mrm, .mhm, .mam {
  margin-right: 20px;
}
.mrl, .mhl, .mal {
  margin-right: 30px;
}
.mre, .mhe, .mae {
  margin-right: 60px;
}
.mbtn, .mvtn, .matn {
  margin-bottom: -5px;
}
.mbsn, .mvsn, .masn {
  margin-bottom: -10px;
}
.mbln, .mvln, .maln {
  margin-bottom: -30px;
}
.mbn, .mvn, .man {
  margin-bottom: 0;
}
.mbt, .mvt, .mat {
  margin-bottom: 5px;
}
.mbs, .mvs, .mas {
  margin-bottom: 10px;
}
.mbm, .mvm, .mam {
  margin-bottom: 20px;
}
.mbl, .mvl, .mal {
  margin-bottom: 30px;
}
.mbe, .mve, .mae {
  margin-bottom: 60px;
}
.mltn, .mhtn, .matn {
  margin-left: -5px;
}
.mlsn, .mhsn, .masn {
  margin-left: -10px;
}
.mlln, .mhln, .maln {
  margin-left: -30px;
}
.mla{
  margin-left: auto;
}
.mln, .mhn, .man {
  margin-left: 0;
}
.mlt, .mht, .mat {
  margin-left: 5px;
}
.mls, .mhs, .mas {
  margin-left: 10px;
}
.mlm, .mhm, .mam {
  margin-left: 20px;
}
.mll, .mhl, .mal {
  margin-left: 30px;
}
.mle, .mhe, .mae {
  margin-left: 60px;
}

/* Font size */
.fs1 {
  font-size: 50px;
}
.fs2 {
  font-size: 40px;
}
.fs3 {
  font-size: 30px;
}
.fs4 {
  font-size: 24px;
}
.fs5 {
  font-size: 18px;
}
.fs6 {
  font-size: 16px;
}
.fs7 {
  font-size: 15px;
}
.fs8 {
  font-size: 14px;
}
.fs9 {
  font-size: 13px;
}

/* Font colors */
.fc1 {
  color: $white;
}
.fc2 {
  color: $black !important;
}
.fc-orange-light {
  color: $orange-light;
}
.fc-orange{
  color: $orange;
}

/* Background colors */
.bgc1 {
  background-color: $red-dark;
}

.bgc2 {
  background-color: $gray-newid;
}

.bgc3 {
  background-color: $blue-dark-newid;
}

.bgc4 {
  background-color: $orange;
}

.bgc5 {
  background-color: $white;
}

.bgfix {
  background-attachment: fixed;
}

/** column dividers **/
.s1of2 {
  width: 50%;
}
.s1of3 {
  width: 33.33333%;
}
.s2of3 {
  width: 66.66666%;
}
.s1of4 {
  width: 25%;
}
.s1of5 {
  width: 20%;
}
.s3of10 {
  width: 30%;
}
.s3of20 {
  width: 15%;
}
.s2of5 {
  width: 40%;
}
.s3of5 {
  width: 60%;
}
.s3of4 {
  width: 75%;
}
.s4of5 {
  width: 80%;
}
.s100 {
  width: 100%;
}
.s100px {
  width: 100px;
}
.h100 {
  height: 100%;
}
%circle {
  border-radius: 50%;
}

.pos-rel {position: relative;}
.pos-abs {position: absolute;}

// Sprite is defined in _init.scss.
.sprite,
.sprite-before::before {
  background: transparent url($sprite) 0 0 no-repeat;
}

// Spinner
@keyframes spinner-small {
  to {transform: rotate(360deg);}
}

.spinner-small:before {
  animation: spinner-small .6s linear infinite;
  border-radius: 50%;
  border-right: 2px solid transparent;
  border-top: 2px solid $orange;
  box-sizing: border-box;
  content: '';
  height: 20px;
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
  position: absolute;
  top: 50%;
  width: 20px;
  z-index: 1;
}

// no outline on tabindex -1
[tabindex="-1"]{
  outline:0
}

//cursors
.cp {
  cursor: pointer;
}

// Owl carusel "unable to preventDefault inside passive event listener due to target being treated as passive" fix
.owl-carousel {
    touch-action: manipulation;
}
