body.section-developer .view-newsmail .view-filters-custom .filters {
  display: none;
}

.view-newsmail {
  margin-left: -15px;
  margin-right: -15px;

  .view-content {
    margin-left: -15px;
    margin-right: -15px;

    &:before,
    &:after {
      content: " ";
      display: table;
    }

    &:after {
      clear: both;
    }
  }

  .view-filters {
    form {
      display: none;
    }
  }

  .view-filters-custom {
    border-bottom: 1px solid $gray-dark-original;
    margin: 0px 0px 30px 0px;
    padding-bottom: 20px;

    ul {
      display: inline;
      color: $nobel;

      li {
        float: left;
        list-style-type: none;
        list-style-image: none;
        margin-right: 10px;


        &.idle {
          a {
            position: relative;
            color: $nobel;

            &:hover {
              color: $mango-tango;
            }

            &.spinner-small {
              color: $gray-lighter;
            }
          }
        }

        &:not(:first-child):before {
          content: "|";
          margin-right: 10px;
        }

        &:nth-child(2):before {
          color: $nobel;
        }

        &.active {
          color: $mango-tango;

          &:before {
            color: $nobel;
            font-weight: 300;
          }
        }

        &.next-to-active {
          &:before {
            color: $nobel;
            font-weight: 300;
          }
        }
      }

      &:after {
        content: " ";
        display: table;
        clear: both;
      }
    }

    .filters {
      float: left;
    }

    .orders {
      float: right;
      color: $nobel;

      .icon {
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.35 7.23"><defs><style>.cls-1,.cls-3{fill:none;}.cls-2{clip-path:url(#clip-path);}.cls-3{stroke:#9A9A9A;stroke-width:1.5px;}</style><clipPath id="clip-path"><path class="cls-1" d="M0 0h12.35v7.23h-12.35z"/></clipPath></defs><g class="cls-2"><path class="cls-3" d="M5.64 6.7l6.18-6.17M.53.53l6.17 6.17"/></g></svg>') 2px 5px no-repeat;
        float: left;
        margin-right: 5px;
        background-size: 16px 16px;
        display: block;
        height: 22px;
        text-align: center;
        width: 22px;
      }

      .desc {
        position: relative;

        .icon {
          background-position-y: 2px;
          transform: scaleX(-1) scaleY(-1);
        }

        &.spinner-small {
          opacity: 0.5;
        }
      }

      .asc {
        position: relative;

        &.spinner-small {
          opacity: 0.5;
        }
      }
    }

    &:after {
      content: " ";
      display: table;
      clear: both;
    }
  }

  .pager-wrapper {
    border-top: 1px solid $gray-dark-original;
    margin: 0;

    .pager {
      margin-top: 15px;
    }
  }

  .region-overlay {
    background-color: $white;
    position: absolute;
    display: flex;
    align-items: center;
    opacity: 0.2;
    top: 0;
    z-index: 95;
  }
}

.node-newsmail {
  &.node-teaser {
    border: 1px solid $gray-lighter;

    &:hover {
      box-shadow: 4px 4px 13px 0 rgba(66, 63, 63, 0.5);
      transform: scale(1.02, 1.02);
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;
    }

    .newsmail-teaser-body {
      padding: 40px 18px 18px 11px;
    }

    .pane-node-field-newsmail-header-image {
      height: 110px;

      img {
        width: 100%;
        object-fit: cover;
      }

      &.tall {
        img {
          height: 110px;
        }
      }
    }

    .header-date-tag {
      background-color: $gray-lighter;
      color: $mango-tango;
      height: 25px;
    }

    .pane-node-field-newsmail-date-stamp {
      float: left;
      font-size: 1.2rem;
      margin-left: 15px;
    }

    .pane-node-field-newsmail-tags {
      float: right;
      font-size: 1.2rem;
      overflow: hidden;

      span {
        text-decoration: none;
        margin-right: 5px;
        border-right: 1px solid $mango-tango;
        border-left: 1px solid $mango-tango;
        padding-left: 5px;
        padding-right: 5px;
      }

      &:hover {
        overflow: visible;
        white-space: normal;
      }
    }

    .newsmail-teaser-body {
      h4 {
        font-size: 1.6rem;
        line-height: 1.4;
        color: $mango-tango;
        margin-top: 10px;
        margin-bottom: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .pane-node-field-newsmail-abstract {
      $font-size: 1.4rem;
      $line-height: 1.3;
      $lines-to-show: 6;

      color: $blue-dark-newid;
      display: block;
      /* Fallback for non-webkit */
      display: -webkit-box;
      height: $font-size*$line-height*$lines-to-show;
      /* Fallback for non-webkit */
      margin: 0 auto;
      font-size: $font-size;
      line-height: $line-height;
      -webkit-line-clamp: $lines-to-show;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .pane-node-field-newsmail-link-to-page {
      background-color: $gray-lighter;
      font-size: 1.2rem;
      padding-left: 15px;
      position: relative;

      .go-icon {
        display: inline-block;
        background-color: $mango-tango;
        border-radius: 50%;
        height: 15px;
        width: 15px;
        margin-top: -8px;
        top: 50%;
        position: absolute;

        &::after {
          color: $white;
          position: relative;
          content: '\0203A';
          top: -7px;
          right: -6px;
          font-size: 2rem;
        }
      }

      a {
        color: $mango-tango;
        margin-left: 20px;
      }
    }
  }
}

// Eloqua email styles
.node-type-newsmail .field-name-body {

  // style 1
  #outlook a {
    padding: 0
  }

  .ExternalClass {
    width: 100%
  }

  .ExternalClass,
  .ExternalClass div,
  .ExternalClass font,
  .ExternalClass p,
  .ExternalClass span,
  .ExternalClass th {
    line-height: 100%
  }

  .background-table {
    margin: 0;
    padding: 0;
    width: 100% !important;
    min-width: 100% !important;
    line-height: 100% !important
  }

  img {
    outline: 0;
    text-decoration: none;
    -ms-interpolation-mode: bicubic;
    display: block
  }

  a img {
    border: none
  }

  //style 2
  table {
    border-collapse: collapse;
    mso-table-lspace: 0;
    mso-table-rspace: 0
  }

  th {
    border-collapse: collapse
  }

  //style 3
  background: #DDD;

  a {
    color: #AAA;
    text-decoration: none
  }

  ul {
    Margin: 0;
    padding: 0
  }

  li {
    Margin: 0 0 0 20px;
    mso-special-format: bullet
  }

  .text-orange {
    color: #E47D00
  }

  .signature-image {
    padding: 10px 0
  }
}

@media screen and (device-width:768px) and (device-height:1024px) {
  .node-type-newsmail .field-name-body {
    min-width: 701px !important
  }
}

// Fix bootstrap width
@media (min-width: 992px) {
  .view-newsmail {
    .col-md-4 {
      width: 33.3%;
    }
  }
}

body.node-type-newsmail table.email-content {
  background-color: #fff
}
