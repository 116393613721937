.view-newsmail {
  margin-left: -10px;
  margin-right: -10px;

  .view-content {
    margin-left: -10px;
    margin-right: -10px;
  }

  .view-filters-custom {
    margin: 0px 0px 20px 0px;
    padding-bottom: 13px;

    ul {
      li {
        margin-right: 7px;

        &:not(:first-child):before {
          margin-right: 7px;
        }
      }
    }

    .orders {
      .icon {
        margin-right: 3px;
        background-size: 10px 10px;
        height: 14px;
        width: 14px;
      }
    }
  }

  .pager-wrapper {
    .pager {
      margin-top: 10px;
    }
  }
}

.node-newsmail {
  &.node-teaser {
    &:hover {
      box-shadow: 3px 3px 8px 0 rgba(66, 63, 63, 0.5);
    }

    .newsmail-teaser-body {
      padding: 7px 12px 12px 7px;
    }

    .pane-node-field-newsmail-header-image {
      height: 72px;

      &.tall {
        img {
          height: 72px;
        }
      }
    }

    .header-date-tag {
      height: 16px;
    }

    .pane-node-field-newsmail-date-stamp {
      font-size: 0.8rem;
      margin-left: 10px;
    }

    .pane-node-field-newsmail-tags {
      font-size: 0.8rem;

      span {
        margin-right: 3px;
        padding-left: 3px;
        padding-right: 3px;
      }
    }

    .newsmail-teaser-body {
      h4 {
        font-size: 1rem;
        line-height: 1.4;
        margin-top: 7px;
        margin-bottom: 7px;
      }
    }

    .pane-node-field-newsmail-abstract {
      $font-size: 0.9rem;
      $line-height: 1.3;
      $lines-to-show: 6;

      height: $font-size*$line-height*$lines-to-show;
      /* Fallback for non-webkit */
      font-size: $font-size;
      line-height: $line-height;
      -webkit-line-clamp: $lines-to-show;
    }

    .pane-node-field-newsmail-link-to-page {
      font-size: 0.8rem;
      padding-left: 10px;

      .go-icon {
        height: 10px;
        width: 10px;
        margin-top: -5px;

        &::after {
          top: -5px;
          right: -4px;
          font-size: 1.3rem;
        }
      }

      a {
        margin-left: 13px;
      }
    }
  }
}

.node-type-newsmail .field-name-body {
  li {
    Margin: 0 0 0 13px;
  }

  .signature-image {
    padding: 7px 0
  }
}


.node-type-newsmail {
  .field-name-body {
    .background-table.has-width-640 .email-content {
      width: 100% !important
    }

    .background-table.has-width-640 img {
      max-width: 100%;
      height: auto
    }

    .background-table.has-width-640 .mobile-display-block {
      display: block;
      width: 100%
    }

    .background-table.has-width-640 .mobile-display-none {
      display: none
    }

    .background-table.has-width-640 .mobile-display-table-caption {
      display: table-caption !important
    }

    .background-table.has-width-640 .mobile-display-table-header-group {
      display: table-header-group !important
    }

    .background-table.has-width-640 .mobile-display-table-row {
      display: table-row !important
    }

    .background-table.has-width-640 .mobile-display-table-footer-group {
      display: table-footer-group !important
    }

    .background-table.has-width-640 .mobile-width-auto {
      width: auto !important
    }
  }
}

.html {
  .node-newsmail {
    .header-date-tag {
      height: 37px !important;

      .pane-node-field-newsmail-date-stamp {
        margin-top: 7px !important;
      }

      .pane-node-field-newsmail-tags {
        margin-top: 8px !important;
      }

      .date-display-single,
      .pane-node-field-newsmail-tags span {
        font-size: 1.6rem;
      }
    }

    .pane-node-field-newsmail-header-image {
      top: -13px;
      position: relative;
    }

    .pane-node-field-newsmail-link-to-page {
      padding-top: 5px;
      margin-top: initial !important;

      &::before {
        height: 20px;
        width: 20px;
      }

      &:after {
        left: -165px;
        top: 1px;
        font-size: 2rem;
      }
    }
  }
}

.block .view-newsmail {
  margin-top: 20px;
  width: 89%;

  .node-newsmail.node-teaser {
    .pane-node-field-newsmail-header-image {
      height: auto;

      img {
        height: auto;
        object-fit: unset;
      }
    }

    .pane-node-field-newsmail-date-stamp {
      font-size: 0.8rem;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .newsmail-teaser-body {
      h4 {
        font-size: 2rem;
      }
    }

    .pane-node-field-newsmail-abstract {
      margin-top: 20px;
      margin-bottom: 20px;
      height: auto;
    }

    .pane-node-field-newsmail-tags {
      margin-top: 0;
    }

    .pane-node-field-newsmail-link-to-page {
      margin-top: 65px;
    }
  }
}

body.node-type-newsmail table.email-content {
  width:100%!important
}