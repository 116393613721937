.client-form--webform {

  // Special stying of the HTML block to fit the checkbox label align and look
  // http://local.e-conomic.dk/revisor-og-bogholder-registrering-dk
  &.webform-client-form-4890 {
    .webform-component--oplysningspligt-obligatorisk {
      margin-left: 28px;
      font-size: 1.4rem;
    }
  }
}