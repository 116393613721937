#navigation {
  .block {
    max-width: 100%
  }
}
.menu-name-menu-header-menu:not(.menu-level-2) li:nth-child(1) {
  padding-right: 20px !important;
}
.menu-name-menu-header-menu:not(.menu-level-2) li {
  padding-left: 5px !important;
}