.instagram-social-feed {
  text-align: center;

  img {
    width: 300px;
    height: 300px;
  }

  ul {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    margin: 40px 0;
    padding: 0;

    li {
      display: block;
      flex: 0 1 auto;
      list-style-type: none;
    }
  }

  .social-feed-link {
    @extend .btn-orange-solid;
    @extend [class^="btn-"];
    @extend .btn-ve;

    margin-top: 60px;
  }
}