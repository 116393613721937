.site_app_login {

  // Site App Login system messages in general.
  &.system_message {
    border: 1px solid $gray-lighter;
    clear: both;
    display: block;
    float: left;
    padding: 2rem;
    text-align: center;
    width: 100%;

    p,
    div,
    span,
    h4 {
      margin: 0;
      padding-top: 0;
      position: relative;
    }

    h4 {
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  // Message severity classes: .notice and .critical
  &.notice {
    background: #FFFFAA;
    color: $black;
  }

  &.critical {
    background: #D90000 !important;

    a,
    h4,
    p {
      color: white !important;
    }
  }
}

.client-form--login {
  @extend .client-form--left;
  position: relative;

  .client-form__intro{
    width: 480px;
  }
  
  // the custom error tooltip
  .form-error {
    background-color: $white-dark;
    border: 1px solid $gray-lighter;
    box-shadow: 2px 2px 20px $gray;
    margin-left: 93%;
    position: absolute;
    top: 14px;
    width: 80%;
    z-index: 10;

    .form-error-content {
      padding: 20px;
    }

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-top: 40px solid $gray;
      border-right: 40px solid transparent;
      border-left: 40px solid transparent;
      position: absolute;
      left: -41px;
      top: -1px;
      z-index: 1;
    }

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-top: 40px solid $white;
      border-right: 40px solid transparent;
      border-left: 40px solid transparent;
      position: absolute;
      left: -39px;
      top: 0;
      z-index: 2;
    }

    .site-app-login-form-error-close {
      cursor: pointer;
      margin: 10px 10px 0 0;
    }
  }

  
  // special link in a custom position
  .forgot-your-login {
    float: right;
    position: relative;
    margin-top: -35px;
  }
  
  // special margin due to a special link
  .form-submit {
    margin-top: 15px;
  }
}

.client-form--login__center {
  @extend .client-form--center;

  .client-form__intro{
    width: auto
  }

  .form-error {
    margin-left: 83%;
    width: 40%;
  }
}