/**
 * @file
 * Styles are organized using the SMACSS technique. @see http://smacss.com/book/
 *
 * When you turn on CSS aggregation at admin/config/development/performance, all
 * of these @include files will be combined into a single file.
 */

// CSS SASS Grid
//@import "susy";

/* Import Sass mixins, variables, Compass modules, etc. */
@import "init";

/* Import custom fonts */
@import "components/font_open_sans_bold";
// @import "components/font_roboto_slab_bold";

/* HTML element (SMACSS base) rules */
@import "normalize";

/* Layout rules */
//@import "layouts/responsive";

// CSS3 transitions
// @import "compass/css3/transition";

/* Component (SMACSS module) rules */
// @import "components/chosen";
@import "components/bootstrap/bootstrap";
@import "components/base";
@import "components/buttons";
@import "components/carousel";
@import "components/ctools.module";
@import "components/formatting";
@import "components/icons";
@import "components/misc";
@import "components/navigation";
@import "components/wysiwyg_templates";
@import "components/webinar";
@import "components/colorbox.module";
@import "components/forms";
@import "components/dictionary_list";
@import "components/site_dictionary.module";
@import "components/video_section";
@import "components/site_accountant.module";
@import "components/ratings.module";
@import "components/site_presentation.module";
@import "components/client_form";
@import "components/site_app_ext";
@import "components/order_flow.module";
@import "components/order_block";
@import "components/webform";
@import "components/site_app_login.module";
@import "components/site_trial.module";
@import "components/jquery.mb.YTPlayer.min";
@import "components/site_accountant_map_popup.module";
@import "components/site_job";
@import "components/typedjs";
@import "components/addon_modules.view";
@import "components/site_search.module";
@import "components/gdpr_form";
@import "components/site_newsmail.module";
@import "components/site_event_calendar";
@import "components/jquery.flipster";
@import "components/fixed";
@import "components/instagram";
@import "components/wiki";
@import "components/wiki_brief";

// Responsive rules
@import "responsive/styles";

// Adding external library, instagram filters for editors to use on images via WYSIWYG editor
// @import "lib/cssgram.css";
// @import "lib/animate.css";

/* Libraries */
// We are not currently using the CSS generated by including the this library
/* @import "lib/font-awesome/font-awesome"; */

// Optionally, add your own components here.
// @import "print";

// Migration
@import "components/migration.scss";