.view-display-id-block_1>.view-content>h3,
.view-display-id-block_8>.view-content>h3,
.view-display-id-block_9>.view-content>h3 {
  padding-bottom: 13px;
}

.addon-list {

  li {
    margin-bottom: 26px;

    .addon-row-content {
      height: 81px;

      &.short {
        height: 47px;
      }
    }

    .addon-description {
      height: 76px;
      padding: 7px;
      width: 195px;
    }

    .addon-description-short,
    .addon-subtitle-description {
      font-size: 0.9rem;
      line-height: 1.3rem;
    }

    .title {
      font-size: 1.4rem;
      line-height: 3.3rem;
      width: 130px;

      &.no-img {
        width: 182px;
      }
    }

    figure {
      height: 26px;
      width: 52px;

      img {
        width: 26px;
      }
    }

    .addon-price {
      font-size: 0.9rem;
      width: 76px;
      height: 76px;

      .price-box {
        margin-top: 13px;
        min-height: 8px;
      }

      .currency {
        top: -6px;
      }

      .price {
        font-size: 2.6rem;
        top: 7px;
      }

      .period {
        bottom: -6px;
      }

      .expand-collapse {
        height: 16px;
        left: 29px;
        top: 10px;
        width: 16px;
      }
    }

    .addon-description-full {
      width: 271px;
    }

    .short {
      .addon-description {
        height: 42px;
      }

      .addon-price {
        height: 42px;

        .expand-collapse {
          top: 13px;
        }
      }
    }
  }
}

.addon-updated-label {
  height: 26px;
  padding-left: 6px;
  padding-top: 5px;
  width: 26px;
}

.addon-description-full-wrapper {
  padding: 13px;
}

ul.addon-list {
  li {
    display: inline-block;

    .addon-description-full {
      width: 100%;

      .template33 .icon-wrapper {
        height: 72px;
        width: 72px;

        .icon {
          height: 26px;
        }
      }
    }
  }

  .addon-description {
    width: 70%;
  }

  .addon-price {
    width: 30%
  }

  .currency,
  .period {
    font-size: 1.2rem;
  }

  .expand-collapse {
    left: 43% !important;
  }


  .close-addon {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .addon-description-full-wrapper {
    width: 100%;
  }

  .addon-updated-label {
    font-size: 10px;
    margin-top: -15px;
    padding-top: 1px;
    margin-left: -13px
  }
}