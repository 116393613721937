.l1 .fsc.search-results-section {
  vertical-align: top;
}

.page-node.section-soegeresultater {
  .navigation-search {
    display: block;
  }
}

#cludo-search-results {
  .search-filters {
    a {
      color: $gray-more-dark;
    }
    .active {
      a {
        font-family: $open-sans-bold;
      }
    }
  }

  .search_page_list {
    border-top: 1px $gray-light solid;

    .active {
      color: $gray-darkest;
    }

    a {
      color: $gray-darkest;
    }
  }

  .search-result-count,
  .search-did-you-mean.full {
    padding-bottom: 7px;
    color: $gray-more-dark;
    text-align: right;
  }

  .cludo-page-navigation-top {
    display: flex;
    justify-content: space-between;
    border-top: 0;
    border-bottom: 1px $gray-light solid;

    .search_page_list {
      border: 0;
    }

    .cludo-page-navigation {
      width: 400px;
      margin-top: auto;
    }
  }

  .search-results {
    border-top: 1px $gray-light solid;

    &.navigation-prepended {
      border-top: 0;
    }

    .search-results-item {
      margin-top: 40px;
      margin-bottom: 40px;

      h2 {
        margin-bottom: 6px;

        a {
          color: $blue-dodger;
        }
      }

      p {
        margin-bottom: 0;
      }

      b {
        font-family: $open-sans-bold;
      }

      .path {
        display: none;
      }
    }

    .search-no-results {
      color: $gray-darkest;

      .info {
        margin-top: 50px;
      }

      .headline {
        color: $orange;
        margin-top: 25px;
      }

      .search-did-you-mean.full {
        text-align: left;

        a {
          color: $gray-darkest;
          font-family: $open-sans-bold;
        }
      }

      ul {
        margin: 30px 0;

        li {
          background-image: url('../images/checkmark-orange.svg');
          padding-left: 32px;
          background-repeat: no-repeat;
          background-size: 24px 24px;
          background-position: 0 0;
          margin-bottom: 7px;
        }
      }

      .text {
        display: block;
        margin: 7px 0;
      }

      b {
        font-family: $open-sans-bold;
      }
    }
  }

  .loading {
    margin-left: 135px
  }
}

.powered-by-cludo {
  display: none;
}

.cludo-banner-smartinbox {
  display: flex;
  height: 150px;
  background-color: $blue-dark-newid;
  margin: 30px 0;
  font-family: $open-sans;

  .left-column {
    width: 170px;
    background: url("../images/smart-inbox-icon.png") center center no-repeat;
  }

  .right-column {
    .line1 {
      display: block;
      font-size: 21px;
      line-height: 2.6;
      font-weight: 300;
      color: $gray;
      white-space: nowrap;
      overflow: hidden;
    }

    .line2 {
      display: block;
      font-family: $open-sans-bold;
      font-size: 60px;
      font-weight: bold;
      line-height: 0.6;
      color: white;
    }

    .line3 {
      display: block;
      font-size: 21px;
      line-height: 3.4;
      font-weight: 300;
      color: $gray;
    }
  }
}

.page-accountants-search {
  #cludo-search-results {

    .cludo-poweredby {
      display: none;
    }

    .cludo-search-modal-body {

      .search-result-count {
        padding-right: 20px;
      }

      .search-filters ul a.active {
        background-color: $orange;
      }
    }

    #cludo-search-form-content input[type=search] {
      border-color: $orange;
    }

  }


  #cludo-search-form-revisorlist {
    display: flex;
    justify-content: center;

    .form-actions {
      top: 0;
    }

    .search_autocomplete {
      font-size: 2rem;
      background: #fff;
      width: 418px;
      margin: 0;
      list-style: none;
      text-align: left;
      line-height: 2.8rem;
      padding: 0;
      color: $gray-more-dark;
      border: 1px solid black;
      top: auto;

      li {
        padding: 10px 5px;
        overflow: hidden;
        text-overflow: ellipsis;

        b {
          font-family: $open-sans-bold;
        }

        &:hover {
          background-color: $orange;
        }

        &.active {
          background-color: $orange;
        }
      }

      &:empty {
        display: none;
      }
    }
  }
}

body {
  article {

    .wiki-search,
    .ecopedia-search {
      a {
        color: $blue-dodger;
      }
    }
  }
}
