.url-textfield {
  display: none !important;
}

.client-form--webform {
  .client-form__fieldset {
    [class*="webform-component--answer-"] {

      // Override answer margings in the quizz form
      // http://local.e-conomic.dk/lpd/e-conomic-insider-momsquiz
      // http://local.e-conomic.dk/momsquiz
      .form-item {
        margin: 0;
      }
    }

    // Webform specific date design
    // https://jira.visma.com/browse/WD-4419
    .webform-component-date {
      margin-top: 20px;

      label {
        display: none;
      }

      .webform-datepicker .form-item {
          display: none;
      }
    }
  }

  // Special stying of the HTML block to fit the checkbox label align and look
  // http://local.e-conomic.dk/revisor-og-bogholder-registrering-dk
  &.webform-client-form-4890 {
    .webform-component--oplysningspligt-obligatorisk {
      @extend .client-form__label__link;

      margin-left: 35px
    }
  }

}

.webform-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
  z-index: 9998;
}

.webform-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  font-size: 2rem;
  width: 640px;
}

.webform-modal-close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.webform-modal-close:hover,
.webform-modal-close:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
}
