form {
  &.webform-client-form {

    input[type=text],
    input[type=email],
    select {
      padding: 0 7px 0;
      margin: 0 0 7px;
      height: 26px;
    }

    .webform-component {
      label {
        margin-top: 7px;
      }

      .form-type-radio {
        input[type=radio] {
          margin-right: 7px;
        }

        &:last-child {
          padding-bottom: 7px;
        }
      }
    }

    input[type=submit] {
      width: 100%;
      border-radius: 33px;
      margin-top: 13px;
      font-size: 16px;
      padding: 7px 0px;
    }
  }

  input:not([type="checkbox"]):not([type="submit"]) {
    padding: 0 7px 0;
  }
}

// Search form in main content area.
#search-block-form,
#search-form {
  width: 100%;
  margin: 33px auto 0;
  padding-left: 130px;

  .form-text {
    border-radius: 3px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    font-size: 10px;
    height: 36px;
    margin-bottom: 20px;
    margin-top: 13px;
    padding: 3px;
    width: 293px;
  }

  .form-submit {
    background-size: 13px 13px;
    border-radius: 3px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    font-size: 10px;
    height: 36px;
    left: -3px;
    margin-bottom: 20px;
    margin-top: 13px;
    padding: 3px;
    width: 39px;
  }
}