$order-form-confirm-width:560px;

// Logo on landingpage
.page-order-flow {
  padding-top: 80px;

  ul.menu {
    list-style: none outside none;
  }

  header {
    @extend %header-menu-shared-prop;
    background: rgba(255, 255, 255, 1);
    height: 90px;
    top: 0;
    width: 100%;
    position: fixed;
    z-index: 90;
    margin-bottom: 25px;
    text-align: center;
    border-bottom: 1px solid $gray;

    .logo {
      display: inline-block;
      width: 360px;
    }

    .cart {
      display: inline-block;
      height: 65px;
      margin-right: 20px;
      position: relative;
      text-align: right;
      top: -10px;
      vertical-align: bottom;
      width: 350px;

      &.step1 {
        margin-right: 0;
        width: 600px;
      }

      .product-price-total {
        position: relative;
        top: 3px;
      }
    }

    .buttons {
      border-left: 1px solid $gray;
      display: inline-block;
      margin-bottom: 43px;
      vertical-align: bottom;

      #edit-back {
        background-color: $white;
        border-width: 0;
        margin-left: 30px;
      }

      #edit-proceed {
        color: #fff;
        background-color: #ef7d00;
        border: 1px solid #ea7a00;
        font-size: 13px;
        padding: 5px 20px;
        margin-left: 10px;
      }
    }

    ul.menu>li.first>a {
      @extend %header-menu-logo-shared-prop;
    }
  }

  .order-flow-step-subscription {
    float: right;
  }

  .field-name-field-section {
    margin-left: -306px;

    .field-section-content {
      padding: 0;
    }
  }

  // Overwrite margin for pricing page when field container is in field (fx add ons inserted as block on order page).
  .field {
    .field-collection-item-container {
      margin-left: 41px;
    }
  }
}

.ribbon-wrapper {
  height: 90px;
  left: -3px;
  line-height: 2rem;
  overflow: hidden;
  position: absolute;
  top: -3px;
  width: 90px;
}

.ribbon-orange {
  background-color: $orange;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 1.1rem;
  font-weight: 100;
  left: -24px;
  position: relative;
  top: 23px;
  transform: rotate(-45deg);
  width: 115px;

  &::before,
  &::after {
    content: "";
    border-top: 3px solid $orange-darker;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    position: absolute;
    bottom: -2px;
  }

  &::before {
    left: 0
  }

  &::after {
    right: 0
  }
}

.order-steps ul {
  background: url('../images/order-flow-breadcrumb.png') 0 0 no-repeat;
  display: block;
  height: 50px;
  width: 960px;

  li {
    display: block;
    float: left;
    line-height: 50px;
    list-style: none;
    padding-left: 95px;
    width: 320px;

    &.active,
    &.active a {
      color: $black;
      font-weight: 700;
    }

    &.checkmark {
      padding-left: 0;

      a {
        background-image: url('../images/order-flow-checkmark-fixedsize.svg');
        background-position: 64px 10px;
        background-repeat: no-repeat;
        background-size: 24px 24px;
        color: $black;
        display: block;
        float: left;
        text-decoration: none;
        width: 330px;

        span {
          padding-left: 95px;
        }
      }
    }
  }
}

.node-product-package {
  width: 960px;
  margin: 0 auto;
  text-align: right;

  &.less-than-three-products {
    text-align: center;
  }

  .field-type-field-collection {
    text-align: left;
  }

  [class^="cta-"] a {
    border-radius: 50px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    width: 80%;

    @extend .btn-orange-l;
  }

  .cta-top {
    padding: 0 0 20px 0;
  }

  .cta-bottom {
    text-align: center;
  }
}

.field-name-field-package-payoffs {
  display: table;
  font-family: Cambria;
  font-size: 2rem;
  font-style: italic;
  height: 258px;
  position: absolute;
  text-align: left;
  width: 255px;
  z-index: 80;

  li {
    padding-bottom: 20px;
  }
}

.field-name-field-product {
  display: inline-block;
  width: 230px;
  z-index: 10;
  position: relative;

  .icons-sp {
    background-position: center center;
    background-repeat: no-repeat;
    height: 50px;
    border-top: 1px solid $gray-lighter;
  }

  .check {
    background-image: url(../images/checkmark-price-page-m.png);
  }
}

.highlighted-label {
  text-align: center;
  margin-top: 30px;

  .label-special-offer {
    color: #fff;
    width: 150px;
    background: #000;
    margin: 0 auto;
    top: 15px;
    font-size: 1.5rem;
    position: relative;
    transform: rotate(2deg) skew(-2deg, -2deg);
  }
}

.label-most-popular {
  background-color: #fff;
  border: 1px solid $gray-lighter;
  color: $orange;
  font-size: 1.5rem;
  height: 60px;
  left: 13px;
  margin-top: -35px;
  padding-top: 5px;
  position: absolute;
  width: 200px;
  z-index: -1;

  // Overwrite height if there's also special offer label
  &.has-special-offer {
    margin-top: -19px;
  }

  // An editor can choose different background color for most popular label.
  &.dark-blue {
    background-color: #373e4e;
    color: #fff;
  }

  &.orange {
    background-color: #f39200;
    color: #fff;
  }

  &.green {
    background-color: #29b095;
    color: #fff;
  }

  &.blue {
    background-color: #009ce2;
    color: #fff;
  }

  &.white {
    background-color: #fff;
    color: #000;
  }

  &.black {
    background-color: #000;
    color: #fff;
  }
}

.field-collection-package-item-wrapper {
  border: 1px solid $gray-lighter;
  width: 225px;
  background: $white;
  display: inline-block;
  text-align: left;

  &.highlighted {
    border: 1px solid $blue-dark-newid;
    box-shadow: 0px 0px 10px #ccc;

    .markup.parent,
    .child-features .markup {
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }

    .markup.last-feature {
      border-bottom: 1px solid #000;
    }

    .top,
    h3 {
      color: $white;
    }

    .top:not([class*="bgc"]) {
      color: $black;

      h3 {
        color: $black;
      }
    }
  }

  .highlighted-text {
    font-family: $cambria;
    font-size: 2.2rem;
    font-style: italic;
    margin-top: -20px;
  }

  .btn_info {
    color: $orange !important;
  }

  .md_tbl_cell_middle {
    .btn_info {
      display: inline-block;
      margin-top: 0px;
    }
  }

  .column-head {
    text-align: center;
    min-height: 315px;

    h3 {
      padding: 20px 0 20px 0;
      font-family: Cambria, sans-serif;

      &.has-special-offer {
        padding-top: 30px;
      }
    }

    .product-usp {
      h3 {
        font-size: 1.6rem;
      }
    }

    .old-price {
      text-decoration: line-through;
      padding-bottom: 10px;
      margin-top: -10px;
    }

    .currency {
      position: relative;
      top: -20px;
    }

    .price {
      font-size: 4.5rem;
      position: relative;

      span {
        position: absolute;
        top: 48px;
        left: 0;
        white-space: pre;
        font-size: 8pt;
      }

    }

    .description {
      text-align: left;
      font-size: 1.4rem;
      line-height: 2.2rem;
    }

    a.colorbox-load {
      color: $gray-more-dark;
      text-decoration: underline;
    }
  }

  .field-title {
    font-weight: 700;
  }

  .gradient-top {
    &::after {
      width: 269px;
      height: 10px;
      display: block;
      position: absolute;
      content: '';
      left: 0;
      top: -10px;
      background-image: linear-gradient(to top, rgba(219, 219, 219, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
    }
  }

  .gradient-bottom::before {
    width: 269px;
    height: 10px;
    display: block;
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    background-image: linear-gradient(to bottom, rgba(219, 219, 219, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  }

  .icons-sp.gradient-top::after {
    position: relative;
    width: 100%;
  }

  .md_inlb.gradient-top::after {
    top: -58px;
  }

  label {
    margin-bottom: -8px;
    margin-top: 0;
    text-align: center;
    vertical-align: middle;

    .special-label {
      display: none;
    }

    .child-features {
      display: none;
      margin-left: 1px;
      margin-top: -8px;

      .markup {
        color: $gray-more-dark !important;
        font-size: 1.4rem;
      }

      i {
        background-image: url(../images/checkmark-price-page-s.png);

        &.gradient-bottom::before {
          width: 223px;
          position: relative;
        }
      }

      i.unchecked {
        background-image: none;
      }
    }

    .markup {
      display: none;
      font-weight: 500;
      height: 52px;
      color: #000;
      font-size: 1.4rem;

      &.parent {
        .feature_info {
          margin-top: 15px;
        }
      }

      .tooltip.no-url,
      .tooltip.no-url:hover {
        color: $gray-darker;
      }
    }

    i {
      display: inline-block;
      width: 100%;
      text-align: center;
      color: $blue;
      font-style: normal;
      margin-top: 0;

      &.fa-times {
        margin-top: 15px;
        color: $gray-darker;
      }
    }

    &.first-column .special-label {
      animation-name: pulse;
      animation-duration: 1s;
      animation-iteration-count: 8;
      animation-direction: alternate;
      animation-timing-function: ease-in-out;
      color: $orange;
      display: block;
      font-size: 1rem;
      font-weight: 200;
      line-height: 1rem;
      margin-top: 1px;
      padding: 2px;
      z-index: 2;

      // pulse effect
      @keyframes pulse {
        to {
          opacity: .5;
        }
      }
    }

    &.first-column .markup {
      background-color: #fff;
      border: 1px solid $gray-lighter;
      color: #000;
      display: table;
      height: 50px;
      left: 1px;
      margin: 0 0 0 -280px;
      padding: 0 0 0 10px;
      position: absolute;
      text-align: left;
      vertical-align: middle;
      width: 270px;

      .md_tbl_cell_middle:first-child {
        width: 214px;
        line-height: 1.8rem;
      }

      span {
        font-size: 1.4rem;
        font-weight: normal;
      }

      // span with +/- expand/collapse circle, uses class definition from _icons.scss
      .expand-collapse-icon {
        margin-right: 15px;
        margin-top: 3px;
        padding: 6px 0 0 6px;
        position: absolute;
      }

      &.first {
        .expanded {
          margin-right: 15px;
        }
      }

      a[href] {
        @include a-linkcolor($blue, $blue, none);
      }

      a:not([href]) {
        color: $gray-darkest;
        text-decoration: none;
      }
    }

  }
}

.order-steps {
  position: relative;
  margin-bottom: 20px;
}

.node-statement-autoresolve {
  background: url('../images/notification.png') no-repeat;
  padding-left: 55px;
  margin-top: 20px;
}

.subscription,
.company,
.user {
  width: $order-form-confirm-width;

  .item_head {
    margin: 40px 0 20px 0;

    h2 {
      float: left;
      font-size: 2.6rem;
      line-height: 2rem;
      font-weight: 700;
    }

    [class^="edit"] {
      @include a-linkcolor($orange, $decoration: "underline");

      clear: right;
      font-size: 1.4rem;
      line-height: 2.6rem;
      margin-left: 10px;
    }
  }

  color: black !important;
  font-size: 1.6rem;

  .vat,
  .total {
    border-bottom: 0;
  }
}

.order-flow-confirm-title {
  color: $orange;
}

// Moved this out to optimize the CSS.
.outer-div,
.product,
.vat,
.total {
  clear: both;
  display: table;
  margin-bottom: 10px;
  padding-bottom: 5px;
  width: 100%;

  .title {
    float: left;
  }

  .value,
  .price {
    float: right;
  }
}

.node-statement {
  font-weight: 500;
  display: table-cell;
  vertical-align: middle;

  >ul>li {
    padding-right: 20px;
    line-height: 2.5rem !important;
  }
}

/* Views styling for addons-block */
.addons-more-group {

  /* Styles common across all layouts - mobile + others */
  .view-header {
    h3 {
      padding: 20px 0 20px 0;
      font-family: Cambria, sans-serif;
      font-style: italic;

      &.has-special-offer {
        padding-top: 30px;
      }
    }

    .addons-title-wrapper {
      background-image: url('../images/bg_addons_header_greyline.png');
      background-position: 0 10px;
      background-repeat: repeat-x;
      overflow: hidden;
      text-align: center;
      width: 100%;
    }

    .addons-title {
      display: inline-block;
      padding: 0 20px;
    }

    .show-hide-toggle {
      float: left;
      position: relative;
      top: 19px;
    }
  }

  h3 {
    display: block;
    clear: both;
    padding-top: 50px;

    .group-term-subtitle {
      color: $gray-darker;
    }
  }

  h2.group-title {
    display: inline-block;
  }

  .view-content {
    width: 100%;
  }

  .views-row {
    background: white;
    border: 1px solid $gray;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 10px;
    font-size: 1.6rem;

    .addon-title {
      font-weight: 500;
    }

    .addon-updated-txt {
      color: $orange;
      display: inline-block;
      font-size: 1rem;
      margin-left: 10px;
    }

    .field-name-field-subtitle {
      color: $gray-more-dark;
    }

    .views-field-description {
      float: right;
      width: 30px;
      height: 30px;
    }
  }

  /* ./Styles common across all layouts - mobile + others. */
  .views-row-odd {
    float: left;
    width: 49%;
    margin-right: 2%;
  }

  .views-row-even {
    float: right;
    margin-right: 0;
    width: 49%;
  }
}

/*
* Retry popup shown when an error ocurred...
* ... or when an error re-occurs
*/
.page-order-flow-signup-retry {
  .block {
    margin-bottom: 2rem;
  }

  .order-flow-content {
    width: 100%;
  }

  form#order-flow-signup-retry {
    .field-collection-item-container {
      width: 100%;
      font-size: 1.6rem;
    }

    .field-collection-item-wrapper {
      height: auto !important;
    }

    .field-section-content {
      height: auto !important;
      padding: 0 !important;
      vertical-align: top !important;
      color: $gray-darkest;
    }

    .form-submit {
      margin-right: 2%;
    }
  }

  .colorbox-footer {
    border-top: 1px $gray-lighter solid;
    padding-top: 3rem;
    margin-top: 3rem;

    .retry-error {
      display: block;
      clear: both;
      float: none;
    }

    .retry-text,
    .retry-error {
      font-size: 1.6rem;
      color: $gray-darkest;
    }
  }
}

.critical {
  color: red !important;
}

.btn_info {
  border-radius: 50%;
  border: 1px solid $gray-darker;
  color: $gray-darker !important;
  display: block;
  font-size: 1.4rem;
  font-weight: 700;
  height: 20px;
  line-height: 18px;
  margin-top: 3px;
  text-align: center;
  width: 20px;

  &.feature_info {
    margin-right: 17px;
  }

  a {
    color: $gray-darker;
    text-decoration: none;
    padding: 6px;
  }

}

.rel-wrapper {
  position: relative;

  .anchor_order_form {
    position: absolute;
    top: -80px;
  }
}

#order-flow-signup-user-info {
  .tooltip-question-icon {
    background: url('../images/questionmark-black.svg') 2px 2px no-repeat;
    background-size: 14px 14px;
    border-radius: 50%;
    border: 1px solid $black;
    display: block;
    float: right;
    height: 20px;
    margin-right: 15px;
    margin-top: 8px;
    padding: 6px 0 0 6px;
    text-align: center;
    width: 20px;

    &::before {
      content: '';
    }
  }

  .tooltip:hover::before {
    margin-left: 8px;
  }
}

.order-flow-content {
  width: $wrap-page-width;
}

.client-form--order {
  .client-form__parralel-info {
    position: absolute;
    margin-left: $client-form-width;
  }
}

.client-form--order-confirm {
  @extend .client-form--slim-left;

  .client-form__fieldset {
    width: $order-form-confirm-width;

    label, .privacy-text{
      a{
        @include a-linkcolor($gray-darkestale, $decoration:"underline");
      }
    }
  }

  .client-form__fieldframe {
    color: $gray-darkestale;

    legend {
      font-size: 2.6rem;
      line-height: 2rem;
      font-family: $open-sans;
      margin-bottom: 35px;
      color: black;
    }

  }
}