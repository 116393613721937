.view-display-id-block_1 > .view-content > h3, .view-display-id-block_8 > .view-content > h3, .view-display-id-block_9 > .view-content > h3 {
  padding-bottom: 20px;
}

.addon-list {
  list-style: none;

  li {
    float: left;
    margin-bottom: 40px;
    width: 50%;

    .addon-row-content{
      height: 125px;

      &.short{
        height: 73px;
      }
    }

    &.views-row-odd {
      clear: both;
    }

    .addon-description {
      background-color: $white;
      float: left;
      height: 117px;
      padding: 10px;
      width: 300px;
    }


    .addon-description-short,
    .addon-subtitle-description {
      font-size: 1.4rem;
    }

    .title {
      float: left;
      font-size: 2.2rem;
      line-height: 5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      white-space: nowrap;
      width: 200px;

      &.no-img{
        width: 280px;
      }
    }


    figure {
      float: right;
      height: 40px;
      width: 80px;

      img {
        width: auto;
        height: auto;
      }
    }


    .addon-description-short,
    .addon-subtitle-description {
      clear: both;
    }

    .addon-price {
      float: left;
      font-size: 1.4rem;
      color: $white;
      width: 117px;
      height: 117px;
      background-color: $blue-dark-newid;

      &.inactive,
      &.inactive-next {
        background-color: #8b92a3;
      }

      .price-box {
        display: table;
        margin-top: 20px;
        text-align: center;
        width: 100%;
        min-height: 13px;
      }

      .currency {
        display: table-cell;
        text-align: right;
        width: 25%;
        position: relative;
        top: -9px;
      }

      .price {
        display: table-cell;
        font-size: 4rem;
        width: 45%;
        position: relative;
        top: 10px;
      }

      .period {
        display: table-cell;
        text-align: left;
        width: 30%;
        position: relative;
        bottom: -9px;
      }

      .expand-collapse {
        background: url('../images/show-more-white.svg') center center no-repeat;
        cursor: pointer;
        display: block;
        height: 25px;
        position: relative;
        left: 45px;
        top: 15px;
        transition: 200ms ease-in-out;
        width: 25px;

        &.clicked,
        &.clicked-next {
          transform: rotate(180deg);
        }

      }
    }

    .addon-description-full {
      background-color: $blue-dark-newid;
      clear: both;
      color: $white;
      opacity: 0;
      width: 417px;
    }

    .short{
      .addon-description{
          height: 65px;
      }
      .addon-price{
          height: 65px;

          .expand-collapse{
            top: 20px;
          }
      }
      .expand-collapse {
        cursor: default;
      }
    }

  }
}

.addon-updated-label {
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid $orange;
  height: 40px;
  padding-left: 9px;
  padding-top: 7px;
  position: absolute;
  width: 40px;
}

.close-addon {
  text-align: right;
  margin: 0px auto;
}

[class*="cloned-"] {
  background-color: $blue-dark-newid;
  display: none;
  opacity: 0;
  position: absolute;
  width: 100%;
}

.addon-description-full-wrapper {
  color: $white;
  margin: 0 auto;
  width: 1024px;
  padding: 20px;

  h2 {
    color: $white;
  }
}
