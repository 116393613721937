#navigation>.region-navigation>.block-lpd_logo {
  ul {
    margin: 8px 0 0;

    li {
      padding-top: 1px;
    }
  }
}

.html .block-lpd_logo {
  display: none;
}

.node-course {
  &>.content>div {
    padding-right: 13px;
  }
}

.course-booking-form {

  input[type=text],
  select {
    padding: 0 7px 0;
    margin: 0 0 7px;
    height: 26px;
  }

  select {
    &.error {
      margin-bottom: 20px;
    }
  }

  .error {
    margin: -3px 0 13px;
  }

  input[type=submit] {
    margin-top: 13px;
  }
}