.video-section-wrapper-container {
  .video-section-wrapper-content {
    .video-section-close-button {
      width: 19px;
      height: 19px;
      margin: -7px -7px 0 -10px;
      border-width: 1px;
      font-size: 16px !important;
      line-height: 16px;
      border-radius:16px;
    }
  }

  .video-section-wrapper-content-wide {
    .video-section-close-button {
      margin: 3px -7px 0 3px;
    }
  }
}