.node-accountant-full {
  font-size: 1rem;
  margin-bottom: 33px;

  h1 {
    font-size: $h1-font-size;
  }

  h2 {
    font-size: $h2-font-size;
  }

  h3 {
    font-size: $base-font-size;
  }

  .headline {
    margin-bottom: 20px;
  }

  .block-top-margin {
    margin-top: 13px;
  }

  .infoblock {
    margin-bottom: 13px;
  }

  .block-header {
    margin-bottom: 3px;
  }

  .paragraph-header {
    margin-top: 20px;
    margin-bottom: 7px;
  }

  .paragraph-header-top {
    margin-bottom: 7px;
  }

  .article-separator {
    margin-top: 13px;
    margin-bottom: 13px;
  }

  .geofieldMap {
    width: 100% !important;
    height: 163px !important;
  }

  .gm-style-iw {
    font-size: 5px;
  }

}

[class*="-accountant"] {
  .button-oversight a {
    &::before {
      left: -3px;
    }
  }

  #cludo-search-form-revisorlist {
    margin-bottom: 20px;
  }

  .panel-pane.description {
    width: 100%;

    .pane-content {
      padding: 0 13px 0 13px;
    }
  }

  .geofieldMap {
    width: 100% !important;
    height: 163px !important;
  }

  .button-try a,
  .button-oversight a {
    width: 100%;
    padding: 7px 20px;
  }

  .button-oversight a {
    margin-bottom: 13px;
  }

  #cludo-search-form-revisorlist {
    .form-item {
      margin: 0;
    }

    .form-actions {
      font-size: 0;
    }
  }
}

.html [class*="-accounant"] {
  .button-oversight a {
    &::before {
      height: 16px !important;
    }
  }
}

.field-name-field-accountant-numclients {
  padding: 0 0 8px 15px;
  position: relative;
  background: none;
  margin-left: 13px;
}

.field-name-field-accountant-numclients:before {
  width: 12px;
  height: 12px;
  text-indent: -1000px;
  top: 1px;
}

[class*="-accountants"] {

  [class*="-list-overview"] {
    font-size: 10px;

    .row.top {
      display: none;
    }

    .row.top+.row {
      border-top: 1px solid #ddd;
      padding-top: 13px;
    }

    .row {
      div[class^="col-"] {
        padding: 10px 0 10px 0;
      }

      .description {
        margin-right: 65px;
        margin-left: 13px;
      }

      .services {
        margin: 0px 13px 13px 13px;
      }
    }
  }

  .pager-previous,
  .pager-next {

    a {
      width: 10px;
      height: 20px;
      margin-bottom: -7px;
      text-indent: -1000px;
    }
  }

  .panel-pane.description {

    margin-top: 14px;

    .pane-title {
      font-size: 1.4rem;
    }
  }

  nav.alphabet {
    display: none;
  }
}

.view-accountant-map {
  margin: 10px 0 33px 0;
}

.view-accountant-city-overview {
  margin-bottom: 33px;

  h3 {
    font-size: 1.3rem;
    padding: 10px 0 10px 0;
    margin-bottom: 10px;
  }

  ul {
    column-gap: 33px;
    margin-bottom: 33px;

    li {
      font-size: 10px;
      margin-bottom: 3px;
    }
  }
}

.html {
  .view-accountant-city-overview {
    .view-content ul {
      column-count:1;
    }
  }
}

.google-cse-form {

  .form-text {
    font-size: 1.3rem;
    height: 36px;
    padding-left: 7px;
    width: 272px;
  }

  .form-submit {
    background-size: 13px 13px;
    height: 36px;
    width: 53px;
  }
}

.page-accountants-citylist {
  .panel-col-bottom {
    margin-left: 13px;
  }
}